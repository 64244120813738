import React, { useCallback, useState } from 'react';
import { Modal } from 'hoc/modal';

const useModal = (props = {}) => {
  const { autoClose = true } = props;
  const [show, setShow] = useState(false);
  const [contentModal, setContentModal] = useState('');

  const closeModal = () => {
    setShow(false);
    setContentModal('');
  };

  const openModal = (content) => {
    setShow(true);
    setContentModal(content);
  };

  const CustomModal = useCallback(
    (props) => {
      return (
        <Modal
          show={show}
          onClose={autoClose ? closeModal : () => {}}
          {...props}
        >
          {contentModal}
        </Modal>
      );
    },
    [autoClose, contentModal, show],
  );

  const ReturnModal = useCallback(
    (props) => (
      <Modal
        show={show}
        onClose={autoClose ? closeModal : () => {}}
        {...props}
      />
    ),
    [autoClose, show],
  );

  return {
    show,
    contentModal,
    CustomModal,
    openModal,
    closeModal,
    Modal: ReturnModal,
  };
};

export default useModal;
