import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { addModal, delModal } from 'app/modal/modal.slice';

const Modal = ({ show, onClose, children, loading, option }) => {
  const el = useRef();
  const dispatch = useDispatch();
  const { count } = useSelector((state) => state.modal);

  useEffect(() => {
    if (show) {
      dispatch(addModal());
    }

    return () => {
      if (show) {
        dispatch(delModal());
      }
    };
  }, [dispatch, show]);

  useEffect(() => {
    if (count > 0) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [count]);

  const handleModal = (e) => {
    e.stopPropagation();
    if (!el.current.contains(e.target)) {
      if (onClose) {
        onClose();
      }
    }
  };

  return createPortal(
    show ? (
      <BackGround onClick={handleModal} count={count}>
        <ModalWrapper style={option}>
          <ModalContainer>
            <Content ref={el} isLoading={loading}>
              {children}
            </Content>
          </ModalContainer>
        </ModalWrapper>
      </BackGround>
    ) : null,
    document.getElementById('modal-root'),
  );
};

const BackGround = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ count }) => 99 + count};
  width: 100%;
  height: 100%;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-x: hidden;
  overflow-y: auto;
`;

const ModalWrapper = styled.div`
  position: relative;
  margin: 3rem auto;
  height: auto;
  min-height: calc(100% - 7rem);
  display: flex;
  justify-content: center;
`;

const ModalContainer = styled.div`
  padding: 0.5rem;
`;

const Content = styled.div`
  z-index: 999;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);

  ${({ isLoading }) =>
    !isLoading &&
    css`
      animation: event 0.3s;
      @keyframes event {
        from {
          transform: translateY(100%) scale(0);
        }
        to {
          transform: translateY(0) scale(1);
        }
      }
    `};
`;

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  loading: PropTypes.bool,
  option: PropTypes.object,
};

Modal.defaultProps = {
  show: false,
  onClose: () => {},
  children: '',
  loading: false,
  option: null,
};

export default React.memo(Modal);
