import React from 'react';
import styled from 'styled-components';
import { Select } from 'components/atoms';
import { usePropContext } from 'context/PropContext';

const SearchCategoryForm = ({ selectedValue01, selectedValue02 }) => {
  const { category, selectedCategory01, selectedCategory02, handleOption } =
    usePropContext();
  const categories02 = category.filter((item) => {
    return item.sp_category1 == selectedCategory01;
  })[0].sp_category2;

  const handleCategory01 = (change) => {
    const target = category.filter((item) => {
      return item.sp_category1 == change.target.value;
    })[0].sp_category2[0];
    handleOption({
      sp_category1: change.target.value,
      sp_category2: target,
    });
  };

  const handleCategory02 = (change) => {
    handleOption({ sp_category2: change.target.value });
  };

  return (
    <Container>
      <div>
        <SubTitle>카테고리 I</SubTitle>
        <Select
          name="itemSearch"
          options={category.map((item) => {
            return {
              id: item.sp_category1,
              value: item.sp_category1,
              name: item.sp_category1,
            };
          })}
          selectedValue={selectedValue01}
          onChange={handleCategory01}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <div>
        <SubTitle>카테고리 II</SubTitle>
        <Select
          name="itemSearch"
          options={categories02.map((item) => {
            return {
              id: item,
              value: item,
              name: item,
            };
          })}
          selectedValue={selectedValue02}
          onChange={handleCategory02}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <div />
    </Container>
  );
};

const Container = styled.form`
  flex-direction: row;
  gap: 1.8rem;
  font-size: 0.8rem;
  padding: 10px;
  width: 100%;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SubTitle = styled.div`
  font-size: 12px;
  color: #a3aed0;
  margin-bottom: 5px;
`;

export default SearchCategoryForm;
