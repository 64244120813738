import React, { useState } from 'react';
import styled from 'styled-components';
import { Select, Button } from 'components/atoms';
import { usePropContext } from 'context/PropContext';

const SearchCategoryForm = () => {
  const { date, selectedMonth, handleOption } = usePropContext();
  const [total, setTotal] = useState({ value: '', label: '전체' });

  const handleYearMonth = (change) => {
    handleOption(change.target.value);
  };

  return (
    <Container>
      <Section>
        <div>
          <SubTitle>기간</SubTitle>
          <Content
            style={{
              alignItems: 'flex-start',
              flexDirection: 'row',
            }}
          >
            <div>
              <Select
                name="itemSearch"
                options={date?.map((item) => {
                  return {
                    id: item,
                    value: item,
                    name: item.slice(0, 4) + '-' + item.slice(4, 6),
                  };
                })}
                edit={total}
                onChange={handleYearMonth}
                style={{ cursor: 'pointer', width: 300, textAlign: 'center' }}
              />
            </div>
          </Content>
        </div>
        <Button className="button">초기화</Button>
      </Section>
    </Container>
  );
};

const Container = styled.form`
  display: flex;
  flex-direction: row;
  gap: 1.8rem;
  font-size: 0.8rem;
  padding: 10px;

  .button {
    width: 78px;
    height: 32px;
    margin-top: 22px;
    margin-left: 20px;
    border-radius: 20px;
    border: 1px solid #f4f7fe;
    font-weight: 600;
    background-color: #f4f7fe;
    color: #2b3674;

    &:hover {
      transform: translateY(-1px);
    }
  }
`;

const Section = styled.div`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const SubTitle = styled.div`
  font-size: 12px;
  color: #a3aed0;
  margin-bottom: 5px;
`;

export default SearchCategoryForm;
