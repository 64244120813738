import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InfoIcon } from 'components/atoms';

const InfoDiv = ({ name, desc, position }) => {
  return (
    <Info>
      {name}
      <InfoIcon position={position}>
        <div style={{ color: 'black' }}>{desc}</div>
      </InfoIcon>
    </Info>
  );
};

const Info = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
`;

InfoDiv.propTypes = {
  name: PropTypes.node,
  desc: PropTypes.node,
  position: PropTypes.string,
};

InfoDiv.defaultProps = {
  name: '',
  desc: '',
  position: 'left',
};
export default InfoDiv;
