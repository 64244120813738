import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { SubTitle, Select, Box, Button, Calendar } from 'components/atoms';
import { usePropContext } from 'context/PropContext';
import tooltip from 'assets/images/info.png';

const dateOptions = [
  { id: '01', value: '01', name: '전체' },
  { id: '02', value: '02', name: '최근 7일' },
  { id: '03', value: '03', name: '최근 30일' },
  { id: '04', value: '04', name: '직접 입력' },
];

const SearchDateForm = ({
  dateRange,
  compare,
  selectedValue,
  searchType = 'total',
  isTopic = false,
  limitPeriod = false,
}) => {
  const [activeCustom, setActiveCustom] = useState(false);
  const {
    search,
    category,
    handleSearch,
    handleReset,
    handleSubmit,
    handleOption,
  } = usePropContext();
  const [fdt, setFdt] = useState();
  const [edt, setEdt] = useState();
  const [alert, setAlert] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    searchType == 'total'
      ? '전체'
      : searchType == 'last7Days'
      ? '최근 7일'
      : '최근 30일',
  );
  const [hidden, setHidden] = useState(true);

  const getPeriodDate = () => {
    let startDate = new Date();
    let endDate = new Date();
    if (searchType == 'total') {
      setFdt(dateRange.startDate);
      setEdt(dateRange.endDate);
    } else if (searchType == 'last7Days') {
      startDate.setDate(startDate.getDate() - 8);
      endDate.setDate(endDate.getDate() - 1);
      setFdt(startDate);
      setEdt(endDate);
    } else if (searchType == 'last30Days') {
      startDate.setDate(startDate.getDate() - 31);
      endDate.setDate(endDate.getDate() - 1);
      setFdt(startDate);
      setEdt(endDate);
    }
  };

  const getDateWithFormat = (date) => {
    if (date) {
      const YY = date.getFullYear();
      const MM = date.getMonth() + 1;
      const DD = date.getDate();
      return (
        YY + '-' + (MM < 10 ? '0' : '') + MM + '-' + (DD < 10 ? '0' : '') + DD
      );
    } else {
      return '';
    }
  };

  useEffect(() => {
    getPeriodDate();
    if (selectedDate === '전체') {
      setHidden(false);
      setTimeout(() => {
        setHidden(true);
      }, 2000);
    }
  }, []);

  const handleCategory = (changeCategory) => {
    let startDate = new Date();
    let endDate = new Date();
    if (changeCategory.target.value == '01') {
      startDate = new Date(
        dateRange.startDate.getFullYear(),
        dateRange.startDate.getMonth(),
        dateRange.startDate.getDate(),
      );
      endDate = new Date(
        dateRange.endDate.getFullYear(),
        dateRange.endDate.getMonth(),
        dateRange.endDate.getDate(),
      );
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 2000);
      setSelectedDate('전체');
    } else if (changeCategory.target.value == '02') {
      startDate.setDate(startDate.getDate() - 8);
      endDate.setDate(endDate.getDate() - 1);
      setSelectedDate('');
    } else if (changeCategory.target.value == '03') {
      startDate.setDate(startDate.getDate() - 31);
      endDate.setDate(endDate.getDate() - 1);
      setSelectedDate('');
    } else if (changeCategory.target.value == '04') {
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 2000);
      setSelectedDate('직접입력');
    }

    setFdt(startDate);
    setEdt(endDate);
    if (changeCategory.target.value == '04') {
      setActiveCustom(true);
    } else {
      setActiveCustom(false);
      handleSearch({
        firstStartDate: startDate,
        firstEndDate: endDate,
      });
      handleSubmit();
    }
  };

  const handleCalendar = (calendarDate) => {
    const { name, value } = calendarDate;
    handleSearch({
      [name]: value,
    });
    handleSubmit();
  };

  const getGuideContents = (limitPeriod = false) => {
    if (limitPeriod) {
      return (
        <Contents limitPeriod={limitPeriod}>
          <Title>분석 기간 안내</Title>
          <br />
          최근 데이터만 분석 & 조회 가능합니다.
          <br />
          <br />
          <br />
          <Title>기간 설정에 따른 차트 안내</Title>
          <br />
          차트는 데이터 가독성을 위해 '전체' 혹은 '직접입력'인 경우 최근 180일
          간의 데이터만 표시됩니다. ('긍부정비율' 제외)
          <br />
          <br />단 아래와 같은 경우는 설정한 기간 모두 데이터에 표시됩니다.
          <br />
          <br />
          1. '전체' 기간이 60일 이하인 경우
          <br />
          2. '직접입력'에서 기간설정이 60일 이하인 경우
        </Contents>
      );
    } else {
      return (
        <Contents limitPeriod={limitPeriod}>
          <Title>기간 설정에 따른 차트 안내</Title>
          <br />
          <br />
          차트는 데이터 가독성을 위해 '전체' 혹은 '직접입력'인 경우 최근 180일
          간의 데이터만 표시됩니다. ('긍부정비율' 제외)
          <br />
          <br />단 아래와 같은 경우는 설정한 기간 모두 데이터에 표시됩니다.
          <br />
          <br />
          1. '전체' 기간이 60일 이하인 경우
          <br />
          2. '직접입력'에서 기간설정이 60일 이하인 경우
        </Contents>
      );
    }
  };

  return (
    <Box style={{ backgroundColor: '#FFFFFF' }}>
      <Container
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {(!hidden || alert) && getGuideContents(limitPeriod)}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <SubTitle>기간설정</SubTitle>
          <div
            style={{
              minWidth: '165px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <div style={{ width: 167, position: 'relative' }}>
              <Select
                name="category-topic"
                options={dateOptions}
                selectedValue={
                  searchType == 'total'
                    ? '01'
                    : searchType == 'last7Days'
                    ? '02'
                    : '03'
                }
                onChange={handleCategory}
                style={{
                  justifyContent: 'flex-end',
                  width: '165px',
                  cursor: 'pointer',
                }}
              />
              {(selectedDate === '전체' || selectedDate === '직접입력') && (
                <Tooltip
                  selectedDate={selectedDate}
                  src={tooltip}
                  alt=""
                  onMouseOver={() => setHidden(false)}
                  onMouseOut={() => setHidden(true)}
                />
              )}
            </div>
            {!activeCustom ? (
              <div
                style={{
                  marginTop: 10,
                  color: '#4318FF',
                  height: 30,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {getDateWithFormat(fdt)} ~ {getDateWithFormat(edt)}
              </div>
            ) : (
              <div
                style={{
                  marginTop: 10,
                  height: 30,
                  color: '#4318FF',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Calendar
                  selected={fdt}
                  onChange={(date) =>
                    handleCalendar({ name: 'firstStartDate', value: date })
                  }
                />
                &nbsp;~&nbsp;
                <Calendar
                  selected={edt}
                  onChange={(date) =>
                    handleCalendar({ name: 'firstEndDate', value: date })
                  }
                />
              </div>
            )}
          </div>
        </div>
      </Container>
    </Box>
  );
};

const Container = styled.form`
  gap: 1rem;
  font-size: 0.8rem;
  position: relative;
`;

const Tooltip = styled.img`
  position: absolute;
  width: 13px;
  height: 13px;
  top: 11px;
  left: ${({ selectedDate }) => (selectedDate === '전체' ? '40px' : '66px')};
  z-index: 10;
  cursor: pointer;
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #2b3674;
  margin-bottom: 10px;
`;

const Contents = styled.div`
  letter-spacing: 2px;
  width: 340px;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.3px;
  color: #2b3674;
  position: absolute;
  top: ${({ limitPeriod }) => (limitPeriod ? '-300px' : '-230px')};
  right: 0px;
  z-index: 20;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 5px 5px 5px 2px rgba(43, 54, 116, 0.45);
`;

export default SearchDateForm;
