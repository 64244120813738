import React, {
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PointLeft, PointRight } from 'components/atoms/icon';

export default function Carousel({ children, autoflow = 0 }) {
  const [currentLoopIndex, setCurrentLoopIndex] = useState(0);
  const [isFlowing, setIsFlowing] = useState(true);
  const size = useMemo(
    () => (Array.isArray(children) ? children : [children]).length,
    [children],
  );
  const slideList = Array.isArray(children) ? children : [children];
  const isAutoFlow = autoflow > 0;

  const getStaticIndex = useCallback(
    (loopIndex) => {
      let rest = loopIndex % size;
      if (rest < 0) {
        rest += size;
      }
      return rest;
    },
    [size],
  );

  const getNearestLoopIndex = useCallback(
    (staticIndex) => {
      const currentStaticIndex = getStaticIndex(currentLoopIndex);
      const diff = staticIndex - currentStaticIndex;
      return currentLoopIndex + diff;
    },
    [currentLoopIndex, getStaticIndex],
  );

  useLayoutEffect(() => {
    let intervalId;
    if (isFlowing) {
      if (isAutoFlow) {
        intervalId = setInterval(() => {
          setCurrentLoopIndex(currentLoopIndex + 1);
        }, autoflow);
      }
    }
    return () => clearTimeout(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFlowing, setCurrentLoopIndex, currentLoopIndex, autoflow]);

  const [startX, setStartX] = useState();
  const [pos, setPos] = useState(0);
  const sliderRef = useRef();

  function getClientX(event) {
    // eslint-disable-next-line no-nested-ternary
    return event._reactName === 'onTouchStart'
      ? event.touches[0].clientX
      : event._reactName === 'onTouchMove' || event._reactName === 'onTouchEnd'
      ? event.changedTouches[0].clientX
      : event.clientX;
  }

  const onDragStart = (e) => {
    e.preventDefault();
    setStartX((prev) => getClientX(e));
  };

  const onDragMove = (e) => {
    if (startX) {
      setPos((prev) => getClientX(e) - startX);
    }
  };

  const onDragLeave = (e) => {
    if (pos) {
      const currentTouchX = getClientX(e);
      if (startX > currentTouchX + 100) {
        setCurrentLoopIndex(currentLoopIndex + 1);
      } else if (startX < currentTouchX - 100) {
        setCurrentLoopIndex(currentLoopIndex - 1);
      }
      setPos((prev) => 0);
    }
    setStartX((prev) => null);
  };

  return (
    <Container
      onMouseOver={() => isAutoFlow && setIsFlowing(false)}
      onMouseOut={() => isAutoFlow && setIsFlowing(true)}
    >
      <SliderContainer
        translate={`
        calc(${-100 * size - 100 * currentLoopIndex}% + ${pos}px)
        `}
        onMouseDown={onDragStart}
        onTouchStart={onDragStart}
        onMouseMove={onDragMove}
        onTouchMove={onDragMove}
        onMouseUp={onDragLeave}
        onMouseLeave={onDragLeave}
        onTouchEnd={onDragLeave}
        ref={sliderRef}
      >
        <SliderContent translate={`${100 * currentLoopIndex}%`}>
          {Array(size * 2 + 1)
            .fill(1)
            .map((_, index) => {
              const loopIndexToShow = currentLoopIndex + index - size;
              return {
                staticIndex: getStaticIndex(loopIndexToShow),
                loopIndexToShow,
              };
            })
            .map(({ staticIndex, loopIndexToShow }, index) => (
              <SliderWrapper key={loopIndexToShow}>
                {slideList[staticIndex]}
              </SliderWrapper>
            ))}
        </SliderContent>
      </SliderContainer>
      <DotWrapper>
        {slideList.map((_, index) => (
          <button
            key={index}
            className={
              getStaticIndex(currentLoopIndex) === index ? 'active' : ''
            }
            onClick={() => setCurrentLoopIndex(getNearestLoopIndex(index))}
          >
            {index}
          </button>
        ))}
      </DotWrapper>
      <MoveBtn
        className="prev"
        onClick={() => setCurrentLoopIndex(currentLoopIndex - 1)}
        aria-label="Go to Previous"
      >
        <PointLeft />
      </MoveBtn>
      <MoveBtn
        className="next"
        onClick={() => setCurrentLoopIndex(currentLoopIndex + 1)}
        aria-label="Go to Next"
      >
        <PointRight />
      </MoveBtn>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: inherit;
  height: inherit;
  overflow: hidden;
`;

const SliderContainer = styled.div`
  display: flex;
  height: 100%;
  transition: all 0.5s ease-in-out;
  transform: translateX(${({ translate }) => translate});
`;

const SliderContent = styled.div`
  display: inline-flex;
  width: 100%;
  transform: translateX(${({ translate }) => translate});
`;

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;

  * {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-user-drag: none;
  }
`;

const MoveBtn = styled.button`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: #ffffffd9;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 50%;
  cursor: pointer;

  &.prev {
    left: 5%;
  }

  &.next {
    right: 5%;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &:disabled {
    color: #ffffff40;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: not-allowed;
  }
`;

const DotWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 10px;
  display: flex;
  justify-content: center;
  gap: 5px;

  button {
    font-size: 0;
    line-height: 0;
    display: block;
    padding: 7px;
    color: transparent;
    border: 0;
    border-radius: 50%;
    outline: none;
    background-color: lightgray;
    border: none;
    cursor: pointer;
    color: transparent;

    &.active {
      background-color: white;
    }

    &:hover {
      background-color: white;
    }
  }
`;

Carousel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  autoflow: PropTypes.oneOfType([PropTypes.number]),
};
