import React, { useState, useRef, useEffect } from 'react';
import { render } from 'react-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import SentStat from 'components/templates/layout/SentStat';
import TrendStat from 'components/templates/layout/TrendStat';
import styled from 'styled-components';
import { Button, SubTitle, Label } from 'components/atoms';
import { PropContextProvider } from 'context/PropContext';
import SelectSortOption from 'components/templates/layout/SelectSortOption';
import DataList from 'components/templates/layout/DataList';
import useTable from 'hooks/useTable';
import * as d3 from 'd3';
import Stat from 'components/templates/layout/Stat';
import ItemStat from 'components/templates/layout/ItemStat';
import SearchDateFormNew from 'components/templates/layout/SearchDateFormNew';
import EmptyData from 'components/templates/layout/EmptyData';
import MultiLineChart from 'app/chart/MultiLineChart';
import StackBarChart from 'app/chart/StackBarChart';
import PieChart from 'app/chart/PieChart';
import ZoomableSunBurstChart from 'app/chart/ZoomableSunBurstChart';
import { Pagination } from 'components/blocks';
import { CSVLink } from 'react-csv';
import {
  getDataStatItem,
  getDataStatItemDaily,
  getDataStatItemSent,
  getDataStatItemTopic,
  getDataStatItemTopicList,
  getDataStatItemTopicSeries,
  getDataNavigateReviewList,
  getDataStatItemDownload,
} from 'utils/api';
import { ReactComponent as Pointer } from 'assets/svg/chevron-down.svg';
import Contents from './Contents';
import chevron from 'assets/images/arrow_down_grey.png';
import pos_line from 'assets/images/pos_line.png';
import neg_line from 'assets/images/neg_line.png';
import na_line from 'assets/images/na_line.png';
import downloadImage from 'assets/images/download.png';
import { ReactComponent as ChevronRight } from 'assets/images/chevron-right.svg';
import { ReactComponent as ChevronLeft } from 'assets/images/chevron-left.svg';
import { ReactComponent as ChevronSort } from 'assets/images/sort-svgrepo-com.svg';

let chartOption01 = {
  chart: {
    type: 'area',
    height: '250',
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  xAxis: {
    visible: false,
    categories: [],
  },
  yAxis: {
    visible: false,
    title: {
      text: '',
    },
  },
  colors: ['#90E4CE', '#5A55D2'],
  tooltip: {
    pointFormat:
      '<span style="color:{series.color}"><b>●</b></span>: <b>{point.y:.0f}</b><br/>',
    shared: true,
  },
  plotOptions: {
    area: {
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 2,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
      lineWidth: 3,
    },
  },
  series: [],
};

const sortRules = [
  { id: 'DESC', value: 'DESC', name: '내림차순' },
  { id: 'ASC', value: 'ASC', name: '오름차순' },
];

const sortFields = [
  { id: 'TOT_REVW_CNT', value: 'TOT_REVW_CNT', name: '리뷰 수' },
  { id: 'TOT_POS_SENT_CNT', value: 'TOT_POS_SENT_CNT', name: '긍정 문장 수' },
  { id: 'TOT_NEG_SENT_CNT', value: 'TOT_NEG_SENT_CNT', name: '부정 문장 수' },
];

const columns = [
  { label: '주제항목', col: 'sp_category1' },
  { label: '리뷰', col: 'tot_revw_cnt' },
  { label: '비중', col: 'tot_revw_ratio' },
  { label: '긍정 문장', col: 'tot_pos_sent_cnt' },
  { label: '부정 문장', col: 'tot_neg_sent_cnt' },
  { label: '긍정 비율', col: 'tot_pos_sent_ratio' },
  { label: '부정 비율', col: 'tot_neg_sent_ratio' },
  { label: '긍/부정', col: 'tot_neg_sent_cnt' },
];

const sentimentOption = [
  { id: '', value: null, name: '전체' },
  { id: 'POS', value: 'POS', name: '긍정', img: pos_line },
  { id: 'NEG', value: 'NEG', name: '부정', img: neg_line },
  { id: 'NA', value: 'NA', name: '중립', img: na_line },
];

const trendMaxWindow = 180;

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const ItemDetail = ({ itemCode, handleShowDetail, searchType = 'total' }) => {
  const [trendSeries, setTrendSeries] = useState([]);
  const [trendSeriesChanged, setTrendSeriesChanged] = useState(false);
  const [series, setSeries] = useState([]);
  const [seriesChanged, setSeriesChanged] = useState(false);
  const [selectedValue00, setSelectedValue00] = useState('tot_revw_cnt');
  const [selectedValue, setSelectedValue] = useState('tot_revw_cnt');
  const [revwTotal, setRevwTotal] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [reviewSeries, setReviewSeries] = useState([]);
  const [sentSeries, setSentSeries] = useState([]);
  const [sentCountSeries, setSentCountSeries] = useState([]);
  const [chartOption, setChartOption] = useState();
  const [highchartOption01, setHighchartOption01] = useState();
  const [highchartOption02, setHighchartOption02] = useState();
  const [highchartOption03, setHighchartOption03] = useState();
  const [sentStatChanged, setSentStatChanged] = useState(false);
  const [sentStat, setSentStat] = useState(null);
  const [itemStat, setItemStat] = useState(null);
  const [review, setReview] = useState(null);
  const [changed, setChanged] = useState(false);
  const getPeriodDate = (type) => {
    let targetDate = new Date();
    if (searchType == 'total') {
      return type == 'start' ? dateRange?.startDate : dateRange?.endDate;
    } else {
      if (searchType == 'last7Days') {
        if (type == 'start') {
          targetDate.setDate(targetDate.getDate() - 8);
        } else {
          targetDate.setDate(targetDate.getDate() - 1);
        }
      } else if (searchType == 'last30Days') {
        if (type == 'start') {
          targetDate.setDate(targetDate.getDate() - 31);
        } else {
          targetDate.setDate(targetDate.getDate() - 1);
        }
      }
      return targetDate;
    }
  };
  const [search, setSearch] = useState({
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    endDate: new Date(),
    firstStartDate: getPeriodDate('start'),
    firstEndDate: getPeriodDate('end'),
    secondStartDate: dateRange?.startDate,
    secondEndDate: dateRange?.endDate,
  });
  const [option01, setOption01] = useState({
    page: 1,
    size: 50,
    sort_field: 'TOT_REVW_CNT',
    sort_rule: 'DESC',
    item_code: itemCode,
  });
  const [option02, setOption02] = useState({
    page: 1,
    size: 8,
    sort_field: 'REVIEW_CREATED_DATE',
    sort_rule: 'DESC',
    item_code: itemCode,
    topic: '',
    sentiment: null,
  });
  const option03 = [
    { id: '', name: '전체' },
    { id: 'POS', name: '긍정' },
    { id: 'NEG', name: '부정' },
    { id: 'NA', name: '중립' },
  ];
  const [categoryOption, setCategoryOption] = useState({
    changed: true,
    topic: null,
  });
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const [csvData, setCsvData] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [selectedAllCategory, setSelectedAllCategory] = useState(true);
  const [stat, setStat] = useState();
  const [overall, setOverall] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [categories, setCategories] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState('');
  const [data, setData] = useState({ totalCnt: 0, items: [] });
  const table = useTable({ columns, initData: data.items });
  const [currentContents, setCurrentContents] = useState('');
  const [sentiment, setSentiment] = useState({ id: '', name: '전체' });
  const [select, setSelect] = useState(option03[0].id);
  const [isActive, setIsActive] = useState(false);
  const view = useRef();
  const downloadRef = useRef();
  let period = null;

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!empty) {
      if (itemCode != '') {
        if (searchType == 'last7Days') {
          let startDate = new Date();
          let endDate = new Date();
          startDate.setDate(startDate.getDate() - 8);
          endDate.setDate(endDate.getDate() - 1);
          setSearch({
            ...search,
            firstStartDate: startDate,
            firstEndDate: endDate,
          });
        } else if (searchType == 'last30Days') {
          let startDate = new Date();
          let endDate = new Date();
          startDate.setDate(startDate.getDate() - 31);
          endDate.setDate(endDate.getDate() - 1);
          setSearch({
            ...search,
            firstStartDate: startDate,
            firstEndDate: endDate,
          });
        }
        getOverall();
        getItemOverallSent();
        getItemOverallSingle();
        getItemOverallDaily();
      }
    }
  }, [empty, option01]);

  useEffect(() => {
    if (itemCode != '') {
      getItemOverallSingleSeries();
    }
  }, [categoryOption]);

  useEffect(() => {
    getReviewList();
  }, [option02]);

  useEffect(() => {
    getCategories();
  }, [itemCode, currentCategory]);

  const selectedCategory = (idx, arrow) => {
    let target = '';
    if (idx === 0 && arrow === 'prev') {
      target = categories[categories.length - 1];
    } else if (idx === categories.length - 1 && arrow === 'next') {
      target = categories[0];
    } else {
      if (arrow === 'next') {
        target = categories[idx + 1];
      } else {
        target = categories[idx - 1];
      }
    }
    setSelectedCategories(target);
    setCategoryOption({ topic: target, changed: !categoryOption.changed });
    setChanged(!changed);
    table.convert.setColStyle = (item) => {
      const { colIdx, rowIdx } = item;
      if ([0].includes(rowIdx)) {
        return {
          backgroundColor: '#ebf1fc',
          padding: '20px 0.75rem',
          color: '#2B3674',
          fontWeight: 600,
          textAlign: 'center',
          borderRight: [0, 2, 4].includes(colIdx)
            ? '1px dashed #A3AED0'
            : '0px',
        };
      } else {
        return {
          padding: '0.7rem 0.75rem',
          cursor: 'pointer',
          backgroundColor:
            categoryOption.topic === categories[rowIdx - 1]
              ? '#F6F6FC'
              : '#FFF',
          color: '#2B3674',
          fontWeight: 600,
          textAlign: 'center',
          borderRight: '1px dashed #A3AED0',
          borderRight: [0, 2, 4].includes(colIdx)
            ? '1px dashed #A3AED0'
            : '0px',
        };
      }
    };
  };

  const getColor = (value) => {
    const color = d3.scaleOrdinal(
      d3.quantize(d3.interpolateRainbow, categories.length + 1),
    );

    const colors = categories.map((item) => {
      return color(item);
    });

    return colors[value];
  };

  const changedSelectedValue = (value) => {
    setSelectedValue(value);
    chartOption01.series = [];
    chartOption01.series.push({
      name: series.targetA[0].topic,
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, '#90E4CE'],
          [1, '#ffffff'],
        ],
      },
      data: series.targetA.map((item) => {
        if (value === 'tot_revw_cnt') {
          return item.tot_revw_cnt;
        } else if (value === 'tot_pos_sent_cnt') {
          return item.tot_pos_sent_cnt;
        } else if (value === 'tot_neg_sent_cnt') {
          return item.tot_neg_sent_cnt;
        }
      }),
    });
    chartOption01.series.push({
      name: '전체 주제항목별 평균',
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, '#5A55D2'],
          [1, 'rgba(255, 255, 255, 0.25)'],
        ],
      },
      data: series.targetB.map((item) => {
        if (value === 'tot_revw_cnt') {
          return item.tot_revw_cnt;
        } else if (value === 'tot_pos_sent_cnt') {
          return item.tot_pos_sent_cnt;
        } else if (value === 'tot_neg_sent_cnt') {
          return item.tot_neg_sent_cnt;
        }
      }),
    });
    setChartOption(chartOption01);
    setSeriesChanged(!seriesChanged);
  };

  const getDatesParam = (from, to) => {
    const fYY = from?.getFullYear();
    const fMM = from?.getMonth() + 1;
    const fDD = from?.getDate();
    const eYY = to?.getFullYear();
    const eMM = to?.getMonth() + 1;
    const eDD = to?.getDate();
    return from && to
      ? {
          fdt: fYY + (fMM < 10 ? '0' : '') + fMM + (fDD < 10 ? '0' : '') + fDD,
          edt: eYY + (eMM < 10 ? '0' : '') + eMM + (eDD < 10 ? '0' : '') + eDD,
        }
      : null;
  };

  const handleOption01 = (changeObj) => {
    const page = changeObj.page ? changeObj : 1;
    setOption01({ ...option01, page, ...changeObj });
    //view.current.scrollIntoView();
  };

  const handleOption02 = (changeObj) => {
    const page = changeObj.page ? changeObj : 1;
    setOption02({ ...option02, page, ...changeObj });
    //view.current.scrollIntoView();
  };

  const handleReset = () => {
    setSearch({
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      endDate: new Date(),
      firstStartDate: dateRange?.startDate,
      firstEndDate: dateRange?.endDate,
      secondStartDate: dateRange?.startDate,
      secondEndDate: dateRange?.endDate,
    });
    setOption01({
      page: 1,
      size: 50,
      sort_field: 'TOT_REVW_CNT',
      sort_rule: 'DESC',
      item_code: itemCode,
    });
    setOption02({
      page: 1,
      size: 8,
      sort_field: 'REVIEW_CREATED_DATE',
      sort_rule: 'DESC',
      item_code: itemCode,
      topic: '',
    });
    setCategoryOption({
      changed: !categoryOption.changed,
    });
    setSelectedValue('tot_revw_cnt');
    setChanged(!changed);
  };

  const handleSubmit = () => {
    setOption01({ ...option01, page: 1 });
    setOption02({ ...option02, topic: '' });
    setCategoryOption({ ...categoryOption, changed: !categoryOption.changed });
    setChanged(!changed);
  };

  const handleSearch = (changeData) => {
    setSearch({ ...search, ...changeData });
  };

  const handleTableOption = (key) => {
    let sort = 'DESC';
    if (
      key.toUpperCase() == option01.sort_field &&
      option01.sort_rule === 'DESC'
    )
      sort = 'ASC';
    setOption01({
      ...option01,
      sort_field: key.toUpperCase(),
      sort_rule: sort,
    });
  };

  const getPortionValue = (value01, value02) => {
    const result = ((value01 / value02) * 100).toFixed(2);
    if (result === 'Infinity' || result === 'NaN') {
      return '-%';
    } else {
      return result + '%';
    }
  };

  const getRatioValue = (value01, value02) => {
    const result = ((value01 / value02) * 100 - 100).toFixed(2);
    if (result === 'Infinity' || result === 'NaN') {
      return '-%';
    } else {
      return result + '%';
    }
  };

  // TODO00
  const getOverall = async () => {
    const res = await getDataStatItem({ item_code: itemCode });

    if (
      res.results.tot_revw_cnt +
        res.results.tot_sent_cnt +
        res.results.tot_pos_sent_cnt +
        res.results.tot_neg_sent_cnt +
        res.results.tot_topic_na_sent_cnt >
      0
    ) {
      const today = new Date();
      const fDate = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate(),
      );
      /*
      const toDate = new Date();
      const frDate = new Date(new Date().setDate(toDate.getDate() - 30));
      const dates = getDatesParam(frDate, toDate);
      const fYYMMDD =
        res.results.series.length > 0 ? res.results.series[0]?.dt : dates.fdt;
      const fDate = new Date(
        fYYMMDD.substring(0, 4),
        fYYMMDD.substring(4, 6) - 1,
        fYYMMDD.substring(6, 8),
      );
      const eYYMMDD =
        res.results.series.length > 0
          ? res.results.series[res.results.series.length - 1].dt
          : dates.edt;
      const eDate = new Date(
        eYYMMDD.substring(0, 4),
        eYYMMDD.substring(4, 6) - 1,
        eYYMMDD.substring(6, 8),
      );
      const diff = Math.floor(
        Math.abs(eDate.getTime() - fDate.getTime()) / (1000 * 60 * 60 * 24),
      );
      */
      const diff = 30;
      let trends01 = [];
      let trends02 = [];
      let trends03 = [];
      let trends04 = [];
      let trends05 = [];
      for (let i = 0; i <= diff + 1; i++) {
        const curr = new Date(new Date().setDate(fDate.getDate() + i));
        const YY = curr.getFullYear();
        const MM = curr.getMonth();
        const DD = curr.getDate();
        const YYMMDD =
          YY + (MM < 10 ? '0' : '') + MM + (DD < 10 ? '0' : '') + DD;
        const value = res.results.series.filter((item) => {
          return item.dt === YYMMDD;
        });
        trends01.push({
          x: i,
          y: value.length > 0 ? value[0].tot_revw_cnt : 0,
        });
        trends02.push({
          x: i,
          y: value.length > 0 ? value[0].tot_sent_cnt : 0,
        });
        trends03.push({
          x: i,
          y: value.length > 0 ? value[0].tot_pos_sent_cnt : 0,
        });
        trends04.push({
          x: i,
          y: value.length > 0 ? value[0].tot_neg_sent_cnt : 0,
        });
        trends05.push({
          x: i,
          y: value.length > 0 ? value[0].tot_topic_na_sent_cnt : 0,
        });
      }
      setOverall([
        {
          title: '리뷰',
          type: 'review',
          count: res.results.tot_revw_cnt,
          cnt_7: res.results.tot_revw_cnt_7,
          cnt_30: res.results.tot_revw_cnt_30,
          ratio_7: res.results.tot_revw_compare_ratio_7,
          ratio_30: res.results.tot_revw_compare_ratio_30,
        },
        {
          title: '긍정',
          type: 'classification',
          count: res.results.tot_pos_sent_cnt,
          cnt_7: res.results.tot_pos_sent_cnt_7,
          cnt_30: res.results.tot_pos_sent_cnt_30,
          ratio_7: res.results.tot_pos_compare_ratio_7,
          ratio_30: res.results.tot_pos_compare_ratio_30,
          ratio: res.results.tot_pos_sent_ratio,
        },
        {
          title: '부정',
          type: 'classification',
          count: res.results.tot_neg_sent_cnt,
          cnt_7: res.results.tot_neg_sent_cnt_7,
          ratio_7: res.results.tot_neg_compare_ratio_7,
          cnt_30: res.results.tot_neg_sent_cnt_30,
          ratio_30: res.results.tot_neg_compare_ratio_30,
          ratio: res.results.tot_neg_sent_ratio,
        },
      ]);
      setItemStat({
        itemCode: res.results.item_code,
        category: res.results.category_name,
        title: res.results.title,
        image: res.results.image_url,
        date: res.results.created_date,
        url: res.results.url,
      });
    } else {
      setEmpty(true);
    }
  };

  // TODO01
  const getItemOverallSingle = async () => {
    const dates = getDatesParam(search.firstStartDate, search.firstEndDate);
    const res = await getDataStatItemTopic({
      ...option01,
      ...dates,
      item_code: itemCode,
    });

    if (Object.keys(res.results.dt_range).length > 0) {
      if (!dateRange) {
        const { from, to } = res.results.dt_range;
        period = {
          startDate: new Date(
            from.substring(0, 4),
            from.substring(4, 6) - 1,
            from.substring(6, 8),
          ),
          endDate: new Date(
            to.substring(0, 4),
            to.substring(4, 6) - 1,
            to.substring(6, 8),
          ),
        };
        setDateRange(period);
        setSearch({
          ...search,
          firstStartDate: period?.startDate,
          firstEndDate: period?.endDate,
          secondStartDate: period?.startDate,
          secondEndDate: period?.endDate,
        });
      }
      const totalRevw = res.results.items.reduce(function (prev, curr) {
        return typeof prev === 'object'
          ? prev.tot_revw_cnt
          : prev + curr.tot_revw_cnt;
      }, 0);
      const totalSent = res.results.items.reduce(function (prev, curr) {
        return typeof prev === 'object'
          ? prev.tot_sent_cnt
          : prev + curr.tot_sent_cnt;
      }, 0);
      const target = res?.results.overall_stat;
      setData({
        totalCnt: res?.results.total,
        overallStat: res?.results.overall_stat,
        items: [
          {
            sp_category1: '전체',
            tot_revw_cnt: target.tot_revw_cnt.toLocaleString(),
            tot_revw_ratio: target.tot_revw_ratio,
            tot_pos_sent_cnt: target.tot_pos_sent_cnt.toLocaleString(),
            tot_pos_sent_ratio: target.tot_pos_sent_ratio,
            tot_neg_sent_cnt: target.tot_neg_sent_cnt.toLocaleString(),
            tot_neg_sent_ratio: target.tot_neg_sent_ratio,
          },
        ].concat(
          res.results.items.map((item) => {
            return {
              sp_category1: item.topic,
              tot_revw_cnt: item.tot_revw_cnt.toLocaleString(),
              tot_revw_ratio: item.tot_revw_ratio,
              tot_pos_sent_cnt: item.tot_pos_sent_cnt.toLocaleString(),
              tot_pos_sent_ratio: item.tot_pos_sent_ratio,
              tot_neg_sent_cnt: item.tot_neg_sent_cnt.toLocaleString(),
              tot_neg_sent_ratio: item.tot_neg_sent_ratio,
            };
          }),
        ),
      });
      setCategories(
        res.results.items.map((item) => {
          return item.topic;
        }),
      );
      if (selectedCategories.length < 1) {
        setSelectedCategories([res.results.items[0].topic, '전체']);
      }
      setCurrentCategory(
        res?.results?.items.length > 0 ? res.results.items[0].topic : '',
      );
      setOption02({
        ...option02,
        item_code: option01.item_code,
        topic: res?.results?.items.length > 0 ? res.results.items[0].topic : '',
      });
    } else {
      setData({ totalCnt: 0, items: [] });
      setCategories([]);
      setSelectedCategories([]);
      setCurrentCategory('');
      setOption02({
        ...option02,
        item_code: option01.item_code,
        topic: res?.results?.items.length > 0 ? res.results.items[0].topic : '',
      });
    }

    if (!categoryOption.topic && res.results.items.length > 0) {
      setCategoryOption({
        ...categoryOption,
        topic: res.results.items[0].topic,
      });
    }
  };

  // TODO02
  const getItemOverallSingleSeries = async () => {
    if (categoryOption.topic) {
      const dates = getDatesParam(search.firstStartDate, search.firstEndDate);
      const res = await getDataStatItemTopicSeries({
        ...dates,
        item_code: option01.item_code,
        topic: categoryOption.topic,
      });
      const from = dates
        ? new Date(
            dates.fdt.substring(0, 4),
            dates.fdt.substring(4, 6) - 1,
            dates.fdt.substring(6, 8),
          )
        : new Date(
            period?.startDate.getFullYear(),
            period?.startDate.getMonth(),
            period?.startDate.getDate(),
          );
      const to = dates
        ? new Date(
            dates.edt.substring(0, 4),
            dates.edt.substring(4, 6) - 1,
            dates.edt.substring(6, 8),
          )
        : new Date(
            period?.endDate.getFullYear(),
            period?.endDate.getMonth(),
            period?.endDate.getDate(),
          );
      const diff = Math.floor(
        Math.abs(to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24),
      );
      const start = diff <= trendMaxWindow ? 0 : diff - trendMaxWindow;
      let temp = {
        targetA: [],
        targetB: [],
        categories: [],
      };
      let xCategories = [];
      let curr = dates
        ? new Date(
            dates.fdt.substring(0, 4),
            dates.fdt.substring(4, 6) - 1,
            dates.fdt.substring(6, 8),
          )
        : new Date(
            period?.startDate.getFullYear(),
            period?.startDate.getMonth(),
            period?.startDate.getDate(),
          );
      curr.setDate(curr.getDate() + (start - 1));
      for (let i = start; i <= diff; i++) {
        curr.setDate(curr.getDate() + 1);
        const YY = curr.getFullYear();
        const MM = curr.getMonth() + 1;
        const DD = curr.getDate();
        const YYMMDD =
          YY + (MM < 10 ? '0' : '') + MM + (DD < 10 ? '0' : '') + DD;
        const YYMMDD02 =
          YY +
          '-' +
          (MM < 10 ? '0' : '') +
          MM +
          '-' +
          (DD < 10 ? '0' : '') +
          DD;
        temp.categories.push(YYMMDD02);
        for (let j = 0; j < res.results.items.length; j++) {
          const target = res.results.items[j].series.filter((item) => {
            return item.dt === YYMMDD;
          });
          if (target.length > 0) {
            temp.targetA.push({
              topic: target[0].topic,
              tot_revw_cnt: target[0].tot_revw_cnt,
              tot_pos_sent_cnt: target[0].tot_pos_sent_cnt,
              tot_neg_sent_cnt: target[0].tot_neg_sent_cnt,
            });
          } else {
            temp.targetA.push({
              topic: res.results.items[j].topic,
              tot_revw_cnt: 0,
              tot_pos_sent_cnt: 0,
              tot_neg_sent_cnt: 0,
            });
          }
        }
        const targetB = res.results.compare.series.filter((item) => {
          return item.dt === YYMMDD;
        });
        if (targetB.length > 0) {
          temp.targetB.push({
            topic: targetB[0].topic,
            tot_revw_cnt: targetB[0].tot_revw_cnt_avg,
            tot_pos_sent_cnt: targetB[0].tot_pos_sent_cnt_avg,
            tot_neg_sent_cnt: targetB[0].tot_neg_sent_cnt_avg,
          });
        } else {
          temp.targetB.push({
            topic: '전체',
            tot_revw_cnt: 0,
            tot_pos_sent_cnt: 0,
            tot_neg_sent_cnt: 0,
          });
        }
      }
      setSeries(temp);
      chartOption01.xAxis.categories = temp.categories;
      chartOption01.series = [];
      chartOption01.series.push({
        name: temp.targetA[0].topic,
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#90E4CE'],
            [1, '#ffffff'],
          ],
        },
        data: temp.targetA.map((item) => {
          if (selectedValue === 'tot_revw_cnt') {
            return item.tot_revw_cnt;
          } else if (selectedValue === 'tot_pos_sent_cnt') {
            return item.tot_pos_sent_cnt;
          } else if (selectedValue === 'tot_neg_sent_cnt') {
            return item.tot_neg_sent_cnt;
          }
        }),
      });
      chartOption01.series.push({
        name: '전체 주제항목별 평균',
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#5A55D2'],
            [1, 'rgba(255, 255, 255, 0.25)'],
          ],
        },
        data: temp.targetB.map((item) => {
          if (selectedValue === 'tot_revw_cnt') {
            return item.tot_revw_cnt;
          } else if (selectedValue === 'tot_pos_sent_cnt') {
            return item.tot_pos_sent_cnt;
          } else if (selectedValue === 'tot_neg_sent_cnt') {
            return item.tot_neg_sent_cnt;
          }
        }),
      });
      setChartOption(chartOption01);
      setSeriesChanged(!seriesChanged);
    }
  };

  // TODO03
  const getItemOverallDaily = async () => {
    const dates = getDatesParam(search.firstStartDate, search.firstEndDate);
    const res = await getDataStatItemDaily({
      ...dates,
      item_code: option01.item_code,
    });

    setReviewCount(res.results.stat.tot_revw_cnt);

    const from = dates
      ? new Date(
          dates.fdt.substring(0, 4),
          dates.fdt.substring(4, 6) - 1,
          dates.fdt.substring(6, 8),
        )
      : new Date(
          res.results.dt_range.from.substring(0, 4),
          res.results.dt_range.from.substring(4, 6) - 1,
          res.results.dt_range.from.substring(6, 8),
        );
    const to = dates
      ? new Date(
          dates.edt.substring(0, 4),
          dates.edt.substring(4, 6) - 1,
          dates.edt.substring(6, 8),
        )
      : new Date(
          res.results.dt_range.to.substring(0, 4),
          res.results.dt_range.to.substring(4, 6) - 1,
          res.results.dt_range.to.substring(6, 8),
        );

    const diff = Math.floor(
      Math.abs(to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24),
    );
    const start = diff <= trendMaxWindow ? 0 : diff - trendMaxWindow;

    let temp = {
      categories: [],
      series: [],
    };
    let curr = dates
      ? new Date(
          dates.fdt.substring(0, 4),
          dates.fdt.substring(4, 6) - 1,
          dates.fdt.substring(6, 8),
        )
      : new Date(
          res.results.dt_range.from.substring(0, 4),
          res.results.dt_range.from.substring(4, 6) - 1,
          res.results.dt_range.from.substring(6, 8),
        );
    curr.setDate(curr.getDate() + (start - 1));
    for (let i = start; i <= diff; i++) {
      curr.setDate(curr.getDate() + 1);
      const YY = curr.getFullYear();
      const MM = curr.getMonth() + 1;
      const DD = curr.getDate();
      const YYMMDD = YY + (MM < 10 ? '0' : '') + MM + (DD < 10 ? '0' : '') + DD;
      const YYMMDD02 =
        YY + '-' + (MM < 10 ? '0' : '') + MM + '-' + (DD < 10 ? '0' : '') + DD;
      temp.categories.push(YYMMDD02);
      const target = res.results.items.filter((item) => {
        return item.dt === YYMMDD;
      });
      if (target.length > 0) {
        temp.series.push({
          tot_revw_cnt: target[0].tot_revw_cnt,
          tot_pos_sent_cnt: target[0].tot_pos_sent_cnt,
          tot_neg_sent_cnt: target[0].tot_neg_sent_cnt,
        });
      } else {
        temp.series.push({
          tot_revw_cnt: 0,
          tot_pos_sent_cnt: 0,
          tot_neg_sent_cnt: 0,
        });
      }
    }
    setTrendSeries(temp);
    setTrendSeriesChanged(!trendSeriesChanged);
  };

  // TODO04
  const getItemOverallSent = async () => {
    const dates = getDatesParam(search.firstStartDate, search.firstEndDate);
    const res = await getDataStatItemSent({
      ...dates,
      item_code: option01.item_code,
    });
    setSentStat(res?.results.items[0]);
    setSentStatChanged(!sentStatChanged);
  };

  // TODO05
  const getReviewList = async () => {
    if (option02.topic !== '') {
      // const dates = getDatesParam(search.firstStartDate, search.firstEndDate);
      const res = await getDataNavigateReviewList({
        item_code: itemCode,
        topic: currentCategory,
        ...option02,
        // ...dates,
      });

      // Insert sentence to fetched results
      let _revw = res.results;
      for (let i = 0; i < _revw.items.length; i++) {
        const _item = _revw.items[i];
        // Replace multitext token in recipe review
        _item.review = _item.review.replace(/(\x01)/gi, '\n');
        _item.sentence = _item.review.substring(
          _item.topic[0].analyzed[0].start_idx,
          _item.topic[0].analyzed[0].end_idx,
        );
      }

      setReview(_revw);
      const total =
        res?.results?.stat?.tot_neg_sent_cnt +
        res?.results?.stat?.tot_pos_sent_cnt;
      const neg =
        res?.results?.stat?.tot_neg_sent_cnt !== 0
          ? Math.round((res?.results?.stat?.tot_neg_sent_cnt / total) * 100)
          : 0;
      const pos =
        res?.results?.stat?.tot_pos_sent_cnt !== 0
          ? Math.round((res?.results?.stat?.tot_pos_sent_cnt / total) * 100)
          : 0;
      setStat({
        neg: neg,
        neg_cnt: res?.results?.stat?.tot_neg_sent_cnt,
        pos: pos,
        pos_cnt: res?.results?.stat?.tot_pos_sent_cnt,
      });
    } else {
      setReview(null);
      setStat(null);
    }
  };

  const getCategories = async () => {
    const res = await getDataStatItemTopicList({
      item_code: itemCode,
    });
    setTopicList(
      res.results.map((item) => {
        return {
          topic: item.topic,
          ratio: item.ratio,
        };
      }),
    );
  };

  table.convert.setHead = (item) => {
    const { colIdx, key, label } = item;
    return <div style={{ alignItems: 'center' }}>{label}</div>;
  };

  table.convert.setCol = (item) => {
    const { rowIdx, colIdx } = item;
    if ([7].includes(colIdx)) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
          onClick={() => {
            if (rowIdx > 0) selectedCategory(rowIdx, 'prev');
          }}
          onMouseOver={() => {
            setHoverIdx(rowIdx);
          }}
        >
          {data.items[rowIdx]?.tot_pos_sent_ratio?.replace('%', '') !== '-' && (
            <div
              style={{
                backgroundColor: '#106EFC',
                width: parseInt(
                  data.items[rowIdx]?.tot_pos_sent_ratio?.replace('%', ''),
                ),
                height: 10,
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                borderTopRightRadius:
                  data.items[rowIdx]?.tot_pos_sent_ratio?.replace('%', '') >=
                  100
                    ? 5
                    : 0,
                borderBottomRightRadius:
                  data.items[rowIdx]?.tot_pos_sent_ratio?.replace('%', '') >=
                  100
                    ? 5
                    : 0,
              }}
            />
          )}
          {data.items[rowIdx]?.tot_neg_sent_ratio?.replace('%', '') !== '-' && (
            <div
              style={{
                backgroundColor: '#F86994',
                width: parseInt(
                  data.items[rowIdx]?.tot_neg_sent_ratio?.replace('%', ''),
                ),
                height: 10,
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                borderTopLeftRadius:
                  data.items[rowIdx]?.tot_neg_sent_ratio?.replace('%', '') >=
                  100
                    ? 5
                    : 0,
                borderBottomLeftRadius:
                  data.items[rowIdx]?.tot_neg_sent_ratio?.replace('%', '') >=
                  100
                    ? 5
                    : 0,
              }}
            />
          )}
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
          onClick={() => {
            if (rowIdx > 0) selectedCategory(rowIdx, 'prev');
          }}
          onMouseOver={() => {
            setHoverIdx(rowIdx);
          }}
        >
          {item.value.toLocaleString() || '-'}
        </div>
      );
    }
  };

  table.convert.setHeadStyle = () => {
    return {
      fontSize: '10pt',
      fontWeight: 500,
      color: '#A3AED0',
      backgroundColor: '#fff',
      border: '0px solid #fff',
    };
  };

  const [hoverIdx, setHoverIdx] = useState(-1);
  table.convert.setColStyle = (item) => {
    const { colIdx, rowIdx } = item;
    if ([0].includes(rowIdx)) {
      return {
        backgroundColor: '#ebf1fc',
        padding: '20px 0.75rem',
        color: '#2B3674',
        fontWeight: 600,
        textAlign: 'center',
        borderRight: [0, 2, 4].includes(colIdx) ? '1px dashed #A3AED0' : '0px',
      };
    } else {
      return {
        padding: '0.7rem 0.75rem',
        cursor: 'pointer',
        backgroundColor:
          categoryOption.topic === categories[rowIdx - 1]
            ? '#F6F6FC'
            : hoverIdx == rowIdx
            ? '#F6F6FC'
            : '#FFF',
        color: '#2B3674',
        fontWeight: 600,
        textAlign: 'center',
        borderRight: '1px dashed #A3AED0',
        borderRight: [0, 2, 4].includes(colIdx) ? '1px dashed #A3AED0' : '0px',
      };
    }
  };

  const handleTopic = (item) => {
    setCurrentCategory(item);
    setOption02({ ...option02, topic: item, page: 1 });
  };

  const handleReviewOption = (changeObj) => {
    const page = changeObj.page ? changeObj : 1;
    setOption02({ ...option02, page, ...changeObj });
    view.current.scrollIntoView();
  };

  const handleDownload = async () => {
    setCsvData([]);
    const dates = getDatesParam(search.firstStartDate, search.firstEndDate);
    const res = await getDataStatItemDownload({
      sort_field: option01.sort_field,
      sort_rule: option01.sort_rule,
      fdt: dates.fdt,
      edt: dates.edt,
      item_code: itemCode,
    });
    setCsvData(res);
    downloadRef.current.link.click();
  };

  const changeSentiment = (value) => {
    setSentiment(value);
  };

  const getCount = (topic) => {
    let result = '';
    const target = data.items.filter((item) => {
      return item.sp_category1 === topic;
    });
    if (selectedValue === 'tot_revw_cnt') {
      result = target[0].tot_revw_cnt.split('건')[0].toLocaleString();
    } else if (selectedValue === 'tot_pos_sent_cnt') {
      result = target[0].tot_pos_sent_cnt.split('건')[0].toLocaleString();
    } else if (selectedValue === 'tot_neg_sent_cnt') {
      result = target[0].tot_neg_sent_cnt.split('건')[0].toLocaleString();
    }
    return result;
  };

  return (
    <>
      {!empty ? (
        <Container>
          {itemStat && (
            <ItemStat
              itemStat={itemStat}
              type="button"
              handleShowDetail={handleShowDetail}
            />
          )}
          <Stat items={overall} />
          <div
            style={{
              height: 2,
              border: '1px dashed #C6C6C6',
            }}
          />
          <PropContextProvider
            value={{
              search,
              handleReset,
              handleSubmit,
              handleSearch,
              handleOption: handleOption01,
            }}
          >
            {search?.firstStartDate && (
              <SearchDateFormNew
                dateRange={dateRange}
                searchType={searchType}
                limitPeriod={true}
              />
            )}
          </PropContextProvider>

          {data && data?.totalCnt > 0 && data?.items?.length > 0 ? (
            <>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ flex: 4 }}>
                  {trendSeriesChanged && trendSeries?.series?.length > 0 && (
                    <PropContextProvider
                      value={{
                        data: sentStat,
                        reviewCount: reviewCount,
                        trendSeries: trendSeries,
                        changed: trendSeriesChanged,
                        width: (900 * getWindowDimensions().width) / 1750,
                        height: (getWindowDimensions().width / 1750) * 300,
                        name: '선택 상품',
                      }}
                    >
                      <TrendStat />
                    </PropContextProvider>
                  )}
                  {!trendSeriesChanged && trendSeries?.series?.length > 0 && (
                    <PropContextProvider
                      value={{
                        data: sentStat,
                        reviewCount: reviewCount,
                        trendSeries: trendSeries,
                        changed: trendSeriesChanged,
                        width: (900 * getWindowDimensions().width) / 1750,
                        height: (getWindowDimensions().width / 1750) * 300,
                        name: '선택 상품',
                      }}
                    >
                      <TrendStat />
                    </PropContextProvider>
                  )}
                </div>
                <div style={{ flex: 2 }}>
                  {sentStatChanged && sentStat && (
                    <PropContextProvider
                      value={{
                        data: sentStat,
                        changed: sentStatChanged,
                      }}
                    >
                      <SentStat />
                    </PropContextProvider>
                  )}
                  {!sentStatChanged && sentStat && (
                    <PropContextProvider
                      value={{
                        data: sentStat,
                        changed: sentStatChanged,
                      }}
                    >
                      <SentStat />
                    </PropContextProvider>
                  )}
                </div>
              </div>
              <Section style={{ boxShadow: '5px 5px 5px #eee' }}>
                <ChartButtonContainer>
                  <div>
                    {categories.map((item, idx) => {
                      return (
                        <div key={idx}>
                          {selectedCategories.includes(item) && (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                paddingLeft: 20,
                                fontSize: '12pt',
                                fontWeight: 700,
                                color: '#2B3674',
                              }}
                            >
                              <ChevronLeft
                                style={{ marginRight: 15 }}
                                onClick={() => selectedCategory(idx, 'prev')}
                              />
                              <div style={{ textAlign: 'center' }}>
                                <div>{item}</div>
                                <div
                                  style={{
                                    marginTop: 7,
                                    color: '#9A9A9A',
                                    fontSize: '10pt',
                                  }}
                                >
                                  {getCount(item)}
                                </div>
                              </div>
                              <ChevronRight
                                style={{ marginLeft: 15 }}
                                onClick={() => selectedCategory(idx, 'next')}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      marginRight: '20px',
                      gap: '10px',
                    }}
                  >
                    {selectedValue === 'tot_revw_cnt' ? (
                      <Button
                        type="button"
                        onClick={() => changedSelectedValue('tot_revw_cnt')}
                        style={{
                          borderRadius: '100px',
                          boxShadow: '3px 3px 10px rgba(37, 99, 235, 0.4)',
                          color: '#F9FAFB',
                          backgroundColor: '#2563EB',
                          fontWeight: 700,
                        }}
                      >
                        리뷰
                      </Button>
                    ) : (
                      <Button
                        outline
                        type="button"
                        onClick={() => changedSelectedValue('tot_revw_cnt')}
                        style={{
                          borderRadius: '100px',
                          border: '1.5px solid rgba(167,192,247,1)',
                          color: 'rgba(167,192,247,1)',
                          fontWeight: 700,
                          boxShadow: 'none',
                        }}
                      >
                        리뷰
                      </Button>
                    )}
                    {selectedValue === 'tot_pos_sent_cnt' ? (
                      <Button
                        type="button"
                        onClick={() => changedSelectedValue('tot_pos_sent_cnt')}
                        style={{
                          borderRadius: '100px',
                          boxShadow: '3px 3px 10px rgba(37, 99, 235, 0.4)',
                          color: '#F9FAFB',
                          backgroundColor: '#2563EB',
                          fontWeight: 700,
                        }}
                      >
                        긍정
                      </Button>
                    ) : (
                      <Button
                        outline
                        type="button"
                        onClick={() => changedSelectedValue('tot_pos_sent_cnt')}
                        style={{
                          borderRadius: '100px',
                          border: '1.5px solid rgba(167,192,247,1)',
                          color: 'rgba(167,192,247,1)',
                          fontWeight: 700,
                          boxShadow: 'none',
                        }}
                      >
                        긍정
                      </Button>
                    )}
                    {selectedValue === 'tot_neg_sent_cnt' ? (
                      <Button
                        type="button"
                        onClick={() => changedSelectedValue('tot_neg_sent_cnt')}
                        style={{
                          borderRadius: '100px',
                          boxShadow: '3px 3px 10px rgba(37, 99, 235, 0.4)',
                          color: '#F9FAFB',
                          backgroundColor: '#2563EB',
                          fontWeight: 700,
                        }}
                      >
                        부정
                      </Button>
                    ) : (
                      <Button
                        outline
                        type="button"
                        onClick={() => changedSelectedValue('tot_neg_sent_cnt')}
                        style={{
                          borderRadius: '100px',
                          border: '1.5px solid rgba(167,192,247,1)',
                          color: 'rgba(167,192,247,1)',
                          fontWeight: 700,
                          boxShadow: 'none',
                        }}
                      >
                        부정
                      </Button>
                    )}
                  </div>
                </ChartButtonContainer>
                {seriesChanged && (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOption}
                  />
                )}
                {!seriesChanged && (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOption}
                  />
                )}
                {/*changed && series.length > 0 && (
                  <MultiLineChart
                    x={(d) => d.date}
                    y={(d) => d.tot_revw_cnt}
                    z={(d) => d.sp_category1}
                    width={(1400 * getWindowDimensions().width) / 1750}
                    height={(getWindowDimensions().width / 1750) * 300}
                    contents={series}
                    value={selectedValue}
                    categories={categories}
                    selectedCategories={selectedCategories}
                    length={categories.length}
                  />
                )*/}
                {/*!changed && series.length > 0 && (
                  <MultiLineChart
                    x={(d) => d.date}
                    y={(d) => d.tot_revw_cnt}
                    z={(d) => d.sp_category1}
                    width={(1400 * getWindowDimensions().width) / 1750}
                    height={(getWindowDimensions().width / 1750) * 300}
                    contents={series}
                    value={selectedValue}
                    categories={categories}
                    selectedCategories={selectedCategories}
                    length={categories.length}
                  />
                )*/}
              </Section>
              <Section style={{ boxShadow: '5px 5px 5px #eee' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <SubTitle style={{ display: 'flex', flexDirection: 'row' }}>
                    주제항목별 현황
                    <img
                      onClick={() => handleDownload()}
                      src={downloadImage}
                      alt=""
                      style={{
                        width: 14,
                        height: 14,
                        marginLeft: 5,
                        cursor: 'pointer',
                      }}
                    />
                  </SubTitle>
                  <PropContextProvider
                    value={{
                      rules: sortRules,
                      fields: sortFields,
                      handleOption: handleOption01,
                      handleDownload,
                      activeCompare: true,
                      status: categoryOption.sp_category1,
                    }}
                  >
                    <Section style={{ padding: '0px' }}>
                      <SelectSortOption />
                    </Section>
                  </PropContextProvider>
                </div>
                <PropContextProvider
                  value={{
                    table,
                    data,
                    option: option01,
                    handleOption: handleOption01,
                  }}
                >
                  <DataList />
                </PropContextProvider>
              </Section>
            </>
          ) : (
            <EmptyData />
          )}
          <div
            style={{
              height: 2,
              border: '1px dashed #C6C6C6',
              margin: '20px 0px',
            }}
          />
          <Section
            style={{ position: 'relative', boxShadow: '5px 5px 5px #eee' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <SubTitle>주제항목별 리뷰 분류</SubTitle>
              <Sentiment onClick={() => setIsActive(!isActive)}>
                {sentiment?.name}
                <Arrow src={chevron} />
              </Sentiment>
              {isActive && (
                <SentimentWrapper>
                  {sentimentOption?.map((item, idx) => {
                    return (
                      <CategoryWrapper key={idx}>
                        <div />
                        <Category
                          onClick={() => {
                            setSentiment({ id: item.id, name: item.name });
                            setOption02({
                              ...option02,
                              sentiment: item.value,
                              page: 1,
                            });
                            setIsActive(!isActive);
                          }}
                        >
                          {item.img && <Line src={item.img} alt="" />}
                          {item.name}
                        </Category>
                      </CategoryWrapper>
                    );
                  })}
                </SentimentWrapper>
              )}
            </div>
            <TopicContainer ref={view}>
              {topicList &&
                topicList.map((item, index) => {
                  return (
                    <TopicCard
                      key={index}
                      style={{
                        borderBottom:
                          currentCategory === item.topic
                            ? '2.5px solid #4318FF'
                            : '0px solid #fff',
                        color:
                          currentCategory === item.topic
                            ? '#2B3674'
                            : '#8492A9',
                        fontWeight: currentCategory === item.topic ? 500 : 300,
                      }}
                      onClick={() => {
                        handleTopic(item.topic);
                      }}
                    >
                      {item.topic}
                    </TopicCard>
                  );
                })}
            </TopicContainer>
            {review?.total > 0 ? (
              <>
                <Wrapper>
                  {review?.items?.map((item, index) => {
                    return (
                      <div key={index}>
                        <ReviewWrapper
                          onClick={() => setCurrentContents(item.sentence)}
                        >
                          <ReviewContents
                            style={{
                              fontWeight:
                                currentContents === item.sentence ? 800 : 500,
                              textDecoration:
                                currentContents === item.sentence
                                  ? 'underline'
                                  : '',
                              color: '#2b3674',
                            }}
                          >
                            {item.sentence}
                          </ReviewContents>
                          <Pointer />
                        </ReviewWrapper>
                        {currentContents === item.sentence && (
                          <FullContents>
                            <Contents item={item} />
                          </FullContents>
                        )}
                      </div>
                    );
                  })}
                </Wrapper>
                <Pagination
                  total={review.total}
                  limit={option02?.size}
                  current={option02?.page}
                  onChange={(page) => handleReviewOption({ page })}
                />
              </>
            ) : (
              <Section>
                <EmptyData type="none" />
              </Section>
            )}
          </Section>
        </Container>
      ) : (
        <div
          style={{
            display: 'flex',
            height: 'calc(100% - 120px)',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EmptyData />
        </div>
      )}
      <CSVLink ref={downloadRef} filename="상품분석현황.csv" data={csvData} />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  padding-bottom: 20px;
  gap: 30px;
  margin-top: -90px;
  background-color: #f5f7fd;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  border-radius: 16px;
  background-color: white;
`;

const Sentiment = styled.div`
  display: flex;
  align-items: center;
  color: #a3aed0;
  cursor: pointer;
  font-size: 14px;
  margin-right: 20px;
`;

const Arrow = styled.img`
  width: 20px;
  height: 25px;
`;

const SentimentWrapper = styled.div`
  position: absolute;
  top: 50px;
  right: 40px;
  width: 150px;
  background-color: #fdfdfd;
  border-radius: 16px;
  box-shadow: 5px 5px 5px #eee;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const CategoryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:hover {
    cursor: pointer;
    background-color: #5d42ff14;
    color: #644ded;
    font-weight: 700;
    border-radius: 6px;
  }
`;

const Category = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 32px;
  padding-right: 10px;
  text-align: right;
`;

const Line = styled.img`
  width: 30px;
  height: 3px;
  margin-right: 5px;
`;

const ChartButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  padding-top: 20px;
  gap: 10px;
`;

const TopicContainer = styled.div`
  display: flex;
  row-gap: 17px;
  align-items: center;
  column-gap: 19px;
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const TopicCard = styled.div`
  min-width: 80px;
  padding: 10px 0;
  font-size: 12px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  padding-top: 10px;
`;

const ReviewWrapper = styled.div`
  width: 95%;
  margin: 10px 30px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ReviewContents = styled.span`
  line-height: 25px;
  white-space: pre-wrap;
  color: #5a5a5a;
  font-weight: 500;
`;

const FullContents = styled.div`
  background-color: #f6f6fc;
  margin: 5px 40px;
  padding: 20px;
  border-radius: 5px;
  color: #5a5a5a;
`;

export default ItemDetail;
