import React from 'react';
import styled from 'styled-components';
import { Title } from 'components/atoms';
import { Tabs } from 'components/blocks';
import IntegrationItem from 'pages/integration/item';
import IntegrationReview from 'pages/integration/review';
import IntegrationCategory from 'pages/integration/category';

const IntegrationAuto = () => {
  return (
    <Container>
      <Category01>연동 설정</Category01>
      <Category02>데이터 업로드</Category02>
      <Section>
        <Title style={{ marginBottom: '20px', padding: '30px 0 0 30px' }}>
          파일 업로드
        </Title>
        <Tabs
          tabs={[
            {
              title: <SubTitle>1. 상품 등록</SubTitle>,
              content: <IntegrationItem />,
            },
            {
              title: <SubTitle>2. 리뷰 등록</SubTitle>,
              content: <IntegrationReview />,
            },
            {
              title: <SubTitle>3. 카테고리 등록</SubTitle>,
              content: <IntegrationCategory />,
            },
          ]}
        />
      </Section>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: calc(100% - 120px);
  padding-top: 15px;
`;

const SubTitle = styled.div`
  font-size: 1rem;
  font-weight: 700;
`;

const Category01 = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #707eae;
  line-height: 20px;
  padding-left: 10px;
`;

const Category02 = styled.div`
  font-size: 30px;
  color: #2b3674;
  font-weight: 800;
  padding-left: 10px;
  margin-bottom: 40px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 5px 5px 5px #eee;
`;

export default IntegrationAuto;
