import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ObjectId: '',
  createdAt: '',
  token: '',
  updatedAt: '',
  message: '',
  status: '',
};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clear: (state) => {
      return {
        ...state,
        message: '',
        status: '',
        signupMessage: '',
      };
    },
    userSignInRequest: (state) => {
      state.message = '';
    },
    userSignInSuccess: {
      reducer: (_, { payload }) => ({ ...payload }),
      prepare: (payload) => {
        localStorage.setItem('token', payload.results.token);
        localStorage.setItem('name', payload.results.name);
        return { payload };
      },
    },
    userSignOutSuccess: {
      reducer: (_) => initialState,
      prepare: (payload) => {
        localStorage.removeItem('token');
        localStorage.removeItem('name');
        return { payload };
      },
    },
    userSiginInFail: (state, { payload }) => {
      state.message = payload;
    },
    userSignUpRequest: (state) => {
      state.status = '';
    },
    userSignUpSuccess: {
      reducer: (_, { payload }) => ({ signupMessage: 'success', ...payload }),
      prepare: (payload) => {
        localStorage.setItem('signup', true);
        return { payload };
      },
    },
    userSignUpFail: (state, { payload }) => {
      state.status = payload;
    },
  },
});

export const {
  userSignInRequest,
  userSignInSuccess,
  userSignOutSuccess,
  userSiginInFail,
  userSignUpRequest,
  userSignUpSuccess,
  userSignUpFail,
  clear,
} = slice.actions;

export default slice.reducer;
