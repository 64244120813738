import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import warning from 'assets/images/warning.png';
import { Button } from 'components/atoms';

const EmptyLink = () => {
  const navigate = useNavigate();

  const goToDataUpload = () => {
    navigate('/link/auto');
  };

  return (
    <Notification>
      <Warning src={warning} alt="" />
      <Contents>데이터 분석을 위해</Contents>
      <Contents>API 연동 또는 데이터를 업로드 해주세요.</Contents>
      <SubContent>연동 후 분석까지 최대 1일이 소요됩니다.</SubContent>
      <Button onClick={goToDataUpload}>연동 설정으로 이동</Button>
    </Notification>
  );
};

const Notification = styled.div`
  background-color: #fff;
  box-shadow: 8px 8px 40px 2px rgba(0, 0, 0, 0.25);
  padding: 65px 100px;
  margin: 100px auto auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  button {
    height: 40px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 12.8;
    margin-top: 50px;
    &:hover {
      transform: translateY(-1px);
    }
  }
`;

const Warning = styled.img`
  width: 38px;
  height: 34px;
  margin: 0 auto 30px;
`;

const Contents = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  margin: 0 auto;
`;

const SubContent = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #f23985;
  margin: 20px auto 0;
  text-decoration: underline;
`;

export default EmptyLink;
