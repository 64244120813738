export const menu = {
  login: {
    link: '/login',
  },
  password: {
    link: '/password',
  },
  signup: {
    link: '/signup',
  },
  changepassword: {
    link: '/changepassword/:token',
  },
  account: {
    name: '설정',
    auth: { name: '계정 정보', link: '/account' },
    current: { name: '현재 비밀번호', link: '/currentpassword' },
    new: { name: '새 비밀번호', link: 'newpassword' },
  },
  link: {
    name: '연동 설정',
    auto: { name: 'API 연동', link: '/link/auto' },
    manually: { name: '데이터 업로드', link: '/link/manually' },
  },
  analysis: {
    name: '데이터 분석 현황',
    overall: { name: '카테고리 분석 현황', link: '/analysis/overall' },
    category: { name: '주제항목별 분석 현황', link: '/analysis/category' },
    item: { name: '상품 분석 현황', link: '/analysis/item' },
    photoreview: { name: '포토 리뷰 분석 현황', link: '/analysis/photoreview' },
    link: { name: '연동 현황', link: '/analysis/link' },
  },
  invoice: {
    name: '결제',
    invoice: { name: 'Invoice 조회', link: '/invoice' },
  },
  help: {
    name: '도움말',
    faq: {
      name: '빌리뷰스튜디오 FAQ',
      link: 'https://bereview.notion.site/FAQ-b2a4c213366541929b8deec0b2909bd7',
      external: true,
    },
  },
};

export const menuList = [
  {
    name: menu.analysis.name,
    child: [
      {
        name: menu.analysis.overall.name,
        link: menu.analysis.overall.link,
      },
      {
        name: menu.analysis.category.name,
        link: menu.analysis.category.link,
      },
      {
        name: menu.analysis.item.name,
        link: menu.analysis.item.link,
      },
      {
        name: menu.analysis.photoreview.name,
        link: menu.analysis.photoreview.link,
      },
    ],
  },
  {
    name: menu.link.name,
    child: [
      { name: menu.link.auto.name, link: menu.link.auto.link },
      { name: menu.link.manually.name, link: menu.link.manually.link },
      // {
      //   name: menu.analysis.link.name,
      //   link: menu.analysis.link.link,
      // },
    ],
  },
  {
    name: menu.invoice.name,
    child: [
      {
        name: menu.invoice.invoice.name,
        link: menu.invoice.invoice.link,
      },
    ],
  },
  // {
  //   name: menu.account.name,
  //   child: [
  //     {
  //       name: menu.account.auth.name,
  //       link: menu.account.auth.link,
  //     },
  //   ],
  // },
  // {
  //   name: menu.help.name,
  //   child: [
  //     {
  //       name: menu.help.faq.name,
  //       link: menu.help.faq.link,
  //       external: menu.help.faq.external,
  //     },
  //   ],
  // },
];
