import React from 'react';
import styled, { css } from 'styled-components';

const Alert = (props) => {
  return <Container {...props} />;
};

const Container = styled.div`
  position: relative;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;

  ${({ variant }) => {
    if (variant === 'danger') {
      return css`
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
      `;
    }
    if (variant === 'success') {
      return css`
        color: #1c726a;
        background-color: #d7f8f5;
        border-color: #c6f5f0;
      `;
    }
    if (variant === 'warning') {
      return css`
        color: #45349c;
        background-color: #dce5ff;
        border-color: #dce5ff;
      `;
    }
  }}
`;

export default Alert;
