import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

function Tabs({ defaultTab, tabs, onChange }) {
  const [currentIdx, setCurrentIdx] = useState();

  const clickTab = (idx) => {
    setCurrentIdx(idx);
    if (onChange) {
      onChange(idx);
    }
  };

  useEffect(() => {
    setCurrentIdx(defaultTab);
  }, [defaultTab]);

  return (
    <Container>
      <Header>
        {tabs.map((tab, idx) => (
          <Tab
            key={idx}
            active={currentIdx === idx}
            onClick={() => clickTab(idx)}
            disabled={tab.disabled}
          >
            {tab.title}
          </Tab>
        ))}
      </Header>
      <Body>{tabs[currentIdx]?.content}</Body>
    </Container>
  );
}

const Container = styled.div``;

const Header = styled.div`
  display: flex;
`;

const Tab = styled.button`
  padding: 1rem 2rem;
  outline: none;
  border: none;
  cursor: pointer;
  color: #d6d6d6;
  background-color: white;
  border-bottom: 1px solid #e9edf7;
  ${({ active }) =>
    active &&
    css`
      color: #2b3674;
      border-bottom: 3px solid #4318ff;
    `};

  &:disabled {
    cursor: not-allowed;
  }
`;

const Body = styled.div`
  background-color: white;
  border-radius: 16px;
  // padding: 1rem;
`;

Tabs.propTypes = {
  defaultTab: PropTypes.number,
  tabs: PropTypes.instanceOf(Array),
  children: PropTypes.node,
  onChange: PropTypes.func,
};

Tabs.defaultProps = {
  defaultTab: 0,
  tabs: [],
  children: null,
  onChange: null,
};

export default Tabs;
