import { isProductionMode } from 'utils/utils';

const getBaseUrl = () => {
  if (isProductionMode()) {
    return 'https://studio.bereview.co.kr';
  } else {
    return 'https://studio-dev.bereview.co.kr';
  }
};

const base = getBaseUrl();

const apis = {
  url: base,
  account: {
    signup: `${base}/api/admin/account/signup`,
    signin: `${base}/api/admin/account/signin`,
    user: `${base}/api/admin/account/user`,
    password: {
      check: `${base}/api/admin/account/check/password`,
      update: `${base}/api/admin/account/update/password`,
      generate: `${base}/api/admin/account/generate/password`,
    },
    delete: `${base}/api/admin/account/delete`,
  },
  secret: `${base}/api/admin/secret`,
  company: {
    register: `${base}/api/admin/company/register`,
    list: `${base}/api/admin/company/list`,
  },
  data: {
    overall: {
      brief: `${base}/api/data/stat/overall`,
      category: {
        brief: `${base}/api/data/stat/overall/cate`,
        series: `${base}/api/data/stat/overall/cate/series`,
        compare: {
          brief: `${base}/api/data/stat/overall/cate/compare`,
          series: `${base}/api/data/stat/overall/cate/compare/series`,
        },
      },
      download: `${base}/api/data/stat/overall/cate/download`,
      daily: `${base}/api/data/stat/overall/daily`,
      sent: `${base}/api/data/stat/overall/sent`,
    },
    cate: {
      brief: `${base}/api/data/stat/cate`,
      topic: {
        brief: `${base}/api/data/stat/cate/topic`,
        series: `${base}/api/data/stat/cate/topic/series`,
        compare: {
          brief: `${base}/api/data/stat/cate/topic/compare`,
          series: `${base}/api/data/stat/cate/topic/compare/series`,
        },
      },
      shot: {
        brief: `${base}/api/data/stat/cate/shot`,
      },
      // daily: {
      //   brief: `${base}/api/data/stat/cate/daily`,
      //   compare: `${base}/api/data/stat/cate/topic/daily`,
      // },
      list: `${base}/api/data/stat/cate/list`,
      download: `${base}/api/data/stat/cate/topic/download`,
      daily: `${base}/api/data/stat/cate/daily`,
      sent: `${base}/api/data/stat/cate/sent`,
    },
    item: {
      brief: `${base}/api/data/stat/item`,
      search: `${base}/api/data/stat/item/search`,
      topic: {
        brief: `${base}/api/data/stat/item/topic`,
        series: `${base}/api/data/stat/item/topic/series`,
        compare: {
          brief: `${base}/api/data/stat/item/topic/compare`,
          series: `${base}/api/data/stat/item/topic/compare/series`,
        },
        list: `${base}/api/data/stat/item/topic/list`,
      },
      rank: `${base}/api/data/stat/item/rank`,
      download: `${base}/api/data/stat/item/topic/download`,
      daily: `${base}/api/data/stat/item/daily`,
      sent: `${base}/api/data/stat/item/sent`,
    },
    review: {
      list: `${base}/api/data/navigate/review/list`,
    },
    navigate: {
      list: `${base}/api/data/navigate/overall`,
      cate: `${base}/api/data/navigate/overall/cate`,
    },
  },
  integration: {
    upload: `${base}/api/data/upload/file`,
    download: `${base}/api/data/download/file`,
    template: `${base}/api/data/template/download`,
    list: `${base}/api/data/upload/file/list`,
  },
  invoice: {
    list: `${base}/api/admin/invoice/list`,
    download: `${base}/api/admin/invoice/download`,
  },
};

export { apis };
