import React from 'react';
import styled from 'styled-components';

const ModalBody = (prop) => {
  const { children, className } = prop;

  return <Body className={className}>{children}</Body>;
};

const Body = styled.div`
  padding: 1rem;
`;

export default ModalBody;
