import React, { useRef } from 'react';
import styled from 'styled-components';
import { Title } from 'components/atoms';
import background from 'assets/images/background.png';
import upload from 'assets/images/upload_img.png';
import book from 'assets/images/book.png';
import arrow from 'assets/images/arrow_right_blue.png';

const FileUpload = ({ type, handleUpload }) => {
  const title =
    type === 'item' ? '상품' : type === 'category' ? '카테고리' : '리뷰';
  const fileRef = useRef();

  const handleUploadButtonClick = () => {
    fileRef.current.click();
  };

  const handleGuideDownload = () => {
    window.open('https://docs.bereview.co.kr/guides/data.spec');
  };

  const handleUploadFile = (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      const formData = new FormData();
      formData.append('type', type);
      formData.append('file', files[0]);
      handleUpload(formData);
    }
    fileRef.current.value = '';
  };

  return (
    <UploadWrapper>
      <UploadButton onClick={handleUploadButtonClick}>
        <div
          style={{
            position: 'relative',
            width: '200px',
            height: '260px',
          }}
        >
          <img
            src={background}
            alt=""
            style={{
              width: '200px',
              position: 'absolute',
              top: 0,
              left: 0,
              height: '260px',
            }}
          />
          <img
            src={upload}
            alt=""
            style={{ width: '200px', position: 'absolute', top: 50 }}
          />
        </div>
        <input
          type="file"
          accept=".csv,.json"
          onChange={handleUploadFile}
          ref={fileRef}
          style={{ display: 'none' }}
        />
      </UploadButton>
      <div>
        <Title>안내사항</Title>
        <Contents>
          <Row>
            ▪︎ 1-3단계까지 모두 파일 업로드를 진행해주셔야 연동이 완료됩니다. (‘
            {title}등록' 파일만 업로드하신 경우 연동이 불가합니다.)
          </Row>
          <Row>
            ▪︎ 데이터 업로드 가이드에서 파일 작성 예시를 참고하여 파일을
            작성해주세요.
          </Row>
          <Row>
            ▪︎ 연동 완료 후 분석까지 최대 1일이 소요되며, 분석 완료 후 '데이터
            분석 현황'을 이용하실 수 있습니다.
          </Row>
        </Contents>
        <GuideButton
          onClick={() => {
            window.open('https://docs.bereview.co.kr/guides/data.spec');
          }}
        >
          <BookImg src={book} alt="" />
          데이터 업로드 가이드
          <ArrowImg src={arrow} alt="" />
        </GuideButton>
      </div>
    </UploadWrapper>
  );
};

const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UploadButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
  cursor: pointer;
`;

const Contents = styled.div`
  flex-direction: column;
  padding: 20px 0px 10px;
`;

const Row = styled.div`
  font-size: 13px;
  line-height: 30px;
  color: #616161;
`;

const GuideButton = styled.div`
  display: flex;
  align-items: center;
  width: 180px;
  justify-content: center;
  border-radius: 4px;
  background-color: #f4f7fe;
  color: #4318ff;
  cursor: pointer;
  padding: 10px;
  font-size: 13px;
  margin-top: 20px;
`;

const BookImg = styled.img`
  width: 16px;
  height: 13px;
  margin-right: 10px;
`;

const ArrowImg = styled.img`
  width: 14px;
  height: 14px;
  margin-left: 5px;
`;

export default FileUpload;
