import React from 'react';
import styled from 'styled-components';

const Footer = () => {
  return (
    <Container>
      <div>
        (주)빌리뷰 | 서울특별시 강남구 강남대로94길 28, 701호 | 대표: 한송원
      </div>
      <div>
        문의:{' '}
        <a className="email" href="mailto:support@bereview.co.kr">
          support@bereview.co.kr
        </a>
        <span> | </span>사업자등록번호: 312-88-01972
      </div>
      <Terms>
        <a
          className="terms"
          href="https://docs.google.com/document/u/1/d/e/2PACX-1vTEJ2QZ_EZW-xOmJgu2tS_VfglFzWYqoseV99-Qg7_Khh55UNE-rm0LC1s507LDZuYWLNSgSyJwUbm2/pub"
          target="_blank"
          rel="noreferrer"
        >
          서비스 이용약관
        </a>
        <a
          className="terms"
          href="https://docs.google.com/document/u/1/d/e/2PACX-1vRnuGlPVAzGxKpBRXFaP719As8h7ovSVf7cHf9Kx85wXgicP7D8khen5ygqZS6zBBjwunpKV5KzvQIE/pub"
          target="_blank"
          rel="noreferrer"
        >
          개인정보 처리방침
        </a>
      </Terms>
    </Container>
  );
};

const Container = styled.footer`
  padding: 30px 0;
  color: #333;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
  line-height: 200%;
  letter-spacing: -0.03rem;

  .terms {
    color: #5e646d;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: -0.03rem;
    &:hover {
      text-decoration: underline;
    }
  }

  .email {
    color: black;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: -0.03rem;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Terms = styled.div`
  justify-content: space-between;
  display: flex;
  width: 200px;
  margin: 0 auto;
  text-align: center;
`;

export default Footer;
