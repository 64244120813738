import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

const TextArea = forwardRef((props, ref) => {
  return <Container {...props} ref={ref} />;
});

const Container = styled.textarea`
  width: 100%;
  padding: 0.375rem 0.75rem;
  overflow: auto;
  border: none;
  border-radius: 0.3rem;
  box-shadow: inset 0 0 0 1px #ced4da;
  resize: none;
  min-height: ${({ height }) => height};

  &:focus {
    outline: 0;
    border: 1px solid rgba(0, 123, 255, 0.4);
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
  }

  ${({ variant }) =>
    variant === 'danger' &&
    css`
      border: 1px solid red;
      &:focus {
        outline: 0;
        border: 1px solid rgba(255, 0, 0, 0.4);
        box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.3);
      }
    `}
`;

export default TextArea;
