import React from 'react';
import styled from 'styled-components';

const Tr = (props) => {
  return <Container {...props} />;
};

const Container = styled.tr`
  box-shadow: 0 0 0 0px #dddddd;
  background-color: #fff;
  color: #343a40;
  font-weight: 700;
  text-align: center;
`;

export default Tr;
