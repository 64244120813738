export const CHANNEL_PLUGIN_KEY = 'f24dcb71-7bc5-4a59-b833-910790cb5a18';

export class ChannelService {
  constructor() {
    this.loadScript();
  }

  loadScript() {
    let w = window;
    if (w.ChannelIO) {
      // NOTE 확인해도, 별다른 조치를 하지 않는다. 때문에 메시지도 노출시킬 필요가 없다.
      // return (window.console.error || window.console.log || function () {})(
      //   'ChannelIO script included twice.',
      // );
      return null;
    }
    let ch = function () {
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function (args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      let s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      let x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
    if (document.readyState === 'complete') {
      l();
    } else if (window.attachEvent) {
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  }

  boot(settings) {
    window.ChannelIO('boot', settings);
  }

  shutdown() {
    window.ChannelIO('shutdown');
  }
}
