import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

const Input = forwardRef(
  ({ width, disabled, variant, right, rightOption, left, ...rest }, ref) => {
    return (
      <Container width={width}>
        <Content variant={variant} disabled={disabled}>
          {left && <span className="input-left">{left}</span>}
          <input {...rest} ref={ref} disabled={disabled} />
          {right && (
            <span
              className={
                rightOption?.onClick ? 'input-right click' : 'input-right'
              }
              {...rightOption}
            >
              {right}
            </span>
          )}
        </Content>
      </Container>
    );
  },
);

const Container = styled.div`
  display: inline-block;
  width: ${({ width }) => (width ? `${width}` : '100%')};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  min-height: calc(1.7rem + 0.5rem);
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border-radius: 0.3rem;

  &:focus-within {
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.3);
  }

  ${({ variant }) =>
    variant === 'danger' &&
    css`
      border: 1px solid red;
      &:focus-within {
        box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.3);
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #e9ecef;
    `}

  input {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0.3rem 0.5rem;
    color: #495057;
    background-color: inherit;
    background-clip: content-box;
    border-radius: 6px;
    border: 1.5px solid #e4e4e4;
    outline: 0;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }
  }

  span {
    padding: 0.5rem 0.8rem;
    background-color: #343a40;
    color: white;
    white-space: pre;
    border-radius: 0.25rem;
    border-radius: 0.25rem 0 0 0.25rem;
    border-radius: 0rem 0.25rem 0.25rem 0rem;
  }

  .input-left {
    border-radius: 0.25rem 0px 0px 0.25rem;
  }
  .input-right {
    border-radius: 0rem 0.25rem 0.25rem 0rem;
  }
  .click {
    cursor: pointer;
  }
`;

export default Input;
