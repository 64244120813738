import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, SubTitle } from 'components/atoms';
import { usePropContext } from 'context/PropContext';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

let chartOption01 = {
  chart: {
    type: 'area',
    height: '200',
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  /*legend: {
    enabled: false,
  },*/
  tooltip: {
    pointFormat: '<b>{point.y:,.0f}</b>',
  },
  xAxis: {
    visible: false,
    categories: [],
  },
  yAxis: {
    visible: false,
    title: {
      text: '',
    },
  },
  colors: ['#90E4CE', '#5A55D2'],
  tooltip: {
    pointFormat: '<b>{point.y:,.0f}</b>',
  },
  plotOptions: {
    area: {
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 2,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, '#90E4CE'],
          [1, '#ffffff'],
        ],
      },
      lineWidth: 3,
    },
  },
  series: [],
};

let chartOption02 = {
  chart: {
    type: 'column',
    height: '200',
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    visible: false,
    categories: [],
  },
  yAxis: [
    {
      visible: false,
      title: {
        text: '',
      },
    },
    {
      visible: false,
      title: {
        text: '',
      },
      opposite: true,
    },
  ],
  colors: ['#F86994', '#106EFC'],
  tooltip: {
    pointFormat:
      '<span style="color:{series.color}">●</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
    formatter: function () {
      let points = this.points;
      let result = `<span style="font-size:10px">${points[0].key}</span><br/>`;
      points
        .filter((i) => {
          return i.series.name != '긍정건수' && i.series.name != '부정건수';
        })
        .forEach((p) => {
          result += `<span style="color:${p.series.color}">●</span>:
          <b>${p.y}</b> (${p.percentage.toFixed(2)}%)<br/>`;
        });
      return result;
    },
    shared: true,
  },
  plotOptions: {
    column: {
      stacking: 'percent',
    },
  },
  series: [],
};

const TrendStat = () => {
  const { data, reviewCount, trendSeries, changed, width, height, name } =
    usePropContext();
  const [option01, setOption01] = useState();
  const [option02, setOption02] = useState();
  const [selectedValue, setSelectedValue] = useState('tot_revw_cnt');

  useEffect(() => {
    chartOption01.xAxis.categories = trendSeries.categories;
    chartOption01.series = [];
    chartOption01.series.push({
      name: name,
      data: trendSeries.series.map((item) => {
        return item.tot_revw_cnt;
      }),
    });
    setOption01(chartOption01);

    chartOption02.xAxis.categories = trendSeries.categories;
    chartOption02.series = [];
    chartOption02.series.push({
      name: '부정',
      opacity: '0.75',
      data: trendSeries.series.map((item) => {
        return item.tot_neg_sent_cnt;
      }),
    });
    chartOption02.series.push({
      name: '긍정',
      opacity: '0.75',
      data: trendSeries.series.map((item) => {
        return item.tot_pos_sent_cnt;
      }),
    });
    chartOption02.series.push({
      name: '부정건수',
      type: 'spline',
      yAxis: 1,
      data: trendSeries.series.map((item) => {
        return item.tot_neg_sent_cnt;
      }),
    });
    chartOption02.series.push({
      name: '긍정건수',
      type: 'spline',
      yAxis: 1,
      data: trendSeries.series.map((item) => {
        return item.tot_pos_sent_cnt;
      }),
    });
    setOption02(chartOption02);
  }, []);

  return (
    <div
      style={{
        height: 270,
        marginBottom: '5px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '20px 20px 0px 20px',
        borderRadius: '16px',
        backgroundColor: 'white',
        boxShadow: '5px 5px 5px #eee',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <SubTitle>
          {selectedValue === 'tot_revw_cnt' ? (
            <>
              리뷰 등록 추이
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  fontSize: '10pt',
                  fontWeight: 500,
                  color: '#9A9A9A',
                  marginTop: 5,
                }}
              >
                {reviewCount?.toLocaleString()}
              </div>
            </>
          ) : (
            <div>긍/부정 추이</div>
          )}
        </SubTitle>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '10px',
          }}
        >
          {selectedValue === 'tot_revw_cnt' ? (
            <Button
              type="button"
              onClick={() => setSelectedValue('tot_revw_cnt')}
              style={{
                borderRadius: '100px',
                boxShadow: '3px 3px 10px rgba(37, 99, 235, 0.4)',
                color: '#F9FAFB',
                backgroundColor: '#2563EB',
                fontWeight: 700,
              }}
            >
              리뷰 등록 추이
            </Button>
          ) : (
            <Button
              outline
              type="button"
              onClick={() => setSelectedValue('tot_revw_cnt')}
              style={{
                borderRadius: '100px',
                border: '1.5px solid rgba(167,192,247,1)',
                color: 'rgba(167,192,247,1)',
                fontWeight: 700,
                boxShadow: 'none',
              }}
            >
              리뷰 등록 추이
            </Button>
          )}
          {selectedValue === 'tot_sent_cnt' ? (
            <Button
              type="button"
              onClick={() => setSelectedValue('tot_sent_cnt')}
              style={{
                borderRadius: '100px',
                boxShadow: '3px 3px 10px rgba(37, 99, 235, 0.4)',
                color: '#F9FAFB',
                backgroundColor: '#2563EB',
                fontWeight: 700,
              }}
            >
              긍/부정 추이
            </Button>
          ) : (
            <Button
              outline
              type="button"
              onClick={() => setSelectedValue('tot_sent_cnt')}
              style={{
                borderRadius: '100px',
                border: '1.5px solid rgba(167,192,247,1)',
                color: 'rgba(167,192,247,1)',
                fontWeight: 700,
                boxShadow: 'none',
              }}
            >
              긍/부정 추이
            </Button>
          )}
        </div>
      </div>
      {changed && selectedValue === 'tot_revw_cnt' && (
        <HighchartsReact highcharts={Highcharts} options={option01} />
      )}
      {!changed && selectedValue === 'tot_revw_cnt' && (
        <HighchartsReact highcharts={Highcharts} options={option01} />
      )}
      {changed && selectedValue === 'tot_sent_cnt' && (
        <HighchartsReact highcharts={Highcharts} options={option02} />
      )}
      {!changed && selectedValue === 'tot_sent_cnt' && (
        <HighchartsReact highcharts={Highcharts} options={option02} />
      )}
    </div>
  );
};

export default TrendStat;
