import React from 'react';
import styled from 'styled-components';

export default function Label(props) {
  return <Container {...props} />;
}

const Container = styled.label`
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
`;
