import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label } from 'components/atoms';

const LabelForm = ({ className, label, children }) => {
  return (
    <Container className={className}>
      {label && <Label>{label}</Label>}
      <Content className="label-form-content">{children}</Content>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 150px 500px;
  align-items: baseline;
`;

const Content = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`;

LabelForm.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LabelForm.defaultProps = {
  label: null,
};

export default LabelForm;
