import React from 'react';
import styled from 'styled-components';

export default function Title(props) {
  return <Container {...props} />;
}

const Container = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  color: #2b3674;
`;
