import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'assets/styles/GlobalStyle';
import theme from 'assets/styles/theme';
import mixin from 'assets/styles/mixin';

const Config = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={{ ...theme, ...mixin }}>{children}</ThemeProvider>
    </>
  );
};

Config.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Config;
