import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PrivateLayout from 'hoc/PrivateLayout';
import { menu } from 'utils/constants';
import Account from 'pages/account/Account';
import CurrentPassword from 'pages/account/currentpassword';
import Overall from 'pages/analysis/overall';
import Category from 'pages/analysis/category';
import PhotoReview from 'pages/analysis/photoreview';
import NewPassword from 'pages/account/newpassword';
import Link from 'pages/analysis/link';
import { ItemMain, ItemDetail } from 'pages/analysis/item';
import IntegrationAuto from 'pages/integration/auto';
import IntegrationManually from 'pages/integration/manually';
import Invoice from 'pages/invoice/invoice/Invoice';

const Private = () => {
  return (
    <Routes>
      <Route element={<PrivateLayout />}>
        <Route path={menu.account.auth.link} element={<Account />} />
        <Route path={menu.account.current.link} element={<CurrentPassword />} />
        <Route path={menu.account.new.link} element={<NewPassword />} />
        <Route path={menu.analysis.overall.link} element={<Overall />} />
        <Route path={menu.analysis.category.link}>
          <Route path="" element={<Category />} />
          <Route path=":sp_category1/:sp_category2" element={<Category />} />
        </Route>
        <Route path={menu.analysis.item.link}>
          <Route path="" element={<ItemMain />} />
          <Route path=":itemCode" element={<ItemDetail />} />
        </Route>
        <Route
          path={menu.analysis.photoreview.link}
          element={<PhotoReview />}
        />
        <Route path={menu.analysis.link.link} element={<Link />} />
        <Route path={menu.link.auto.link} element={<IntegrationAuto />} />
        <Route
          path={menu.link.manually.link}
          element={<IntegrationManually />}
        />
        <Route path={menu.invoice.invoice.link} element={<Invoice />} />
        <Route
          path="*"
          element={<Navigate to={menu.analysis.overall.link} />}
        />
      </Route>
    </Routes>
  );
};

export default React.memo(Private);
