import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Title } from 'components/atoms';
import { PropContextProvider } from 'context/PropContext';
import SearchMonthForm from 'components/templates/layout/SearchMonthForm';
import InvoiceItem from 'pages/invoice/item/InvoiceItem';
import { getInvoiceList } from 'utils/api';

const Invoice = () => {
  const [yearMonth, setYearMonth] = useState(null);
  const [selectedYearMonth, setSelectedYearMonth] = useState('');
  const [monthOption, setMonthOption] = useState({
    changed: true,
    year_month: '',
  });

  useEffect(() => {
    getContents();
  }, []);

  const getContents = async () => {
    const res = await getInvoiceList({});
    setYearMonth(res.results.items.map((el) => el.dt));
  };

  const data = [];
  yearMonth?.forEach((element) => {
    if (!data.includes(element)) {
      data.push(element);
    }
  });

  const dateList = [];
  for (let i = data.length - 1; i >= 0; i--) {
    dateList.push(data[i]);
  }

  const handleMonthOption = (change) => {
    setSelectedYearMonth(change);
  };

  return (
    <Container>
      <Category01>결제</Category01>
      <Category02>Invoice 조회</Category02>
      <Section>
        <Title>기간 설정</Title>
        <PropContextProvider
          value={{
            date: dateList,
            selectedYearMonth,
            handleOption: handleMonthOption,
          }}
        >
          <SearchMonthForm selectedValue={monthOption.year_month} />
        </PropContextProvider>
      </Section>
      <div
        style={{
          height: 2,
          border: '1px dashed #C6C6C6',
        }}
      />
      <Section>
        <InvoiceItem value={selectedYearMonth} />
      </Section>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: calc(100% - 120px);
  padding-top: 15px;
`;

const Category01 = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #707eae;
  line-height: 20px;
  padding-left: 10px;
`;

const Category02 = styled.div`
  font-size: 30px;
  color: #2b3674;
  font-weight: 800;
  padding-left: 10px;
  margin-bottom: 40px;
  margin-top: -20px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 5px 5px 5px #eee;
`;

export default Invoice;
