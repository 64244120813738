import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Title, SubTitle, Button, Label } from 'components/atoms';
import Stat from 'components/templates/layout/Stat';
import { PropContextProvider } from 'context/PropContext';
import SelectSortOption from 'components/templates/layout/SelectSortOption';
import { useUser } from 'app/user';
import DataList from 'components/templates/layout/DataList';
import EmptyLink from 'components/templates/layout/EmptyLink';
import EmptyData from 'components/templates/layout/EmptyData';
import useTable from 'hooks/useTable';
import {
  getDataStatOverall,
  getDataStatOverallCate,
  getDataStatCateShot,
} from 'utils/api';

const shotColumns = [
  { label: 'Shot', col: 'shot' },
  { label: '포토 수', col: 'tot_photo_cnt' },
  { label: '포토 점유율', col: 'tot_photo_ratio' },
  { label: '포토리뷰 수', col: 'tot_photorevw_cnt' },
  { label: '포토리뷰 점유율', col: 'tot_photorevw_ratio' },
];

const categoryColumns = [
  { label: '카테고리', col: 'sp_category1' },
  { label: '포토 수', col: 'tot_photo_cnt' },
  { label: '포토 점유율', col: 'tot_photo_ratio' },
  { label: '포토리뷰 수', col: 'tot_photorevw_cnt' },
  { label: '포토리뷰 점유율', col: 'tot_photorevw_ratio' },
  { label: '전체 리뷰 수', col: 'tot_revw_cnt' },
  { label: '포토리뷰 작성률', col: 'tot_photorevw_conversion' },
];

const sortRules = [
  { id: 'DESC', value: 'DESC', name: '내림차순' },
  { id: 'ASC', value: 'ASC', name: '오름차순' },
];

const sortFields = [
  { id: 'TOT_PHOTO_CNT', value: 'TOT_PHOTO_CNT', name: '포토 수' },
  { id: 'TOT_PHOTO_SHARE', value: 'TOT_PHOTO_SHARE', name: '포토 점유율' },
  { id: 'TOT_PHOTOREVW_CNT', value: 'TOT_PHOTOREVW_CNT', name: '포토리뷰 수' },
  {
    id: 'TOT_PHOTOREVW_SHARE',
    value: 'TOT_PHOTOREVW_SHARE',
    name: '포토리뷰 점유율',
  },
  {
    id: 'TOT_PHOTOREVW_RATIO',
    value: 'TOT_PHOTOREVW_RATIO',
    name: '포토리뷰 작성률',
  },
  { id: 'TOT_REVW_CNT', value: 'TOT_REVW_CNT', name: '전체 리뷰 수' },
];

const PhotoReview = () => {
  const { user } = useUser();
  const [empty, setEmpty] = useState(false);
  const [option, setOption] = useState({
    sort_field: 'TOT_PHOTOREVW_SHARE',
    sort_rule: 'DESC',
    page: 1,
    size: 100,
  });
  const [categoryOption, setCategoryOption] = useState({
    changed: true,
    sp_category1: '',
  });
  const [shotData, setShotData] = useState({
    totalCnt: 0,
    items: [],
  });
  const [categoryData, setCategoryData] = useState({ totalCnt: 0, items: [] });
  const shotTable = useTable({
    columns: shotColumns,
    initData: shotData.items,
  });
  const categoryTable = useTable({
    columns: categoryColumns,
    initData: categoryData.items,
  });
  const [shotHoverIdx, setShotHoverIdx] = useState(-1);
  const [categoryHoverIdx, setCategoryHoverIdx] = useState(-1);
  const [photoReviewOverall, setPhotoReviewOverall] = useState(null);
  const view = useRef();

  useEffect(() => {
    getPhotoReviewOverall();
  }, []);

  useEffect(() => {
    getPhotoReviewSummaryByCategory();
    getPhotoReviewSummaryByShot();
  }, [option]);

  const handleOption = (changeData) => {
    const page = changeData.page ? changeData : 1;
    setOption({ ...option, page, ...changeData });
  };

  shotTable.convert.setHead = (item) => {
    const { label } = item;
    let title = label;
    return <div style={{ alignItems: 'center' }}>{title}</div>;
  };

  shotTable.convert.setHeadStyle = () => {
    return {
      fontSize: '10pt',
      fontWeight: 500,
      color: '#A3AED0',
      backgroundColor: '#fff',
      border: '0px solid #fff',
    };
  };

  shotTable.convert.setCol = (item) => {
    const { rowIdx, colIdx } = item;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
        onMouseOver={() => {
          setShotHoverIdx(rowIdx);
        }}
      >
        {item.value.toLocaleString() || '-'}
      </div>
    );
  };

  shotTable.convert.setColStyle = (item) => {
    const { colIdx, rowIdx } = item;
    if ([0].includes(rowIdx)) {
      return {
        backgroundColor: '#ebf1fc',
        padding: '20px 0.75rem',
        color: '#2B3674',
        fontWeight: 600,
        textAlign: 'center',
        borderRight: [0, 2].includes(colIdx) ? '1px dashed #A3AED0' : '0px',
      };
    } else {
      return {
        padding: '0.7rem 0.75rem',
        cursor: 'pointer',
        backgroundColor: shotHoverIdx === rowIdx ? '#F6F6FC' : '#FFF',
        color: '#2B3674',
        fontWeight: 600,
        textAlign: 'center',
        borderRight: [0, 2].includes(colIdx) ? '1px dashed #A3AED0' : '0px',
      };
    }
  };

  categoryTable.convert.setHead = (item) => {
    const { colIdx, key, label } = item;
    let title = label;
    if ([0].includes(colIdx)) {
      if (categoryOption.sp_category1 !== '') {
        title = '카테고리II';
      } else {
        title = '카테고리I';
      }
    }
    return <div style={{ alignItems: 'center' }}>{title}</div>;
  };

  categoryTable.convert.setHeadStyle = () => {
    return {
      fontSize: '10pt',
      fontWeight: 500,
      color: '#A3AED0',
      backgroundColor: '#fff',
      border: '0px solid #fff',
    };
  };

  categoryTable.convert.setCol = (item) => {
    const { rowIdx, colIdx } = item;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
        onMouseOver={() => {
          setCategoryHoverIdx(rowIdx);
        }}
      >
        {item.value.toLocaleString() || '-'}
      </div>
    );
  };

  categoryTable.convert.setColStyle = (item) => {
    const { colIdx, rowIdx } = item;
    if ([0].includes(rowIdx)) {
      return {
        backgroundColor: '#ebf1fc',
        padding: '20px 0.75rem',
        color: '#2B3674',
        fontWeight: 600,
        textAlign: 'center',
        borderRight: [0, 2, 4].includes(colIdx) ? '1px dashed #A3AED0' : '0px',
      };
    } else {
      return {
        padding: '0.7rem 0.75rem',
        cursor: 'pointer',
        backgroundColor: categoryHoverIdx === rowIdx ? '#F6F6FC' : '#FFF',
        color: '#2B3674',
        fontWeight: 600,
        textAlign: 'center',
        borderRight: [0, 2, 4].includes(colIdx) ? '1px dashed #A3AED0' : '0px',
      };
    }
  };

  const getPhotoReviewOverall = async () => {
    const res = await getDataStatOverall();

    if (res.statusCode === 200) {
      setPhotoReviewOverall([
        {
          title: '포토 수',
          type: 'review',
          count: res.results.tot_photo_cnt,
          cnt_7: res.results.tot_photo_cnt_7,
          cnt_30: res.results.tot_photo_cnt_30,
          ratio_7: res.results.tot_photo_compare_ratio_7,
          ratio_30: res.results.tot_photo_compare_ratio_30,
        },
        {
          title: '포토리뷰 수',
          type: 'review',
          count: res.results.tot_photorevw_cnt,
          cnt_7: res.results.tot_photorevw_cnt_7,
          cnt_30: res.results.tot_photorevw_cnt_30,
          ratio_7: res.results.tot_photorevw_compare_ratio_7,
          ratio_30: res.results.tot_photorevw_compare_ratio_30,
        },
      ]);
      setEmpty(false);
    } else {
      setEmpty(true);
    }
  };

  const getPhotoReviewSummaryByCategory = async () => {
    const res = await getDataStatOverallCate({
      ...option,
      // ...dates,
      sp_category1: categoryOption.sp_category1,
    });
    const target = res?.results.overall_stat;

    setCategoryData({
      totalCnt: res.results.total,
      items: [
        {
          sp_category1: '전체',
          tot_photo_cnt: target.tot_photo_cnt,
          tot_photo_ratio: target.tot_photo_ratio,
          tot_photorevw_cnt: target.tot_photorevw_cnt,
          tot_photorevw_ratio: target.tot_photorevw_ratio,
          tot_revw_cnt: target.tot_revw_cnt,
          tot_photorevw_conversion: target.tot_photorevw_conversion,
        },
      ].concat(
        res.results.items.map((item) => {
          return {
            sp_category1: item.sp_category1,
            tot_photo_cnt: item.tot_photo_cnt,
            tot_photo_ratio: item.tot_photo_ratio,
            tot_photorevw_cnt: item.tot_photorevw_cnt,
            tot_photorevw_ratio: item.tot_photorevw_ratio,
            tot_revw_cnt: item.tot_revw_cnt,
            tot_photorevw_conversion: item.tot_photorevw_conversion,
          };
        }),
      ),
    });
  };

  const getPhotoReviewSummaryByShot = async () => {
    const res = await getDataStatCateShot({
      ...option,
      sp_category1: categoryOption.sp_category1,
      sp_category2: categoryOption.sp_category2,
      // ...dates,
    });
    const target = res?.results.overall_stat;
    setShotData({
      totalCnt: res.results.total,
      items: [
        {
          shot: '전체',
          tot_photo_cnt: target.tot_photo_cnt,
          tot_photo_ratio: target.tot_photo_ratio,
          tot_photorevw_cnt: target.tot_photorevw_cnt,
          tot_photorevw_ratio: target.tot_photorevw_ratio,
        },
      ].concat(
        res.results.items.map((item) => {
          return {
            shot: item.shot,
            tot_photo_cnt: item.tot_photo_cnt,
            tot_photo_ratio: item.tot_photo_ratio,
            tot_photorevw_cnt: item.tot_photorevw_cnt,
            tot_photorevw_ratio: item.tot_photorevw_ratio,
          };
        }),
      ),
    });
  };

  return (
    <Container ref={view}>
      <Category01>데이터 분석 현황</Category01>
      <Category02>포토리뷰 분석 현황</Category02>
      {!user?.results?.isAnalyzed ? (
        <EmptyLink />
      ) : !empty ? (
        <>
          {photoReviewOverall && <Stat items={photoReviewOverall} />}
          <div
            style={{
              height: 2,
              border: '1px dashed #C6C6C6',
            }}
          />

          {/* Shot 분포 현황을 테이블로 보여준다 */}
          <Section style={{ boxShadow: '5px 5px 5px #eee' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <SubTitle style={{ display: 'flex', flexDirection: 'row' }}>
                Shot 분포 현황
              </SubTitle>
              <PropContextProvider
                value={{
                  rules: sortRules,
                  fields: sortFields,
                  handleOption,
                }}
              >
                <Section style={{ padding: '0px', flexDirection: 'row' }}>
                  <SelectSortOption />
                </Section>
              </PropContextProvider>
            </div>
            <PropContextProvider
              value={{
                table: shotTable,
                option: option,
                data: shotData,
                handleOption: handleOption,
              }}
            >
              <Section style={{ padding: '0px' }}>
                <DataList />
              </Section>
            </PropContextProvider>
          </Section>

          {/* 카테고리 분포 현황을 테이블로 보여준다 */}
          <Section style={{ boxShadow: '5px 5px 5px #eee' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <SubTitle style={{ display: 'flex', flexDirection: 'row' }}>
                카테고리 분포 현황
              </SubTitle>
              <PropContextProvider
                value={{
                  rules: sortRules,
                  fields: sortFields,
                  handleOption,
                }}
              >
                <Section style={{ padding: '0px', flexDirection: 'row' }}>
                  <SelectSortOption />
                </Section>
              </PropContextProvider>
            </div>
            <PropContextProvider
              value={{
                table: categoryTable,
                data: categoryData,
                option: option,
                handleOption: handleOption,
              }}
            >
              <Section style={{ padding: '0px' }}>
                <DataList />
              </Section>
            </PropContextProvider>
          </Section>
        </>
      ) : (
        <div>
          <EmptyData />
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 15px;
`;

const Category01 = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #707eae;
  line-height: 20px;
  padding-left: 10px;
`;

const Category02 = styled.div`
  font-size: 30px;
  color: #2b3674;
  font-weight: 800;
  padding-left: 10px;
  margin-bottom: 40px;
  margin-top: -20px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  background-color: white;
  border-radius: 16px;
`;

export default PhotoReview;
