import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Title, CheckBox, Button } from 'components/atoms';

const Confirm = ({
  onClose,
  children,
  onOk,
  success,
  onCancel,
  fail,
  check,
  title,
  type,
}) => {
  const [isCheck, setIsCheck] = useState(false);

  const handleClick = async (e) => {
    const { name } = e.target;
    if (name === 'cancel') {
      await onCancel();
    } else if (name === 'ok') {
      const result = {};
      if (check) {
        result.isCheck = isCheck;
      }

      await onOk(result);
    }
    onClose();
  };

  const handleCheck = (e) => {
    const { checked } = e.target;
    setIsCheck(checked);
  };

  useEffect(() => {
    setIsCheck(false);
  }, []);

  return (
    <Container>
      {title && <Title style={{ paddingLeft: '1rem' }}>{title}</Title>}
      <Content>{children}</Content>
      <Footer check={check}>
        {check && (
          <CheckBox
            label="다시 보지 않기"
            onChange={handleCheck}
            checked={isCheck}
          />
        )}
        <ButtonWrapper>
          {type === 'check' ? (
            <ConfirmButton name="ok" onClick={handleClick}>
              {success || '확인'}
            </ConfirmButton>
          ) : (
            <>
              <ConfirmButton name="cancel" onClick={handleClick}>
                {fail || '취소'}
              </ConfirmButton>
              <ConfirmButton name="ok" onClick={handleClick}>
                {success || '확인'}
              </ConfirmButton>
            </>
          )}
        </ButtonWrapper>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  width: 400px;
  padding: 50px 32px 24px;
  font-size: 0.8rem;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.22);
  border-radius: 3px;
`;

const Content = styled.div`
  padding: 1rem;
  line-height: 1.5;
  word-wrap: break-word;
`;

const Footer = styled.div`
  display: flex;
  justify-content: ${({ check }) => (check ? 'space-between' : 'flex-end')};
  align-items: center;
  margin-top: 24px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const ConfirmButton = styled(Button)`
  padding: 0.3rem 1rem;
  font-weight: 700;
  border-radius: 3px;

  ${({ name }) => {
    if (name === 'cancel') {
      return css`
        border: 1px solid #bcbcbc;
        color: #6a6a6a;
        background-color: inherit;
      `;
    }
    return css``;
  }};
`;

Confirm.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
  onOk: PropTypes.func,
  success: PropTypes.string,
  onCancel: PropTypes.func,
  fail: PropTypes.string,
  check: PropTypes.bool,
};

Confirm.defaultProps = {
  onClose: () => {},
  children: null,
  onOk: () => {},
  success: '',
  onCancel: () => {},
  fail: '',
  check: false,
};

export default Confirm;
