import React from 'react';
import styled, { css } from 'styled-components';

const Message = (props) => {
  return <Container {...props} />;
};

const Container = styled.p`
  font-size: 0.5rem;
  padding-left: 0.5rem;

  ${({ variant }) => {
    if (variant === 'danger') {
      return css`
        color: red;
      `;
    }
    if (variant === 'mute') {
      return css`
        color: #6c757d;
      `;
    }
  }}
`;

export default Message;
