import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const PropContext = createContext();

export const PropContextProvider = ({ value, children }) => (
  <PropContext.Provider value={value}>{children}</PropContext.Provider>
);

PropContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};

export const usePropContext = () => useContext(PropContext);
