import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'loading',
  initialState: {
    count: 0,
    isLoading: false,
    error: '',
  },
  reducers: {
    pending: (state) => {
      state.count += 1;
      state.isLoading = true;
      state.error = '';
    },
    success: (state) => {
      state.count -= 1;
      if (state.count === 0) {
        state.isLoading = false;
      }
    },
    fail: (state, { payload }) => {
      state.count -= 1;
      state.error = payload;
      if (state.count === 0) {
        state.isLoading = false;
      }
    },
  },
});

export const { pending, success, fail } = slice.actions;

export default slice.reducer;
