import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import Toast from './Toast';

const ToastPortal = forwardRef(
  ({ autoClose, autoCloseTime, position }, ref) => {
    const [toasts, setToasts] = useState([]);
    const [removing, setRemoving] = useState('');

    const removeToast = (id) => {
      setToasts(toasts.filter((item) => item.id !== id));
    };

    useEffect(() => {
      if (removing) {
        setToasts((item) => item.filter((toast) => toast.id !== removing));
      }
    }, [removing]);

    useEffect(() => {
      if (autoClose && toasts.length) {
        const targetId = toasts[toasts.length - 1].id;
        setTimeout(() => {
          setRemoving(targetId);
        }, autoCloseTime);
      }
    }, [autoClose, autoCloseTime, toasts]);

    useImperativeHandle(ref, () => ({
      addMessage(inputToast) {
        setToasts([...toasts, { ...inputToast, id: uuidv4() }]);
      },
    }));

    return createPortal(
      <ToastContainer position={position}>
        {toasts.map((item) => (
          <Toast
            key={item.id}
            mode={item.mode}
            message={item.message}
            onClose={() => removeToast(item.id)}
          />
        ))}
      </ToastContainer>,
      document.getElementById('toast-root'),
    );
  },
);

const ToastContainer = styled.div`
  z-index: 900;
  position: fixed;
  ${({ position }) => handleContainerPosition(position)}
`;

const handleContainerPosition = (position) => {
  switch (position) {
    case 'top-left':
      return 'top: 3em; left: 1em;';
    case 'top-center':
      return 'top: 3em; left: 50%; transform: translateX(-50%);';
    case 'top-right':
      return 'top: 3em; right: 1em;';
    case 'bottom-left':
      return 'bottom: 1em; left: 1em;';
    case 'bottom-center':
      return 'bottom: 1em; left: 50%; transform: translateX(-50%)';
    case 'bottom-right':
      return 'bottom: 1em; right: 1em;';
    default:
      return 'top: 3em; right: 1em;';
  }
};

ToastPortal.propTypes = {
  autoClose: PropTypes.bool,
  autoCloseTime: PropTypes.number,
  position: PropTypes.string,
};

ToastPortal.defaultProps = {
  autoClose: true,
  autoCloseTime: 3000,
  position: 'top-right',
};

export default ToastPortal;
