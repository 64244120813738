import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ReactComponent as Pointer } from 'assets/svg/chevron-down.svg';
import data from 'assets/images/data.png';
import link from 'assets/images/repeat.png';
import card from 'assets/images/creditcard.png';

const Accordion = ({ title, header, children, defaultActive, active }) => {
  const [isActive, setIsActive] = useState(defaultActive);
  const [bodyHeight, setBodyHeight] = useState(null);
  const contentRef = useRef();

  const handleButtonClick = useCallback(
    (event) => {
      setBodyHeight(contentRef.current.scrollHeight + 'px');

      if (isActive) {
        setTimeout(() => {
          setBodyHeight(null);
        });
      } else {
        setTimeout(() => {
          setBodyHeight(null);
        }, 300);
      }

      setIsActive(!isActive);
      if (active) {
        active(!isActive);
      }
    },
    [active, isActive],
  );

  return (
    <Container>
      <Header onClick={handleButtonClick}>
        {title && (
          <Title isActive={isActive}>
            <div
              style={{
                height: '25px',
                paddingLeft: '10px',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              {title === '데이터 분석 현황' ? (
                <Icon src={data} alt="" />
              ) : title === '연동 설정' ? (
                <Icon src={link} alt="" />
              ) : title === '결제' ? (
                <Icon src={card} alt="" />
              ) : (
                ''
              )}
              {title}
            </div>
            <Pointer />
          </Title>
        )}
        {header}
      </Header>
      <Body isActive={isActive} style={{ height: bodyHeight }}>
        <Content ref={contentRef}>{children}</Content>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  position: static;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  font-size: 14px;
  padding: 10px;

  svg {
    transition: all ease 1s;
  }

  ${({ isActive }) => {
    if (isActive) {
      return css`
        // &::after {
        //   content: '';
        //   border-top: 0;
        //   border-bottom: 5px solid;
        // }
        svg {
          transform: rotate(-180deg);
        }
      `;
    }
  }}
`;

const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 5px;
`;

const Body = styled.div`
  ${({ isActive }) => {
    if (isActive) {
      return css`
        /* overflow-y: hidden;
        transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
        height: auto;
        max-height: 9999px; */
        overflow-y: hidden;
        transition: height 0.35s ease;
      `;
    }
    return css`
      overflow: hidden;
      height: 0;
      transition: height 0.35s ease;
      /* overflow: hidden;
      max-height: 0;
      transition: max-height 0.3s cubic-bezier(0, 1, 0, 1); */
    `;
  }};
`;

const Content = styled.div``;

Accordion.propTypes = {
  title: PropTypes.node,
  header: PropTypes.node,
  children: PropTypes.node.isRequired,
  defaultActive: PropTypes.bool,
};

Accordion.defaultProps = {
  title: null,
  header: null,
  defaultActive: false,
};

export default Accordion;
