import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input, Message, Label } from 'components/atoms';

const InputLabel = forwardRef(
  ({ label, name, placeholder, onChange, errors, comment, ...rest }, ref) => {
    return (
      <Container className={rest.className}>
        <Label>{label}</Label>
        <Wrapper>
          <Input
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            variant={errors ? 'danger' : ''}
            {...rest}
            ref={ref}
          />
          {errors && <Message variant="danger">{errors}</Message>}
          <Message>{comment}</Message>
        </Wrapper>
      </Container>
    );
  },
);

const Container = styled.div`
  display: grid;
  grid-template-columns: 150px 500px;
`;

const Wrapper = styled.div``;

InputLabel.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.string,
  comment: PropTypes.string,
};

InputLabel.defaultProps = {
  label: '',
  name: '',
  placeholder: '',
  onChange: () => {},
  errors: '',
  comment: '',
};

export default React.memo(InputLabel);
