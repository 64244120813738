import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CheckBox = forwardRef(({ id, label, ...rest }, ref) => {
  return (
    <Container>
      <label htmlFor={id}>
        <input type="checkbox" id={id} ref={ref} {...rest} />
        {label && <span>{label}</span>}
      </label>
    </Container>
  );
});

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;

  label {
    display: flex;
    align-items: baseline;

    input {
      width: 17px;
      position: relative;
      top: 0.2em;
      margin: 0;
      outline: none;
    }

    span {
      padding: 0 5px;
      font-size: 10pt;
      font-weight: 500;
    }
  }
`;

CheckBox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};

CheckBox.defaultProps = {
  id: null,
  label: null,
};

export default React.memo(CheckBox);
