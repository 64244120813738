import React from 'react';
import styled from 'styled-components';

const Contents = ({ item }) => {
  return (
    <div>
      {/* 하이라이트 한 줄 */}
      {item.topic[0].analyzed?.length === 1 &&
        (item.topic[0].analyzed[0].start_idx === 0 ? (
          <>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[0].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[0].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[0].start_idx,
                item.topic[0].analyzed[0].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[0].end_idx,
                item.review.length,
              )}
            </ReviewContents>
          </>
        ) : (
          <>
            <ReviewContents>
              {item.review.substring(0, item.topic[0].analyzed[0].start_idx)}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[0].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[0].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[0].start_idx,
                item.topic[0].analyzed[0].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[0].end_idx,
                item.review.length,
              )}
            </ReviewContents>
          </>
        ))}

      {/* 하이라이트 두 줄  */}
      {item.topic[0].analyzed.length === 2 &&
        (item.topic[0].analyzed[0].start_idx === 0 ? (
          <>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[0].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[0].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[0].start_idx,
                item.topic[0].analyzed[0].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[0].end_idx,
                item.topic[0].analyzed[1].start_idx,
              )}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[1].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[1].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[1].start_idx,
                item.topic[0].analyzed[1].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[1].end_idx,
                item.review.length,
              )}
            </ReviewContents>
          </>
        ) : (
          <>
            <ReviewContents>
              {item.review.substring(0, item.topic[0].analyzed[0].start_idx)}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[0].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[0].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[0].start_idx,
                item.topic[0].analyzed[0].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[0].end_idx,
                item.topic[0].analyzed[1].start_idx,
              )}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[1].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[1].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[1].start_idx,
                item.topic[0].analyzed[1].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[1].end_idx,
                item.review.length,
              )}
            </ReviewContents>
          </>
        ))}

      {/* 하이라이트 세 줄  */}
      {item.topic[0].analyzed.length === 3 &&
        (item.topic[0].analyzed[0].start_idx === 0 ? (
          <>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[0].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[0].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[0].start_idx,
                item.topic[0].analyzed[0].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[0].end_idx,
                item.topic[0].analyzed[1].start_idx,
              )}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[1].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[1].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[1].start_idx,
                item.topic[0].analyzed[1].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[1].end_idx,
                item.topic[0].analyzed[2].start_idx,
              )}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[2].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[2].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[2].start_idx,
                item.topic[0].analyzed[2].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[2].end_idx,
                item.review.length,
              )}
            </ReviewContents>
          </>
        ) : (
          <>
            <ReviewContents>
              {item.review.substring(0, item.topic[0].analyzed[0].start_idx)}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[0].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[0].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[0].start_idx,
                item.topic[0].analyzed[0].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[0].end_idx,
                item.topic[0].analyzed[1].start_idx,
              )}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[1].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[1].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[1].start_idx,
                item.topic[0].analyzed[1].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[1].end_idx,
                item.topic[0].analyzed[2].start_idx,
              )}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[2].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[2].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[2].start_idx,
                item.topic[0].analyzed[2].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[2].end_idx,
                item.review.length,
              )}
            </ReviewContents>
          </>
        ))}

      {/* 하이라이트 네 줄  */}
      {item.topic[0].analyzed.length === 4 &&
        (item.topic[0].analyzed[0].start_idx === 0 ? (
          <>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[0].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[0].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[0].start_idx,
                item.topic[0].analyzed[0].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[0].end_idx,
                item.topic[0].analyzed[1].start_idx,
              )}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[1].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[1].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[1].start_idx,
                item.topic[0].analyzed[1].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[1].end_idx,
                item.topic[0].analyzed[2].start_idx,
              )}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[2].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[2].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[2].start_idx,
                item.topic[0].analyzed[2].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[2].end_idx,
                item.topic[0].analyzed[3].start_idx,
              )}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[3].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[3].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[3].start_idx,
                item.topic[0].analyzed[3].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[3].end_idx,
                item.review.length,
              )}
            </ReviewContents>
          </>
        ) : (
          <>
            <ReviewContents>
              {item.review.substring(0, item.topic[0].analyzed[0].start_idx)}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[0].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[0].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[0].start_idx,
                item.topic[0].analyzed[0].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[0].end_idx,
                item.topic[0].analyzed[1].start_idx,
              )}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[1].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[1].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[1].start_idx,
                item.topic[0].analyzed[1].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[1].end_idx,
                item.topic[0].analyzed[2].start_idx,
              )}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[2].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[2].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[2].start_idx,
                item.topic[0].analyzed[2].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[2].end_idx,
                item.review.length,
              )}
            </ReviewContents>
          </>
        ))}

      {/* 하이라이트 네 줄 이상  */}
      {item.topic[0].analyzed.length > 4 &&
        (item.topic[0].analyzed[0].start_idx === 0 ? (
          <>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[0].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[0].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[0].start_idx,
                item.topic[0].analyzed[0].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[0].end_idx,
                item.topic[0].analyzed[1].start_idx,
              )}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[1].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[1].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[1].start_idx,
                item.topic[0].analyzed[1].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[1].end_idx,
                item.topic[0].analyzed[2].start_idx,
              )}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[2].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[2].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[2].start_idx,
                item.topic[0].analyzed[2].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[2].end_idx,
                item.topic[0].analyzed[3].start_idx,
              )}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[3].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[3].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[3].start_idx,
                item.topic[0].analyzed[3].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[3].end_idx,
                item.review.length,
              )}
            </ReviewContents>
          </>
        ) : (
          <>
            <ReviewContents>
              {item.review.substring(0, item.topic[0].analyzed[0].start_idx)}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[0].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[0].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[0].start_idx,
                item.topic[0].analyzed[0].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[0].end_idx,
                item.topic[0].analyzed[1].start_idx,
              )}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[1].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[1].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[1].start_idx,
                item.topic[0].analyzed[1].end_idx,
              )}
            </ReviewContents>
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[1].end_idx,
                item.topic[0].analyzed[2].start_idx,
              )}
            </ReviewContents>
            <ReviewContents
              style={{
                backgroundColor:
                  item.topic[0].analyzed[2].sentiment === 'POS'
                    ? '#D3EDFF'
                    : item.topic[0].analyzed[2].sentiment === 'NA'
                    ? '#f6ecda'
                    : '#FFD3D3',
              }}
            >
              {item.review.substring(
                item.topic[0].analyzed[2].start_idx,
                item.topic[0].analyzed[2].end_idx,
              )}
            </ReviewContents>
            {item.topic[0].analyzed[3] && (
              <>
                <ReviewContents>
                  {item.review.substring(
                    item.topic[0].analyzed[2].end_idx,
                    item.topic[0].analyzed[3].start_idx,
                  )}
                </ReviewContents>
                <ReviewContents
                  style={{
                    backgroundColor:
                      item.topic[0].analyzed[3].sentiment === 'POS'
                        ? '#D3EDFF'
                        : item.topic[0].analyzed[3].sentiment === 'NA'
                        ? '#f6ecda'
                        : '#FFD3D3',
                  }}
                >
                  {item.review.substring(
                    item.topic[0].analyzed[3].start_idx,
                    item.topic[0].analyzed[3].end_idx,
                  )}
                </ReviewContents>
                <ReviewContents>
                  {item.review.substring(
                    item.topic[0].analyzed[3].end_idx,
                    item.topic[0].analyzed[4]?.start_idx,
                  )}
                </ReviewContents>
                <ReviewContents
                  style={{
                    backgroundColor:
                      item.topic[0].analyzed[4]?.sentiment === 'POS'
                        ? '#D3EDFF'
                        : item.topic[0].analyzed[4]?.sentiment === 'NA'
                        ? '#f6ecda'
                        : '#FFD3D3',
                  }}
                >
                  {item.review.substring(
                    item.topic[0].analyzed[4]?.start_idx,
                    item.topic[0].analyzed[4]?.end_idx,
                  )}
                </ReviewContents>
                <ReviewContents>
                  {item.review.substring(
                    item.topic[0].analyzed[4]?.end_idx,
                    item.topic[0].analyzed[5]?.start_idx,
                  )}
                </ReviewContents>
                <ReviewContents
                  style={{
                    backgroundColor:
                      item.topic[0].analyzed[5]?.sentiment === 'POS'
                        ? '#D3EDFF'
                        : item.topic[0].analyzed[5]?.sentiment === 'NA'
                        ? '#f6ecda'
                        : '#FFD3D3',
                  }}
                >
                  {item.review.substring(
                    item.topic[0].analyzed[5]?.start_idx,
                    item.topic[0].analyzed[5]?.end_idx,
                  )}
                </ReviewContents>
                <ReviewContents>
                  {item.review.substring(
                    item.topic[0].analyzed[5]?.end_idx,
                    item.topic[0].analyzed[6]?.start_idx,
                  )}
                </ReviewContents>
                <ReviewContents
                  style={{
                    backgroundColor:
                      item.topic[0].analyzed[6]?.sentiment === 'POS'
                        ? '#D3EDFF'
                        : item.topic[0].analyzed[6]?.sentiment === 'NA'
                        ? '#f6ecda'
                        : '#FFD3D3',
                  }}
                >
                  {item.review.substring(
                    item.topic[0].analyzed[6]?.start_idx,
                    item.topic[0].analyzed[6]?.end_idx,
                  )}
                </ReviewContents>
              </>
            )}
            <ReviewContents>
              {item.review.substring(
                item.topic[0].analyzed[item.topic[0].analyzed.length - 1]
                  .end_idx,
                item.review.length,
              )}
            </ReviewContents>
          </>
        ))}

      {/* date */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 10,
          alignItems: 'center',
        }}
      >
        <div />
        <CreatedDate>{item.meta.created_date.slice(0, 10)}</CreatedDate>
      </div>
    </div>
  );
};

const ReviewContents = styled.span`
  line-height: 25px;
  white-space: pre-wrap;
  color: #5a5a5a;
  font-weight: 500;
  width: 100%;
  fontweight: 500;
`;

const CreatedDate = styled.div`
  font-size: 13px;
`;

export default Contents;
