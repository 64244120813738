import React from 'react';
import styled from 'styled-components';

const ModalHeader = (prop) => {
  const { onHide, title } = prop;

  return (
    <Header>
      <div>{title}</div>
      <button type="button" onClick={onHide}>
        <span>×</span>
      </button>
    </Header>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  button {
    border: none;
    outline: none;
    background-color: white;
    span {
      float: right;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: 0.5;
    }
  }
`;

export default ModalHeader;
