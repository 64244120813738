import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Title } from 'components/atoms';
import { useNavigate } from 'react-router-dom';
import { PropContextProvider } from 'context/PropContext';
import SearchItemForm from 'components/templates/layout/SearchItemForm';
import ItemDetail from 'pages/analysis/item/ItemDetail';
import noImage from 'assets/images/no_image.png';
import { getDataStatItemSearch } from 'utils/api';
import { Pagination } from 'components/blocks';
import EmptyData from 'components/templates/layout/EmptyData';

const Item = () => {
  const [showDetail, setShowDetail] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [itemCode, setItemCode] = useState('');
  const [option, setOption] = useState({
    page: 1,
    size: 15,
    sort_field: 'TOT_REVW_CNT',
    sort_rule: 'DESC',
    title: '',
    item_code: '',
  });
  const options = [
    { id: 'name', name: '상품명' },
    { id: 'code', name: '상품코드' },
  ];
  const [select, setSelect] = useState(options[0].id);
  const [results, setResults] = useState(null);
  const [total, setTotal] = useState();
  const navigate = useNavigate();
  const view = useRef();

  useEffect(() => {
    if (option.title != '' || option.item_code != '') {
      getSearchItem();
    }
  }, [option]);

  const getSearchItem = async () => {
    const res = await getDataStatItemSearch({ ...option });
    if (res.statusCode === 200) {
      setEmpty(false);
      setResults(res?.results?.items);
      setTotal(res?.results?.total);
    } else {
      setEmpty(true);
    }
  };

  const handleOption = (changeObj) => {
    const page = changeObj.page ? changeObj : 1;
    setOption({ ...option, page, ...changeObj });
    view.current.scrollIntoView();
  };

  const handleItemClick = (change) => {
    setItemCode(change);
    setShowDetail(true);
    /*
    navigate(`${menu.analysis.item.link}/${change}`, {
      state: {
        empty: empty,
        itemCode: itemCode,
        option: option,
        select: select,
        results: results,
        total: total,
      },
    });
    //window.open(`${menu.analysis.item.link}/${change}`);
    */
  };

  const handleShowDetail = (value) => {
    setShowDetail(value);
  };

  return (
    <Container ref={view}>
      {!showDetail ? (
        <div>
          <Section>
            <Title>검색</Title>
            <PropContextProvider
              value={{
                handleOption,
              }}
            >
              <SearchItemForm options={options} setSelect={setSelect} />
            </PropContextProvider>
          </Section>
          {!empty && results && results?.length > 0 ? (
            <Section style={{ marginTop: '30px', width: '100%' }}>
              <Count>검색결과 {total}건</Count>
              <ItemWrapper>
                {results?.map((item, idx) => {
                  return (
                    <ItemContainer key={idx}>
                      {item?.image_url === '' ? (
                        <EmptyWrapper
                          className="itemImage"
                          onClick={(e) => handleItemClick(item.item_code)}
                        >
                          <EmptyImage src={noImage} alt="no image" />
                          <EmptyContent>등록된 이미지가 없습니다.</EmptyContent>
                        </EmptyWrapper>
                      ) : (
                        <ItemImage
                          className="itemImage"
                          onClick={(e) => handleItemClick(item.item_code)}
                          src={item.image_url}
                          alt="image"
                        />
                      )}
                      <div style={{ margin: '0 auto' }}>
                        <ItemName>{item.item_code}</ItemName>
                        <ItemName>{item.title}</ItemName>
                      </div>
                    </ItemContainer>
                  );
                })}
              </ItemWrapper>
              <Line />
              {results?.length > 0 && (
                <Pagination
                  total={total}
                  limit={option?.size}
                  current={option?.page}
                  onChange={(page) => handleOption({ page })}
                />
              )}
            </Section>
          ) : (
            <div style={{ marginTop: '30px' }}>
              {results !== null && <EmptyData />}
            </div>
          )}
        </div>
      ) : (
        <ItemDetail itemCode={itemCode} handleShowDetail={handleShowDetail} />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  height: calc(100% - 120px);
  padding-bottom: 20px;
  gap: 30px;
  padding-top: 15px;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Count = styled.div`
  color: #8a8a8a;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.3px;
`;

const ItemWrapper = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  row-gap: 15px;
  column-gap: 20px;
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ItemContainer = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const EmptyWrapper = styled.div`
  width: 230px;
  height: 230px;
  border-radius: 5px;
  padding: 10% 30px;
  margin: 0 auto;
  box-shadow: 5px 5px 5px #eee;
  cursor: pointer;
  background-color: rgba(240, 242, 251, 0.3);

  &:hover {
    border: 2.5px solid rgba(72, 89, 209, 0.3);
    border-radius: 10px;
  }
`;

const EmptyImage = styled.img`
  width: 70px;
`;

const EmptyContent = styled.div`
  font-size: 0.75rem;
  margin-top: 50px;
  color: #5859d1;
  font-weight: 600;
  letter-spacing: -0.3px;
`;

const ItemImage = styled.img`
  width: 14rem;
  height: 230px;
  border-radius: 5px;
  cursor: pointer;
  object-fit: cover;

  &:hover {
    transform: translateY(-1px);
    border: 2.5px solid rgba(72, 89, 209, 0.3);
    border-radius: 5px;
  }
`;

const ItemName = styled.div`
  margin: 7px 0;
  line-height: 15px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 5px 5px 5px #eee;
  border-radius: 16px;
`;

const Line = styled.div`
  height: 50px;
`;

export default Item;
