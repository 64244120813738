import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { SubTitle } from 'components/atoms';
import FileUpload from 'components/templates/layout/FileUpload';
import { PropContextProvider } from 'context/PropContext';
import DataList from 'components/templates/layout/DataList';
import useTable from 'hooks/useTable';
import { CSVLink } from 'react-csv';
import useModal from 'hooks/useModal';
import { Modal } from 'hoc/modal';
import Confirm from 'hoc/modal/Confirm';
import {
  postIntegrationUpload,
  postIntegrationDownloadFile,
  getIntegrationTemplateDownload,
  getIntegrationUploadList,
} from 'utils/api';
import download from 'assets/images/download.png';
import { numberFormat } from 'utils/format';

const columns = [
  { label: '파일명', col: 'filename' },
  // { label: '상품 개수', col: 'valid_row_num' },
  { label: '결과', col: '' },
  { label: '업로드 날짜', col: 'created_date' },
  { label: '파일', col: 'download_path' },
];

const IntegrationItem = () => {
  const [option, setOption] = useState({
    sort_field: 'CREATED_DATE',
    sort_rule: 'DESC',
    page: 1,
    size: 10,
  });
  const { openModal, closeModal, show } = useModal();
  const {
    show: showConfirm,
    openModal: openConfirm,
    closeModal: closeConfirm,
  } = useModal({ autoClose: false });
  const [data, setData] = useState({ totalCnt: 0, items: [] });
  const table = useTable({ columns, initData: data.items });
  const [csvData, setCsvData] = useState(null);
  const [csvFilename, setCsvFilename] = useState('');
  const [uploadResult, setUploadResult] = useState({ body: '' });
  const downloadRef = useRef();

  useEffect(() => {
    getContents();
  }, [option]);

  const getContents = async () => {
    const res = await getIntegrationUploadList({
      ...option,
      type: 'item',
    });
    setData({
      totalCnt: res.results.total,
      items: res.results.items,
    });
  };

  const handleUpload = async (param) => {
    const res = await postIntegrationUpload(param);
    if (res.statusCode !== 200) {
      setUploadResult({
        body: res.message.body,
        detail: res.message.detail || null,
        error: res.error || null,
      });
      openConfirm();
    } else {
      getContents();
    }
  };

  const handleOption = (changeData) => {
    const page = changeData.page ? changeData : 1;
    setOption({ ...option, page, ...changeData });
  };

  const handleGuideDownload = async () => {
    const res = await getIntegrationTemplateDownload({
      type: 'item',
    });
    const blob = new Blob([res]);
    const fileObjectUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileObjectUrl;
    link.style.display = 'none';
    link.download = '직접연동가이드-상품등록.xlsx';
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(fileObjectUrl);
  };

  const handleDownload = async (path) => {
    setCsvData(null);
    const res = await postIntegrationDownloadFile({
      path: path,
    });
    const temp = path.split('/');
    if (temp[temp.length - 1].indexOf('.csv') > 0) {
      setCsvFilename(temp[temp.length - 1]);
      setCsvData(res);
      downloadRef.current.link.click();
    } else {
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(res),
      )}`;
      const link = document.createElement('a');
      link.href = jsonString;
      link.download = temp[temp.length - 1];
      link.click();
    }
  };

  table.convert.setCol = (item) => {
    const { colIdx } = item;
    // if ([1].includes(colIdx)) {
    //   return numberFormat(item.value);
    if ([1].includes(colIdx)) {
      return '완료';
    } else if ([2].includes(colIdx)) {
      const date = item.value;
      return date.substring(0, 16).replace('T', ' ');
    } else if ([3].includes(colIdx)) {
      return (
        <img
          onClick={() => {
            handleDownload(item.value);
          }}
          src={download}
          alt=""
          style={{
            width: '12px',
            height: '14px',
            cursor: 'pointer',
            border: 'none',
          }}
        />
      );
    }
  };

  table.convert.setColStyle = (item) => {
    const { colIdx } = item;
    if ([0].includes(colIdx)) {
      return {
        padding: '20px 0.75rem',
        color: '#2B3674',
        fontWeight: 600,
        width: '35%',
        textAlign: 'left',
        borderRight: [0].includes(colIdx) && '1px dashed #A3AED0',
      };
    } else if ([1, 2, 3].includes(colIdx)) {
      return {
        width: '15%',
        color: '#2B3674',
        textAlign: 'center',
        borderRight: [1, 2, 3].includes(colIdx) && '1px dashed #A3AED0',
      };
    } else {
      return {
        textAlign: 'center',
      };
    }
  };

  table.convert.setHeadStyle = (item) => {
    const { colIdx } = item;
    if ([0].includes(colIdx)) {
      return {
        textAlign: 'left',
        fontSize: '10pt',
        fontWeight: 500,
        color: '#A3AED0',
        backgroundColor: '#fff',
        border: '0px solid #fff',
      };
    } else {
      return {
        fontSize: '10pt',
        fontWeight: 500,
        color: '#A3AED0',
        backgroundColor: '#fff',
        border: '0px solid #fff',
      };
    }
  };

  return (
    <Container>
      <Section style={{ paddingBottom: 0 }}>
        <FileUpload
          type="item"
          handleUpload={handleUpload}
          handleGuideDownload={handleGuideDownload}
        />
      </Section>
      <Section>
        <SubTitle style={{ marginTop: 40 }}>등록 결과</SubTitle>
        <PropContextProvider
          value={{
            table,
            data,
            option,
            handleOption,
          }}
        >
          <Section style={{ padding: '0px' }}>
            <DataList />
          </Section>
        </PropContextProvider>
      </Section>
      {csvData && csvData.length > 0 && (
        <CSVLink ref={downloadRef} filename={csvFilename} data={csvData} />
      )}
      <Modal show={showConfirm}>
        <Confirm
          onClose={closeConfirm}
          success="확인"
          type="check"
          onOk={() => {}}
          title="업로드 실패"
          width={uploadResult.detail || uploadResult.error ? 800 : 400}
        >
          {uploadResult.body.split('\n').map((item) => {
            return (
              <span style={{ color: '#212121', fontWeight: '600' }}>
                {item}
                <br />
              </span>
            );
          })}
          {uploadResult.detail && (
            <div
              style={{
                backgroundColor: '#F9F9F9',
                maxHeight: '200px',
                border: '1px solid #E4E4E4',
                borderRadius: '3px',
                padding: '13px 17px',
                marginTop: '20px',
              }}
            >
              <div style={{ fontWeight: '600', marginBottom: '5px' }}>
                에러발생데이터
              </div>
              {uploadResult.detail.errorRow.split('\n').map((item) => {
                return (
                  <span style={{ color: '#5C5C5C' }}>
                    {item}
                    <br />
                  </span>
                );
              })}
              {uploadResult.detail.errorNum && (
                <>
                  <div style={{ fontWeight: '600', margin: '20px 0px 5px' }}>
                    에러발생위치
                  </div>
                  <span style={{ color: '#5C5C5C' }}>
                    {uploadResult.detail.errorNum}행
                  </span>
                </>
              )}
            </div>
          )}
          {uploadResult.error && (
            <div
              style={{
                backgroundColor: '#F9F9F9',
                maxHeight: '400px',
                border: '1px solid #E4E4E4',
                borderRadius: '3px',
                padding: '13px 17px',
                marginTop: '20px',
                overflow: 'scroll',
              }}
            >
              <div style={{ fontWeight: '600', marginBottom: '5px' }}>
                에러내용
              </div>
              {uploadResult.error.split('\n').map((item) => {
                return (
                  <span style={{ color: '#5C5C5C' }}>
                    {item}
                    <br />
                  </span>
                );
              })}
            </div>
          )}
        </Confirm>
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 45px 66px 50px 66px;
`;

export default IntegrationItem;
