import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Box = ({ children, className, style }) => {
  return (
    <Container className={className} style={style}>
      {children}
    </Container>
  );
};

const Container = styled.div`
  box-shadow: 5px 5px 5px #eee;
  border-radius: 20px;
  border: 0;
  padding: 1.25rem;
  background-color: #f8f8fd;
`;

Box.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.instanceOf(Object),
};

Box.defaultProps = {
  children: null,
  className: null,
  style: null,
};

export default Box;
