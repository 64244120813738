import React, { useState, useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import SentStat from 'components/templates/layout/SentStat';
import TrendStat from 'components/templates/layout/TrendStat';
import styled from 'styled-components';
import { Button, SubTitle } from 'components/atoms';
import Stat from 'components/templates/layout/Stat';
import { useUser } from 'app/user';
import SearchDateFormNew from 'components/templates/layout/SearchDateFormNew';
import { PropContextProvider } from 'context/PropContext';
import SelectSortOption from 'components/templates/layout/SelectSortOption';
import DataList from 'components/templates/layout/DataList';
import EmptyData from 'components/templates/layout/EmptyData';
import EmptyLink from 'components/templates/layout/EmptyLink';
import useTable from 'hooks/useTable';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import {
  getDataStatOverall,
  getDataStatOverallDaily,
  getDataStatOverallSent,
  getDataStatCateList,
  getDataStatOverallCate,
  getDataStatOverallCateSeries,
  getDataStatOverallDownload,
} from 'utils/api';
import downloadImage from 'assets/images/download.png';
import { ReactComponent as ChevronRight } from 'assets/images/chevron-right.svg';
import { ReactComponent as ChevronLeft } from 'assets/images/chevron-left.svg';

let chartOption01 = {
  chart: {
    type: 'area',
    height: '250',
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  xAxis: {
    visible: false,
    categories: [],
  },
  yAxis: {
    visible: false,
    title: {
      text: '',
    },
  },
  colors: ['#90E4CE', '#5A55D2'],
  tooltip: {
    pointFormat: '<b>{point.y:,.0f}</b>',
  },
  plotOptions: {
    area: {
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 2,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
      lineWidth: 3,
    },
  },
  series: [],
};

const sortRules = [
  { id: 'DESC', value: 'DESC', name: '내림차순' },
  { id: 'ASC', value: 'ASC', name: '오름차순' },
];

const sortFields = [
  { id: 'TOT_REVW_CNT', value: 'TOT_REVW_CNT', name: '리뷰 수' },
  { id: 'TOT_POS_SENT_CNT', value: 'TOT_POS_SENT_CNT', name: '긍정 문장 수' },
  { id: 'TOT_NEG_SENT_CNT', value: 'TOT_NEG_SENT_CNT', name: '부정 문장 수' },
];

const columns = [
  { label: '주제항목', col: 'sp_category1' },
  { label: '리뷰', col: 'tot_revw_cnt' },
  { label: '비중', col: 'tot_revw_ratio' },
  { label: '긍정 문장', col: 'tot_pos_sent_cnt' },
  { label: '부정 문장', col: 'tot_neg_sent_cnt' },
  { label: '긍정 비율', col: 'tot_pos_sent_ratio' },
  { label: '부정 비율', col: 'tot_neg_sent_ratio' },
  { label: '긍/부정', col: 'tot_neg_sent_cnt' },
];

const columns02 = [
  { label: '카테고리', col: 'sp_category1' },
  { label: '리뷰 건수', col: 'target', length: 3 },
  { label: '', col: 'tot_revw_cnt', length: -1 },
  { label: '', col: 'diff_tot_revw_cnt', length: -1 },
  { label: '문장 건수', col: 'target', length: 3 },
  { label: '', col: 'tot_sent_cnt', length: -1 },
  { label: '', col: 'diff_tot_sent_cnt', length: -1 },
  { label: '긍정', col: 'target', length: 3 },
  { label: '', col: 'tot_pos_sent_cnt', length: -1 },
  { label: '', col: 'diff_tot_pos_sent_cnt', length: -1 },
  { label: '부정', col: 'target', length: 3 },
  { label: '', col: 'tot_neg_sent_cnt', length: -1 },
  { label: '', col: 'diff_tot_neg_sent_cnt', length: -1 },
];

const trendMaxWindow = 180;

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const Overall = () => {
  const [currentTopic, setCurrentTopic] = useState('');
  const [currentSecondDepth, setCurrentSecondDepth] = useState(false);
  const { user } = useUser();
  const [trendSeries, setTrendSeries] = useState([]);
  const [trendSeriesChanged, setTrendSeriesChanged] = useState(false);
  const [seriesChanged, setSeriesChanged] = useState(false);
  const [selectedValue00, setSelectedValue00] = useState('tot_revw_cnt');
  const [revwTotal, setRevwTotal] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [reviewSeries, setReviewSeries] = useState([]);
  const [sentSeries, setSentSeries] = useState([]);
  const [sentCountSeries, setSentCountSeries] = useState([]);
  const [chartOption, setChartOption] = useState();
  const [highchartOption01, setHighchartOption01] = useState();
  const [highchartOption02, setHighchartOption02] = useState();
  const [highchartOption03, setHighchartOption03] = useState();
  const [sentStatChanged, setSentStatChanged] = useState(false);
  const [sentStat, setSentStat] = useState(null);
  const [search, setSearch] = useState({
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    endDate: new Date(),
    firstStartDate: dateRange?.startDate,
    firstEndDate: dateRange?.endDate,
    secondStartDate: dateRange?.startDate,
    secondEndDate: dateRange?.endDate,
    sp_category1: '',
  });
  const [option, setOption] = useState({
    sort_field: 'TOT_REVW_CNT',
    sort_rule: 'DESC',
    page: 1,
    size: 100,
  });
  const [categoryOption, setCategoryOption] = useState({
    changed: true,
    sp_category1: '',
  });
  const [compareOption, setCompareOption] = useState({
    activeCompare: false,
  });
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const [csvData, setCsvData] = useState(null);
  const [selectedAllCategory, setSelectedAllCategory] = useState(true);
  const [empty, setEmpty] = useState(false);
  const [selectedValue, setSelectedValue] = useState('tot_revw_cnt');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryMeta, setCategoryMeta] = useState([]);
  const [changed, setChanged] = useState(false);
  const [overall, setOverall] = useState(null);
  const [data, setData] = useState({ totalCnt: 0, items: [] });
  const [data02, setData02] = useState({ totalCnt: 0, items: [] });
  const table = useTable({ columns, initData: data.items });
  const table02 = useTable({ columns: columns02, initData: data02.items });
  const [activeCompare, setActiveCompare] = useState(false);
  const view = useRef();
  const downloadRef = useRef();
  const [dateRange, setDateRange] = useState(null);
  const navigate = useNavigate();
  let period = null;

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    getOverall();
    getCategory();
  }, []);

  useEffect(() => {
    if (!empty) {
      getCategoryOverallSent();
      getCategoryOverallDaily();
    }
  }, [empty, changed]);

  // NOW
  useEffect(() => {
    getCategoryOverallSingle();
  }, [option, categoryOption]);

  useEffect(() => {
    getCategoryOverallSingleSeries();
  }, [selectedCategories]);

  const handleReset = () => {
    setSearch({
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      endDate: new Date(),
      firstStartDate: dateRange?.startDate,
      firstEndDate: dateRange?.endDate,
      secondStartDate: dateRange?.startDate,
      secondEndDate: dateRange?.endDate,
      sp_category1: '',
    });
    setOption({
      sort_field: 'TOT_REVW_CNT',
      sort_rule: 'DESC',
      page: 1,
      size: 10,
    });
    setCategoryOption({
      changed: !categoryOption.changed,
      sp_category1: '',
    });
    setSelectedValue('tot_revw_cnt');
    setChanged(!changed);
  };

  const handleSubmit = () => {
    setCurrentTopic('');
    setOption({ ...option, page: 1 });
    setCategoryOption({
      ...categoryOption,
      sp_category1: search.sp_category1,
      changed: !categoryOption.changed,
    });
    setSelectedCategories([]);
    setChanged(!changed);
  };

  const handleSearch = (changeData) => {
    setSearch({ ...search, ...changeData });
  };

  const handleOption = (changeData) => {
    const page = changeData.page ? changeData : 1;
    setOption({ ...option, page, ...changeData });
  };

  const handleUpperCategory = () => {
    setCurrentSecondDepth(false);
    setOption({ ...option, page: 1 });
    setSelectedCategories([]);
    setCurrentTopic('');
    setCategoryOption({
      changed: !categoryOption.changed,
      sp_category1: '',
    });
  };

  const selectedCategory = (arrow, currIdx) => {
    let idx = currIdx ? currIdx : 0;
    if (!currIdx) {
      for (let i = 0; i < categories.length; i++) {
        if (categories[i] === selectedCategories[0]) {
          idx = i;
          break;
        }
      }
    }

    let target = '';
    if (idx === 0 && arrow === 'prev') {
      target = categories[categories.length - 1];
    } else if (idx === categories.length - 1 && arrow === 'next') {
      target = categories[0];
    } else {
      if (arrow === 'next') {
        target = categories[idx + 1];
      } else {
        target = categories[idx - 1];
      }
    }
    setSelectedCategories([target]);
  };

  const changedSelectedValue = (value) => {
    setSelectedValue(value);
    chartOption01.series = [];
    chartOption01.series.push({
      name: series.targetA[0].topic,
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, '#90E4CE'],
          [1, '#ffffff'],
        ],
      },
      data: series.targetA.map((item) => {
        if (value === 'tot_revw_cnt') {
          return item.tot_revw_cnt;
        } else if (value === 'tot_pos_sent_cnt') {
          return item.tot_pos_sent_cnt;
        } else if (value === 'tot_neg_sent_cnt') {
          return item.tot_neg_sent_cnt;
        }
      }),
    });
    chartOption01.series.push({
      name: '전체 카테고리 평균',
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, '#5A55D2'],
          [1, 'rgba(255, 255, 255, 0.25)'],
        ],
      },
      data: series.targetB.map((item) => {
        if (value === 'tot_revw_cnt') {
          return item.tot_revw_cnt;
        } else if (value === 'tot_pos_sent_cnt') {
          return item.tot_pos_sent_cnt;
        } else if (value === 'tot_neg_sent_cnt') {
          return item.tot_neg_sent_cnt;
        }
      }),
    });
    setChartOption(chartOption01);
    setSeriesChanged(!seriesChanged);
  };

  const getOverall = async () => {
    const res = await getDataStatOverall();
    if (res.statusCode === 200) {
      const today = new Date();
      const fDate = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate(),
      );
      const diff = 30;
      let trends01 = [];
      let trends02 = [];
      let trends03 = [];
      let trends04 = [];
      let trends05 = [];
      for (let i = 0; i <= diff; i++) {
        const curr = new Date(new Date().setDate(fDate.getDate() + i));
        const YY = curr.getFullYear();
        const MM = curr.getMonth();
        const DD = curr.getDate();
        const YYMMDD =
          YY + (MM < 10 ? '0' : '') + MM + (DD < 10 ? '0' : '') + DD;
        const value = res.results.series.filter((item) => {
          return item.dt === YYMMDD;
        });
        trends01.push({
          x: i,
          y: value.length > 0 ? value[0].tot_revw_cnt : 0,
        });
        trends02.push({
          x: i,
          y: value.length > 0 ? value[0].tot_sent_cnt : 0,
        });
        trends03.push({
          x: i,
          y: value.length > 0 ? value[0].tot_pos_sent_cnt : 0,
        });
        trends04.push({
          x: i,
          y: value.length > 0 ? value[0].tot_neg_sent_cnt : 0,
        });
        trends05.push({
          x: i,
          y: value.length > 0 ? value[0].tot_topic_na_sent_cnt : 0,
        });
      }
      setOverall([
        {
          title: '리뷰',
          type: 'review',
          count: res.results.tot_revw_cnt,
          cnt_7: res.results.tot_revw_cnt_7,
          cnt_30: res.results.tot_revw_cnt_30,
          ratio_7: res.results.tot_revw_compare_ratio_7,
          ratio_30: res.results.tot_revw_compare_ratio_30,
        },
        {
          title: '긍정',
          type: 'classification',
          count: res.results.tot_pos_sent_cnt,
          cnt_7: res.results.tot_pos_sent_cnt_7,
          cnt_30: res.results.tot_pos_sent_cnt_30,
          ratio_7: res.results.tot_pos_compare_ratio_7,
          ratio_30: res.results.tot_pos_compare_ratio_30,
          ratio: res.results.tot_pos_sent_ratio,
        },
        {
          title: '부정',
          type: 'classification',
          count: res.results.tot_neg_sent_cnt,
          cnt_7: res.results.tot_neg_sent_cnt_7,
          cnt_30: res.results.tot_neg_sent_cnt_30,
          ratio_7: res.results.tot_neg_compare_ratio_7,
          ratio_30: res.results.tot_neg_compare_ratio_30,
          ratio: res.results.tot_neg_sent_ratio,
        },
      ]);
      setEmpty(false);
    } else {
      setEmpty(true);
    }
  };

  const getCategory = async () => {
    if (categoryMeta.length > 0) return;
    const res = await getDataStatCateList();
    setCategory(
      [{ id: '전체', value: '전체', name: '전체' }].concat(
        res.results.map((item, idx) => {
          return {
            id: item.sp_category1,
            value: item.sp_category1,
            name: item.sp_category1,
          };
        }),
      ),
    );
    setCategoryMeta(res.results);
    /*
    setCategories(
      res.results.map((item) => {
        return item.sp_category1;
      }),
    );
    if (selectedCategories.length < 1) {
      setSelectedCategories([res.results[0].sp_category1, '전체']);
    }
    */
  };

  const getPortionValue = (value01, value02) => {
    const result = ((value01 / value02) * 100).toFixed(2);
    if (result === 'Infinity' || result === 'NaN') {
      return '-%';
    } else {
      return result + '%';
    }
  };

  const getRatioValue = (value01, value02) => {
    const result = ((value01 / value02) * 100 - 100).toFixed(2);
    if (result === 'Infinity' || result === 'NaN') {
      return '-%';
    } else {
      return result + '%';
    }
  };

  // TODO00
  const getDatesParam = (from, to) => {
    const fYY = from?.getFullYear();
    const fMM = from?.getMonth() + 1;
    const fDD = from?.getDate();
    const eYY = to?.getFullYear();
    const eMM = to?.getMonth() + 1;
    const eDD = to?.getDate();
    return from && to
      ? {
          fdt: fYY + (fMM < 10 ? '0' : '') + fMM + (fDD < 10 ? '0' : '') + fDD,
          edt: eYY + (eMM < 10 ? '0' : '') + eMM + (eDD < 10 ? '0' : '') + eDD,
        }
      : null;
  };

  // TODO01
  const getCategoryOverallSingle = async () => {
    const dates = getDatesParam(search.firstStartDate, search.firstEndDate);

    const res = await getDataStatOverallCate({
      ...option,
      ...dates,
      sp_category1: categoryOption.sp_category1,
    });

    if (Object.keys(res.results.dt_range).length > 0) {
      if (!dateRange) {
        const { from, to } = res.results.dt_range;
        period = {
          startDate: new Date(
            from.substring(0, 4),
            from.substring(4, 6) - 1,
            from.substring(6, 8),
          ),
          endDate: new Date(
            to.substring(0, 4),
            to.substring(4, 6) - 1,
            to.substring(6, 8),
          ),
        };
        setDateRange(period);
        setSearch({
          ...search,
          firstStartDate: period.startDate,
          firstEndDate: period.endDate,
          secondStartDate: period.startDate,
          secondEndDate: period.endDate,
        });
      }
      const totalRevw = res.results.items.reduce(function (prev, curr) {
        return typeof prev === 'object'
          ? prev.tot_revw_cnt
          : prev + curr.tot_revw_cnt;
      }, 0);
      const totalSent = res.results.items.reduce(function (prev, curr) {
        return typeof prev === 'object'
          ? prev.tot_sent_cnt
          : prev + curr.tot_sent_cnt;
      }, 0);
      const target = res?.results.overall_stat;
      setData({
        totalCnt: res.results.total,
        items: [
          {
            sp_category1: '전체',
            tot_revw_cnt: target.tot_revw_cnt.toLocaleString(),
            tot_revw_ratio: target.tot_revw_ratio,
            tot_pos_sent_cnt: target.tot_pos_sent_cnt.toLocaleString(),
            tot_pos_sent_ratio: target.tot_pos_sent_ratio,
            tot_neg_sent_cnt: target.tot_neg_sent_cnt.toLocaleString(),
            tot_neg_sent_ratio: target.tot_neg_sent_ratio,
          },
        ].concat(
          res.results.items.map((item) => {
            return {
              sp_category1: !currentSecondDepth
                ? item.sp_category1
                : item.sp_category2,
              tot_revw_cnt: item.tot_revw_cnt.toLocaleString(),
              tot_revw_ratio: item.tot_revw_ratio,
              tot_pos_sent_cnt: item.tot_pos_sent_cnt.toLocaleString(),
              tot_pos_sent_ratio: item.tot_pos_sent_ratio,
              tot_neg_sent_cnt: item.tot_neg_sent_cnt.toLocaleString(),
              tot_neg_sent_ratio: item.tot_neg_sent_ratio,
            };
          }),
        ),
      });
      setCategories(
        res.results.items.map((item) => {
          return !currentSecondDepth ? item.sp_category1 : item.sp_category2;
        }),
      );
      if (!currentSecondDepth && currentTopic === '') {
        setCurrentTopic(res.results.items[0].sp_category1);
      }
      if (!currentSecondDepth && selectedCategories.length == 0) {
        setSelectedCategories([res.results.items[0].sp_category1, '전체']);
      }
      if (currentSecondDepth && currentTopic === '') {
        setCurrentTopic(res.results.items[0].sp_category2);
      }
      if (currentSecondDepth && selectedCategories.length == 0) {
        setSelectedCategories([res.results.items[0].sp_category2, '전체']);
      }
    } else {
      setData({ totalCnt: 0, items: [] });
    }
  };

  // TODO02
  const getCategoryOverallSingleSeries = async () => {
    const dates = getDatesParam(search.firstStartDate, search.firstEndDate);
    if (dates && currentTopic !== '') {
      let categoryParam;
      if (!currentSecondDepth) {
        categoryParam = {
          sp_category1: selectedCategories[0],
        };
      } else {
        categoryParam = {
          sp_category1: categoryOption.sp_category1,
          sp_category2: selectedCategories[0],
        };
      }
      const res = await getDataStatOverallCateSeries({
        ...dates,
        ...categoryParam,
      });
      if (res.results.items) {
        const from = dates
          ? new Date(
              dates?.fdt.substring(0, 4),
              dates?.fdt.substring(4, 6) - 1,
              dates?.fdt.substring(6, 8),
            )
          : new Date(
              period?.startDate.getFullYear(),
              period?.startDate.getMonth(),
              period?.startDate.getDate(),
            );
        const to = dates
          ? new Date(
              dates.edt.substring(0, 4),
              dates.edt.substring(4, 6) - 1,
              dates.edt.substring(6, 8),
            )
          : new Date(
              period?.endDate.getFullYear(),
              period?.endDate.getMonth(),
              period?.endDate.getDate(),
            );
        const diff = Math.floor(
          Math.abs(to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24),
        );
        const start = diff <= trendMaxWindow ? 0 : diff - trendMaxWindow;
        let temp = {
          targetA: [],
          targetB: [],
          categories: [],
        };
        let xCategories = [];
        let curr = dates
          ? new Date(
              dates.fdt.substring(0, 4),
              dates.fdt.substring(4, 6) - 1,
              dates.fdt.substring(6, 8),
            )
          : new Date(
              period?.startDate.getFullYear(),
              period?.startDate.getMonth(),
              period?.startDate.getDate(),
            );
        curr.setDate(curr.getDate() + (start - 1));
        for (let i = start; i <= diff; i++) {
          curr.setDate(curr.getDate() + 1);
          const YY = curr.getFullYear();
          const MM = curr.getMonth() + 1;
          const DD = curr.getDate();
          const YYMMDD =
            YY + (MM < 10 ? '0' : '') + MM + (DD < 10 ? '0' : '') + DD;
          const YYMMDD02 =
            YY +
            '-' +
            (MM < 10 ? '0' : '') +
            MM +
            '-' +
            (DD < 10 ? '0' : '') +
            DD;
          temp.categories.push(YYMMDD02);
          for (let j = 0; j < res.results.items.length; j++) {
            const target = res.results.items[j].series.filter((item) => {
              return item.dt === YYMMDD;
            });
            if (target.length > 0) {
              temp.targetA.push({
                topic: !currentSecondDepth
                  ? target[0].sp_category1
                  : target[0].sp_category2,
                tot_revw_cnt: target[0].tot_revw_cnt,
                tot_pos_sent_cnt: target[0].tot_pos_sent_cnt,
                tot_neg_sent_cnt: target[0].tot_neg_sent_cnt,
              });
            } else {
              temp.targetA.push({
                topic: !currentSecondDepth
                  ? res.results.items[j].sp_category1
                  : res.results.items[j].sp_category2,
                tot_revw_cnt: 0,
                tot_pos_sent_cnt: 0,
                tot_neg_sent_cnt: 0,
              });
            }
          }
          const targetB = res.results.compare.series.filter((item) => {
            return item.dt === YYMMDD;
          });
          if (targetB.length > 0) {
            temp.targetB.push({
              topic: targetB[0].sp_category1,
              tot_revw_cnt: targetB[0].tot_revw_cnt_avg,
              tot_pos_sent_cnt: targetB[0].tot_pos_sent_cnt_avg,
              tot_neg_sent_cnt: targetB[0].tot_neg_sent_cnt_avg,
            });
          } else {
            temp.targetB.push({
              topic: '전체',
              tot_revw_cnt: 0,
              tot_pos_sent_cnt: 0,
              tot_neg_sent_cnt: 0,
            });
          }
        }

        setSeries(temp);
        chartOption01.xAxis.categories = temp.categories;
        chartOption01.series = [];
        chartOption01.series.push({
          name: temp.targetA[0]?.topic,
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#90E4CE'],
              [1, '#ffffff'],
            ],
          },
          data: temp.targetA.map((item) => {
            if (selectedValue === 'tot_revw_cnt') {
              return item.tot_revw_cnt;
            } else if (selectedValue === 'tot_pos_sent_cnt') {
              return item.tot_pos_sent_cnt;
            } else if (selectedValue === 'tot_neg_sent_cnt') {
              return item.tot_neg_sent_cnt;
            }
          }),
        });
        chartOption01.series.push({
          name: '전체 카테고리 평균',
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#5A55D2'],
              [1, 'rgba(255, 255, 255, 0.25)'],
            ],
          },
          data: temp.targetB.map((item) => {
            if (selectedValue === 'tot_revw_cnt') {
              return item.tot_revw_cnt;
            } else if (selectedValue === 'tot_pos_sent_cnt') {
              return item.tot_pos_sent_cnt;
            } else if (selectedValue === 'tot_neg_sent_cnt') {
              return item.tot_neg_sent_cnt;
            }
          }),
        });
        setChartOption(chartOption01);
        setSeriesChanged(!seriesChanged);
      } else {
        setSeries([]);
      }
    }
  };

  // TODO03
  const getCategoryOverallDaily = async () => {
    const dates = getDatesParam(search.firstStartDate, search.firstEndDate);
    const res = await getDataStatOverallDaily({
      ...dates,
    });

    setReviewCount(res.results.stat.tot_revw_cnt);

    const from = dates
      ? new Date(
          dates.fdt.substring(0, 4),
          dates.fdt.substring(4, 6) - 1,
          dates.fdt.substring(6, 8),
        )
      : new Date(
          res.results.dt_range.from.substring(0, 4),
          res.results.dt_range.from.substring(4, 6) - 1,
          res.results.dt_range.from.substring(6, 8),
        );
    const to = dates
      ? new Date(
          dates.edt.substring(0, 4),
          dates.edt.substring(4, 6) - 1,
          dates.edt.substring(6, 8),
        )
      : new Date(
          res.results.dt_range.to.substring(0, 4),
          res.results.dt_range.to.substring(4, 6) - 1,
          res.results.dt_range.to.substring(6, 8),
        );

    const diff = Math.floor(
      Math.abs(to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24),
    );
    const start = diff <= trendMaxWindow ? 0 : diff - trendMaxWindow;

    let temp = {
      categories: [],
      series: [],
    };
    let curr = dates
      ? new Date(
          dates.fdt.substring(0, 4),
          dates.fdt.substring(4, 6) - 1,
          dates.fdt.substring(6, 8),
        )
      : new Date(
          res.results.dt_range.from.substring(0, 4),
          res.results.dt_range.from.substring(4, 6) - 1,
          res.results.dt_range.from.substring(6, 8),
        );
    curr.setDate(curr.getDate() + (start - 1));
    for (let i = start; i <= diff; i++) {
      curr.setDate(curr.getDate() + 1);
      const YY = curr.getFullYear();
      const MM = curr.getMonth() + 1;
      const DD = curr.getDate();
      const YYMMDD = YY + (MM < 10 ? '0' : '') + MM + (DD < 10 ? '0' : '') + DD;
      const YYMMDD02 =
        YY + '-' + (MM < 10 ? '0' : '') + MM + '-' + (DD < 10 ? '0' : '') + DD;
      temp.categories.push(YYMMDD02);
      const target = res.results.items.filter((item) => {
        return item.dt === YYMMDD;
      });
      if (target.length > 0) {
        temp.series.push({
          tot_revw_cnt: target[0].tot_revw_cnt,
          tot_pos_sent_cnt: target[0].tot_pos_sent_cnt,
          tot_neg_sent_cnt: target[0].tot_neg_sent_cnt,
        });
      } else {
        temp.series.push({
          tot_revw_cnt: 0,
          tot_pos_sent_cnt: 0,
          tot_neg_sent_cnt: 0,
        });
      }
    }
    setTrendSeries(temp);
    setTrendSeriesChanged(!trendSeriesChanged);
  };

  // TODO04
  const getCategoryOverallSent = async () => {
    const dates = getDatesParam(search.firstStartDate, search.firstEndDate);
    const res = await getDataStatOverallSent({
      ...dates,
    });
    setSentStat(res?.results.items[0]);
    setSentStatChanged(!sentStatChanged);
  };

  const handleTableOption = (key) => {
    let sort = 'DESC';
    if (key.toUpperCase() == option.sort_field && option.sort_rule === 'DESC')
      sort = 'ASC';
    setOption({
      ...option,
      sort_field: key.toUpperCase(),
      sort_rule: sort,
    });
  };

  table.convert.setHead = (item) => {
    const { colIdx, key, label } = item;
    let title = label;
    if ([0].includes(colIdx)) {
      if (categoryOption.sp_category1 !== '') {
        title = '카테고리II';
      } else {
        title = '카테고리I';
      }
    }
    return <div style={{ alignItems: 'center' }}>{title}</div>;
  };

  table.convert.setCol = (item) => {
    const { rowIdx, colIdx } = item;
    if ([7].includes(colIdx)) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
          onClick={() => {
            if (rowIdx > 0) selectedCategory('prev', rowIdx);
          }}
          onMouseOver={() => {
            setHoverIdx(rowIdx);
          }}
        >
          {data.items[rowIdx]?.tot_pos_sent_ratio?.replace('%', '') !== '-' && (
            <div
              style={{
                backgroundColor: '#106EFC',
                width: parseInt(
                  data.items[rowIdx]?.tot_pos_sent_ratio?.replace('%', ''),
                ),
                height: 10,
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                borderTopRightRadius:
                  data.items[rowIdx]?.tot_pos_sent_ratio?.replace('%', '') >=
                  100
                    ? 5
                    : 0,
                borderBottomRightRadius:
                  data.items[rowIdx]?.tot_pos_sent_ratio?.replace('%', '') >=
                  100
                    ? 5
                    : 0,
              }}
            />
          )}
          {data.items[rowIdx]?.tot_neg_sent_ratio?.replace('%', '') !== '-' && (
            <div
              style={{
                backgroundColor: '#F86994',
                width: parseInt(
                  data.items[rowIdx]?.tot_neg_sent_ratio?.replace('%', ''),
                ),
                height: 10,
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                borderTopLeftRadius:
                  data.items[rowIdx]?.tot_neg_sent_ratio?.replace('%', '') >=
                  100
                    ? 5
                    : 0,
                borderBottomLeftRadius:
                  data.items[rowIdx]?.tot_neg_sent_ratio?.replace('%', '') >=
                  100
                    ? 5
                    : 0,
              }}
            />
          )}
        </div>
      );
    } else if (![0].includes(rowIdx) && [0].includes(colIdx)) {
      if (!currentSecondDepth) {
        return (
          <div
            onClick={() => {
              setCurrentSecondDepth(true);
              setSelectedCategories([]);
              setCurrentTopic('');
              setOption({ ...option, page: 1 });
              setCategoryOption({
                ...categoryOption,
                sp_category1: item.value,
                changed: !categoryOption.changed,
              });
            }}
            onMouseOver={() => {
              setHoverIdx(rowIdx);
            }}
            style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
          >
            {item.value}
          </div>
        );
      } else {
        return (
          <div
            onClick={() => {
              navigate(
                encodeURI(
                  `/analysis/category/${categoryOption.sp_category1.replace(
                    /\//g,
                    '|',
                  )}/${item.value.replace(/\//g, '|')}`,
                ),
              );
            }}
            onMouseOver={() => {
              setHoverIdx(rowIdx);
            }}
            style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
          >
            {item.value}
          </div>
        );
      }
    } else {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
          onClick={() => {
            if (rowIdx > 0) selectedCategory('prev', rowIdx);
          }}
          onMouseOver={() => {
            setHoverIdx(rowIdx);
          }}
        >
          {item.value.toLocaleString() || '-'}
        </div>
      );
    }
  };

  table.convert.setHeadStyle = () => {
    return {
      fontSize: '10pt',
      fontWeight: 500,
      color: '#A3AED0',
      backgroundColor: '#fff',
      border: '0px solid #fff',
    };
  };

  const [hoverIdx, setHoverIdx] = useState(-1);
  table.convert.setColStyle = (item) => {
    const { colIdx, rowIdx } = item;
    if ([0].includes(rowIdx)) {
      return {
        backgroundColor: '#ebf1fc',
        padding: '20px 0.75rem',
        color: '#2B3674',
        fontWeight: 600,
        textAlign: 'center',
        borderRight: [0, 2, 4].includes(colIdx) ? '1px dashed #A3AED0' : '0px',
      };
    } else {
      return {
        padding: '0.7rem 0.75rem',
        cursor: 'pointer',
        backgroundColor:
          selectedCategories[0] === categories[rowIdx - 1]
            ? '#F6F6FC'
            : hoverIdx == rowIdx
            ? '#F6F6FC'
            : '#FFF',
        color: '#2B3674',
        fontWeight: 600,
        textAlign: 'center',
        borderRight: '1px dashed #A3AED0',
        borderRight: [0, 2, 4].includes(colIdx) ? '1px dashed #A3AED0' : '0px',
      };
    }
  };

  const handleDownload = async () => {
    setCsvData(null);
    const dates = getDatesParam(search.firstStartDate, search.firstEndDate);
    const res = await getDataStatOverallDownload({
      sort_field: option.sort_field,
      sort_rule: option.sort_rule,
      fdt: dates.fdt,
      edt: dates.edt,
      sp_category1: categoryOption.sp_category1,
    });
    setCsvData(res);
    downloadRef.current.link.click();
  };

  const getCount = (sp_category1) => {
    let result = '';
    const target = data.items.filter((item) => {
      return item.sp_category1 === sp_category1;
    });
    if (target.length > 0) {
      if (selectedValue === 'tot_revw_cnt') {
        result = target[0].tot_revw_cnt.split('건')[0].toLocaleString();
      } else if (selectedValue === 'tot_pos_sent_cnt') {
        result = target[0].tot_pos_sent_cnt.split('건')[0].toLocaleString();
      } else if (selectedValue === 'tot_neg_sent_cnt') {
        result = target[0].tot_neg_sent_cnt.split('건')[0].toLocaleString();
      }
    }
    return result;
  };

  return (
    <Container ref={view}>
      <Category01>데이터 분석 현황</Category01>
      <Category02>카테고리 분석 현황</Category02>
      {!user?.results?.isAnalyzed ? (
        <EmptyLink />
      ) : !empty ? (
        <>
          {overall && <Stat items={overall} />}
          <div
            style={{
              height: 2,
              border: '1px dashed #C6C6C6',
            }}
          />
          <PropContextProvider
            value={{
              search,
              option,
              category,
              handleReset,
              handleSubmit,
              handleSearch,
              handleOption,
            }}
          >
            {search?.firstStartDate && category && (
              <SearchDateFormNew dateRange={dateRange} />
            )}
          </PropContextProvider>
          {(!compareOption.activeCompare &&
            data &&
            data.totalCnt > 0 &&
            data.items.length > 0) ||
          (compareOption.activeCompare &&
            data02 &&
            data02.totalCnt > 0 &&
            data02.items.length > 0) ? (
            <>
              <div
                className="scroll"
                style={{
                  width: '100%',
                  overflow: 'scroll',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ flex: 4 }}>
                  {trendSeriesChanged && trendSeries?.series?.length > 0 && (
                    <PropContextProvider
                      value={{
                        data: sentStat,
                        reviewCount: reviewCount,
                        trendSeries: trendSeries,
                        changed: trendSeriesChanged,
                        width: (900 * getWindowDimensions().width) / 1750,
                        height: (getWindowDimensions().width / 1750) * 300,
                        name: '전체 카테고리',
                      }}
                    >
                      <TrendStat />
                    </PropContextProvider>
                  )}
                  {!trendSeriesChanged && trendSeries?.series?.length > 0 && (
                    <PropContextProvider
                      value={{
                        data: sentStat,
                        reviewCount: reviewCount,
                        trendSeries: trendSeries,
                        changed: trendSeriesChanged,
                        width: (900 * getWindowDimensions().width) / 1750,
                        height: (getWindowDimensions().width / 1750) * 300,
                        name: '전체 카테고리',
                      }}
                    >
                      <TrendStat />
                    </PropContextProvider>
                  )}
                </div>
                <div style={{ flex: 2 }}>
                  {sentStatChanged && sentStat && (
                    <PropContextProvider
                      value={{
                        data: sentStat,
                        changed: sentStatChanged,
                      }}
                    >
                      <SentStat />
                    </PropContextProvider>
                  )}
                  {!sentStatChanged && sentStat && (
                    <PropContextProvider
                      value={{
                        data: sentStat,
                        changed: sentStatChanged,
                      }}
                    >
                      <SentStat />
                    </PropContextProvider>
                  )}
                </div>
              </div>
              <Section style={{ boxShadow: '5px 5px 5px #eee' }}>
                <ChartButtonContainer>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 20,
                        fontSize: '12pt',
                        fontWeight: 700,
                        color: '#2B3674',
                      }}
                    >
                      <ChevronLeft
                        style={{ marginRight: 15 }}
                        onClick={() => selectedCategory('prev')}
                      />
                      <div style={{ textAlign: 'center' }}>
                        <div
                          style={{
                            marginBottom: 6,
                            color: '#9A9A9A',
                            fontSize: '9pt',
                            fontWeight: '400',
                          }}
                        >
                          {!currentSecondDepth ? '카테고리 I' : '카테고리 II'}
                        </div>
                        <div>{selectedCategories[0]}</div>
                        <div
                          style={{
                            marginTop: 7,
                            color: '#9A9A9A',
                            fontSize: '10pt',
                          }}
                        >
                          {getCount(selectedCategories[0])}
                        </div>
                      </div>
                      <ChevronRight
                        style={{ marginLeft: 15 }}
                        onClick={() => selectedCategory('next')}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      marginRight: '20px',
                      gap: '10px',
                    }}
                  >
                    {selectedValue === 'tot_revw_cnt' ? (
                      <Button
                        type="button"
                        onClick={() => changedSelectedValue('tot_revw_cnt')}
                        style={{
                          borderRadius: '100px',
                          boxShadow: '3px 3px 10px rgba(37, 99, 235, 0.4)',
                          color: '#F9FAFB',
                          backgroundColor: '#2563EB',
                          fontWeight: 700,
                        }}
                      >
                        리뷰
                      </Button>
                    ) : (
                      <Button
                        outline
                        type="button"
                        onClick={() => changedSelectedValue('tot_revw_cnt')}
                        style={{
                          borderRadius: '100px',
                          border: '1.5px solid rgba(167,192,247,1)',
                          color: 'rgba(167,192,247,1)',
                          fontWeight: 700,
                          boxShadow: 'none',
                        }}
                      >
                        리뷰
                      </Button>
                    )}
                    {selectedValue === 'tot_pos_sent_cnt' ? (
                      <Button
                        type="button"
                        onClick={() => changedSelectedValue('tot_pos_sent_cnt')}
                        style={{
                          borderRadius: '100px',
                          boxShadow: '3px 3px 10px rgba(37, 99, 235, 0.4)',
                          color: '#F9FAFB',
                          backgroundColor: '#2563EB',
                          fontWeight: 700,
                        }}
                      >
                        긍정
                      </Button>
                    ) : (
                      <Button
                        outline
                        type="button"
                        onClick={() => changedSelectedValue('tot_pos_sent_cnt')}
                        style={{
                          borderRadius: '100px',
                          border: '1.5px solid rgba(167,192,247,1)',
                          color: 'rgba(167,192,247,1)',
                          fontWeight: 700,
                          boxShadow: 'none',
                        }}
                      >
                        긍정
                      </Button>
                    )}
                    {selectedValue === 'tot_neg_sent_cnt' ? (
                      <Button
                        type="button"
                        onClick={() => changedSelectedValue('tot_neg_sent_cnt')}
                        style={{
                          borderRadius: '100px',
                          boxShadow: '3px 3px 10px rgba(37, 99, 235, 0.4)',
                          color: '#F9FAFB',
                          backgroundColor: '#2563EB',
                          fontWeight: 700,
                        }}
                      >
                        부정
                      </Button>
                    ) : (
                      <Button
                        outline
                        type="button"
                        onClick={() => changedSelectedValue('tot_neg_sent_cnt')}
                        style={{
                          borderRadius: '100px',
                          border: '1.5px solid rgba(167,192,247,1)',
                          color: 'rgba(167,192,247,1)',
                          fontWeight: 700,
                          boxShadow: 'none',
                        }}
                      >
                        부정
                      </Button>
                    )}
                  </div>
                </ChartButtonContainer>
                {seriesChanged && (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOption}
                  />
                )}
                {!seriesChanged && (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOption}
                  />
                )}
              </Section>
              <Section style={{ boxShadow: '5px 5px 5px #eee' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <SubTitle style={{ display: 'flex', flexDirection: 'row' }}>
                    카테고리 현황
                    <img
                      onClick={() => handleDownload()}
                      src={downloadImage}
                      style={{
                        width: 14,
                        height: 14,
                        marginLeft: 5,
                        cursor: 'pointer',
                      }}
                    />
                  </SubTitle>
                  <PropContextProvider
                    value={{
                      rules: sortRules,
                      fields: sortFields,
                      handleOption,
                      handleDownload,
                      activeCompare: compareOption.activeCompare,
                      second: currentSecondDepth,
                      handleUpperCategory,
                    }}
                  >
                    <Section style={{ padding: '0px', flexDirection: 'row' }}>
                      <SelectSortOption />
                    </Section>
                  </PropContextProvider>
                </div>
                <PropContextProvider
                  value={{
                    table,
                    data,
                    option,
                    handleOption,
                  }}
                >
                  <Section style={{ padding: '0px' }}>
                    <DataList />
                  </Section>
                </PropContextProvider>
              </Section>
            </>
          ) : (
            <EmptyData />
          )}
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            height: 'calc(100% - 120px)',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EmptyData />
        </div>
      )}
      {csvData && csvData.length > 0 && (
        <CSVLink ref={downloadRef} filename="전체분석현황.csv" data={csvData} />
      )}
    </Container>
  );
};
//<CSVDownload filename={'전체분석현황'} data={csvData} target="_blank" />

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 15px;

  .scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Category01 = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #707eae;
  line-height: 20px;
  padding-left: 10px;
`;

const Category02 = styled.div`
  font-size: 30px;
  color: #2b3674;
  font-weight: 800;
  padding-left: 10px;
  margin-bottom: 40px;
  margin-top: -20px;
`;

const SubTitleWrapper = styled.section`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  background-color: white;
  align-items: 'flex-start';
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  background-color: white;
  border-radius: 16px;
`;

const ChartButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 20px;
  padding-top: 20px;
  gap: 10px;
`;

const TableRow = styled.div`
  &:hover {
    background-color: red;
  }
`;

export default Overall;
