import React from 'react';
import ReactDOM from 'react-dom';
import PropType from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import {
  InfoFillCircle,
  CheckFillCircle,
  CloseFillCircle,
} from 'components/atoms/icon';

let container = null;

function createNotification(message, duration, type) {
  if (container === null) {
    container = document.createElement('div');
    container.style = css`
      position: fixed;
      display: flex;
      flex-direction: column;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 900;
    `;
    // container.style.position = 'fixed';
    // container.style.display = 'flex';
    // container.style.flexDirection = 'column';
    // container.style.top = '10px';
    // container.style.left = '50%';
    // container.style.transform = 'translateX(-50%)';
    document.body.appendChild(container);
  }

  const div = document.createElement('div');

  container.appendChild(div);

  const removeNotication = () => {
    ReactDOM.unmountComponentAtNode(div);
    if (div?.parentNode) {
      div.parentNode.removeChild(div);
    }
  };

  ReactDOM.render(
    <NotificateRender
      message={message}
      type={type}
      onClose={removeNotication}
    />,
    div,
    () => {
      setTimeout(removeNotication, duration || 5000);
    },
  );
}

const NotificateRender = ({ type, onClose, message }) => {
  return (
    <Notificate onClick={onClose}>
      {type === 'info' && <InfoFillCircle fill="#1890ff" />}
      {type === 'success' && <CheckFillCircle fill="#52c41a" />}
      {type === 'fail' && <CloseFillCircle fill="#ff4d4f" />}
      <span>{message}</span>
    </Notificate>
  );
};

NotificateRender.propTypes = {
  type: PropType.string.isRequired,
  onClose: PropType.func.isRequired,
  message: PropType.string.isRequired,
};

export const message = {
  success: (msg, duration) => createNotification(msg, duration, 'success'),
  info: (msg, duration) => createNotification(msg, duration, 'info'),
  fail: (msg, duration) => createNotification(msg, duration, 'fail'),
};

export default function useMessage() {
  return (message, { duration, type }) =>
    createNotification(message, duration, type);
}

const fadeIn = keyframes`
  from{
    opacity: 0;
  } to {
    opacity: 1;
  }
`;

const Notificate = styled.div`
  position: relative;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgb(69, 69, 69);
  transition: 0.2s;
  animation: ${fadeIn} 0.75s;
  color: black;
  background-color: white;
  font-size: 0.8rem;
  line-height: 1.5;

  &:hover {
    transition: 0.2;
    transform: scale(0.95);
    box-shadow: 0px 0px 5px gray;
  }
`;
