import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { SubTitle, Title } from 'components/atoms';
import { useLocation } from 'react-router-dom';
import { PropContextProvider } from 'context/PropContext';
import ItemDetail from 'pages/analysis/item/ItemDetail';
import SearchRankForm from 'components/templates/layout/SearchRankForm';
import crown from 'assets/images/all_rank.png';
import chart from 'assets/images/chart_rank.png';
import alert from 'assets/images/alert_rank.png';
import noImage from 'assets/images/no_image.png';
import {
  getDataStatCateList,
  getDataStatCateTopic,
  getDataStatItemRank,
} from 'utils/api';

const Rank = () => {
  const view = useRef();
  const location = useLocation();
  const [empty, setEmpty] = useState(false);
  const [categoryInfo, setCategoryInfo] = useState(null);
  const [selectedCategory01, setSelectedCategory01] = useState(null);
  const [selectedCategory02, setSelectedCategory02] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [categories, setCategories] = useState([]);
  const [doneCompare, setDoneCompare] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [topic, setTopic] = useState('');
  const [rank, setRank] = useState('');
  const [allRank, setAllRank] = useState();
  const [monthRank, setMonthRank] = useState();
  const [weekRank, setWeekRank] = useState();
  const [itemCode, setItemCode] = useState('');
  const [searchType, setSearchType] = useState('total');
  const [showDetail, setShowDetail] = useState(false);
  const [activeCompare, setActiveCompare] = useState(false);
  const [compareOption, setCompareOption] = useState({
    activeCompare: false,
  });
  const [categoryOption, setCategoryOption] = useState({
    changed: true,
    sp_category1: '',
    sp_category2: '',
    topic: '',
  });

  const [search, setSearch] = useState({
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    endDate: new Date(),
    firstStartDate: dateRange?.startDate,
    firstEndDate: dateRange?.endDate,
    secondStartDate: dateRange?.startDate,
    secondEndDate: dateRange?.endDate,
    sp_category1: '',
    topic: '',
  });

  const rankField = [
    { id: 'TOT_REVW_CNT', value: 'TOT_REVW_CNT', name: '리뷰 수' },
    { id: 'TOT_POS_SENT_CNT', value: 'TOT_POS_SENT_CNT', name: '긍정 수' },
    { id: 'TOT_NEG_SENT_CNT', value: 'TOT_NEG_SENT_CNT', name: '부정 수' },
  ];

  useEffect(() => {
    if (!empty) {
      if (
        categoryOption.sp_category1 != '' &&
        categoryOption.sp_category2 != ''
      ) {
        getCategoryOverallSingle();
      }
      setCompareOption({ activeCompare: activeCompare });
    }
  }, [empty, categoryOption]);

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    if (
      categoryOption.sp_category1 !== '' &&
      categoryOption.sp_category2 !== '' &&
      categoryOption.topic !== '' &&
      categoryOption.rank_field !== ''
    ) {
      getCategoryRank();
    }
  }, [categoryOption]);

  const getCategory = async () => {
    const res = await getDataStatCateList();
    if (res.statusCode === 200) {
      setEmpty(false);
      setCategoryInfo(res.results);
      if (
        selectedCategory01 === null &&
        selectedCategory01 === null &&
        selectedTopic !== '' &&
        window.location.href.split('/').length === 7
      ) {
        const sp_category1 = decodeURI(
          window.location.href.split('/')[5],
        ).replace(/\|/g, '/');
        const sp_category2 = decodeURI(
          window.location.href.split('/')[6],
        ).replace(/\|/g, '/');
        setSelectedCategory01(sp_category1);
        setSelectedCategory02(sp_category2);
        setCategoryOption({
          sp_category1: sp_category1,
          sp_category2: sp_category2,
        });
      } else if (location.state) {
        setSelectedCategory01(location.state.sp_category1);
        setSelectedCategory02(location.state.sp_category2);
        setCategoryOption({
          sp_category1: location.state.sp_category1,
          sp_category2: location.state.sp_category2,
        });
      } else {
        setSelectedCategory01(res.results[0].sp_category1);
        setSelectedCategory02(res.results[0].sp_category2[0]);
        setCategoryOption({
          sp_category1: res.results[0].sp_category1,
          sp_category2: res.results[0].sp_category2[0],
        });
      }
    } else {
      setEmpty(true);
    }
  };

  useEffect(() => {
    setCategoryOption({
      ...categoryOption,
      topic: categories[0],
      rank_field: rankField[0].id,
    });
  }, [categories.length]);

  const handleCategoryOption = (changeData) => {
    if (changeData.sp_category1) setSelectedCategory01(changeData.sp_category1);
    setSelectedCategory02(changeData.sp_category2);
    setTopic(changeData.topic);
    setRank(changeData.rank);

    setDateRange(null);
    setSearch({
      ...search,
      firstStartDate: null,
      firstEndDate: null,
      secondStartDate: null,
      secondEndDate: null,
    });
    setCategoryOption({ ...categoryOption, ...changeData });
    setSelectedCategories([]);
    setSelectedTopic('');
  };

  const getCategoryOverallSingle = async () => {
    setDoneCompare(false);
    const res = await getDataStatCateTopic({
      sp_category1: categoryOption.sp_category1,
      sp_category2: categoryOption.sp_category2,
    });

    if (Object.keys(res.results.dt_range).length > 0) {
      setCategories(
        res.results.items.map((item) => {
          return item.topic;
        }),
      );
      if (selectedTopic === '') {
        setSelectedCategories(
          res.results.items.map((item) => {
            return item.topic;
          }),
        );
      }
    } else {
      setCategories([]);
      setSelectedCategories([]);
    }
    if (selectedTopic === '') {
      setSelectedTopic(res.results.items[0].topic);
      setSelectedCategories([res.results.items[0].topic, '전체']);
    }
  };

  const getCategoryRank = async () => {
    const res01 = await getDataStatItemRank({
      sp_category1: categoryOption.sp_category1,
      sp_category2: categoryOption.sp_category2,
      topic: categoryOption.topic,
      rank_field: categoryOption.rank_field,
      rank_type: 'ALL',
    });
    const res02 = await getDataStatItemRank({
      sp_category1: categoryOption.sp_category1,
      sp_category2: categoryOption.sp_category2,
      topic: categoryOption.topic,
      rank_field: categoryOption.rank_field,
      rank_type: 'MONTHLY',
    });
    const res03 = await getDataStatItemRank({
      sp_category1: categoryOption.sp_category1,
      sp_category2: categoryOption.sp_category2,
      topic: categoryOption.topic,
      rank_field: categoryOption.rank_field,
      rank_type: 'WEEKLY',
    });
    setAllRank(res01.results?.items);
    setMonthRank(res02.results?.items);
    setWeekRank(res03.results?.items);
  };

  const getDateWithFormat = (second) => {
    let startDate = new Date();
    let endDate = new Date();
    if (!second) {
      startDate.setDate(startDate.getDate() - (!second ? 7 : 16));
      endDate.setDate(endDate.getDate() - (!second ? 1 : 9));
    } else if (second) {
      startDate.setDate(startDate.getDate() - (!second ? 31 : 30));
      endDate.setDate(endDate.getDate() - (!second ? 1 : 1));
    }
    const fYY = startDate.getFullYear().toString().substring(2, 4);
    const fMM = startDate.getMonth() + 1;
    const fDD = startDate.getDate();
    const eMM = endDate.getMonth() + 1;
    const eDD = endDate.getDate();
    return (
      fYY +
      '.' +
      (fMM < 10 ? '0' : '') +
      fMM +
      '.' +
      (fDD < 10 ? '0' : '') +
      fDD +
      ' ~ ' +
      (eMM < 10 ? '0' : '') +
      eMM +
      '.' +
      (eDD < 10 ? '0' : '') +
      eDD
    );
  };

  const handleItemClick = (change, type) => {
    setSearchType(type);
    setItemCode(change);
    setShowDetail(true);
  };

  const handleShowDetail = (value) => {
    setShowDetail(value);
  };

  return (
    <Container ref={view}>
      {!showDetail ? (
        <>
          <Section>
            <Title>상품 순위 정보</Title>
            {categoryInfo && selectedCategory01 && (
              <PropContextProvider
                value={{
                  category: categoryInfo,
                  selectedCategory01,
                  selectedCategory02,
                  handleOption: handleCategoryOption,
                  categories,
                  rankField,
                }}
              >
                <SearchRankForm
                  selectedValue01={categoryOption.sp_category1}
                  selectedValue02={categoryOption.sp_category2}
                  selectedTopic={categoryOption.topic}
                  selectedRankField={categoryOption.rank_field}
                />
              </PropContextProvider>
            )}
          </Section>
          <RankWrapper>
            <Section
              style={{ minHeight: '940px', padding: '25px 16px', gap: '10px' }}
            >
              <Category>
                <Flex>
                  <Icon src={crown} alt="" />
                  <SubTitle style={{ fontSize: '18px' }}>전체 순위</SubTitle>
                </Flex>
              </Category>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '4fr 1fr',
                  paddingBottom: '10px',
                  borderBottom: '0.5px dashed rgba(163,174,208, 0.5)',
                  marginTop: '20px',
                }}
              >
                <Text>상품</Text>
                <Text>
                  {categoryOption.rank_field === 'TOT_POS_SENT_CNT'
                    ? '긍정 수'
                    : categoryOption.rank_field === 'TOT_REVW_CNT'
                    ? '리뷰 수'
                    : '부정 수'}
                </Text>
              </div>
              {allRank?.length > 0 ? (
                allRank?.map((item, idx) => {
                  return (
                    <ItemWrapper
                      key={idx}
                      style={{ gridTemplateColumns: '0.5fr 4fr 1fr' }}
                      onClick={() => handleItemClick(item.item_code, 'total')}
                    >
                      {item?.image_url === '' ? (
                        <EmptyImg src={noImage} alt="no image" />
                      ) : (
                        <ItemImg src={item.image_url} alt="" />
                      )}

                      <div>
                        <Number>{item.rank}.</Number>
                        {item.title.length > 30 ? (
                          <ItemTitle>
                            {item.title.slice(0, 27) + '...'}
                          </ItemTitle>
                        ) : (
                          <ItemTitle>{item.title}</ItemTitle>
                        )}
                        <ItemCode>{item.item_code}</ItemCode>
                      </div>
                      <div>
                        <Cnt>{item.value.toLocaleString()}</Cnt>
                        <ItemCode style={{ margin: 0, textAlign: 'center' }}>
                          {item.percent}
                        </ItemCode>
                      </div>
                    </ItemWrapper>
                  );
                })
              ) : (
                <EmptyWrapper>
                  <EmptyImg src={alert} alt="" />
                  <Contents>해당 데이터가 없습니다.</Contents>
                </EmptyWrapper>
              )}
            </Section>
            <Section
              style={{
                minHeight: '940px',
                display: 'grid',
                gridTemplateColumns: '1fr 0.0000001fr 1fr',
                padding: '25px 16px',
              }}
            >
              <Section02>
                <Category>
                  <Flex>
                    <Icon src={chart} alt="" />
                    <SubTitle style={{ fontSize: '18px' }}>순위 변화</SubTitle>
                  </Flex>
                  <DateWrapper>
                    <Period>최근 30일</Period>
                  </DateWrapper>
                </Category>
                <div
                  style={{ justifyContent: 'space-between', display: 'flex' }}
                >
                  <div />
                  <PeriodDate>({getDateWithFormat('second')})</PeriodDate>
                </div>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '4fr 1fr 1fr',
                    paddingBottom: '10px',
                    marginTop: '10px',
                    borderBottom: '0.5px dashed rgba(163,174,208, 0.5)',
                  }}
                >
                  <Text>상품</Text>
                  <Text>
                    {categoryOption.rank_field === 'TOT_POS_SENT_CNT'
                      ? '긍정 수'
                      : categoryOption.rank_field === 'TOT_REVW_CNT'
                      ? '리뷰 수'
                      : '부정 수'}
                  </Text>
                  <Text>상승/하락</Text>
                </div>
                {monthRank?.length > 0 ? (
                  monthRank?.map((item, idx) => {
                    return (
                      <ItemWrapper
                        key={idx}
                        onClick={() =>
                          handleItemClick(item.item_code, 'last30Days')
                        }
                      >
                        {item?.image_url === '' ? (
                          <EmptyImg src={noImage} alt="no image" />
                        ) : (
                          <ItemImg src={item.image_url} alt="" />
                        )}

                        <div>
                          <Number>{item.rank}.</Number>
                          {item.title.length > 23 ? (
                            <ItemTitle>
                              {item.title.slice(0, 21) + '...'}
                            </ItemTitle>
                          ) : (
                            <ItemTitle>{item.title}</ItemTitle>
                          )}
                          <ItemCode>{item.item_code}</ItemCode>
                        </div>
                        <div>
                          <Cnt>{item.value.toLocaleString()}</Cnt>
                          <ItemCode style={{ margin: 0, textAlign: 'center' }}>
                            {item.percent}
                          </ItemCode>
                        </div>
                        <Compare compare={item.compare}>
                          {item.compare < 0 ? '▼' : item.compare > 0 ? '▲' : ''}
                          &nbsp;
                          {item.compare < 0
                            ? item.compare.substring(1)
                            : item.compare}
                        </Compare>
                      </ItemWrapper>
                    );
                  })
                ) : (
                  <EmptyWrapper>
                    <EmptyImg src={alert} alt="" />
                    <Contents>해당 데이터가 없습니다.</Contents>
                  </EmptyWrapper>
                )}
              </Section02>
              <div style={{ border: '0.1px dashed #A3AED0' }} />
              <Section02>
                <Category>
                  <div />
                  <DateWrapper>
                    <Period>최근 7일</Period>
                  </DateWrapper>
                </Category>
                <div
                  style={{ justifyContent: 'space-between', display: 'flex' }}
                >
                  <div /> <PeriodDate>({getDateWithFormat()})</PeriodDate>
                </div>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '4fr 1fr 1fr',
                    paddingBottom: '10px',
                    marginTop: '10px',
                    borderBottom: '0.5px dashed rgba(163,174,208, 0.5)',
                  }}
                >
                  <Text>상품</Text>
                  <Text>
                    {categoryOption.rank_field === 'TOT_POS_SENT_CNT'
                      ? '긍정 수'
                      : categoryOption.rank_field === 'TOT_REVW_CNT'
                      ? '리뷰 수'
                      : '부정 수'}
                  </Text>
                  <Text>상승/하락</Text>
                </div>
                {weekRank?.length > 0 ? (
                  weekRank?.map((item, idx) => {
                    return (
                      <ItemWrapper
                        key={idx}
                        onClick={() =>
                          handleItemClick(item.item_code, 'last7Days')
                        }
                      >
                        {item?.image_url === '' ? (
                          <EmptyImg src={noImage} alt="no image" />
                        ) : (
                          <ItemImg src={item.image_url} alt="" />
                        )}

                        <div>
                          <Number>{item.rank}.</Number>
                          {item.title.length > 23 ? (
                            <ItemTitle>
                              {item.title.slice(0, 21) + '...'}
                            </ItemTitle>
                          ) : (
                            <ItemTitle>{item.title}</ItemTitle>
                          )}
                          <ItemCode>{item.item_code}</ItemCode>
                        </div>
                        <div>
                          <Cnt>{item.value.toLocaleString()}</Cnt>
                          <ItemCode style={{ margin: 0, textAlign: 'center' }}>
                            {item.percent}
                          </ItemCode>
                        </div>
                        <Compare compare={item.compare}>
                          {item.compare < 0 ? '▼' : item.compare > 0 ? '▲' : ''}
                          &nbsp;
                          {item.compare < 0
                            ? item.compare.substring(1)
                            : item.compare}
                        </Compare>
                      </ItemWrapper>
                    );
                  })
                ) : (
                  <EmptyWrapper>
                    <EmptyImg src={alert} alt="" />
                    <Contents>해당 데이터가 없습니다.</Contents>
                  </EmptyWrapper>
                )}
              </Section02>
            </Section>
          </RankWrapper>
        </>
      ) : (
        <ItemDetail
          itemCode={itemCode}
          handleShowDetail={handleShowDetail}
          searchType={searchType}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  height: calc(100% - 120px);
  padding-bottom: 20px;
  gap: 30px;
  padding-top: 15px;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 5px 5px 5px #eee;
  border-radius: 16px;
`;

const Section02 = styled.section`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
`;

const RankWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 15px;
`;

const Category = styled.div`
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const DateWrapper = styled.div`
  text-align: right;
  padding-right: 17px;
`;

const Period = styled.div`
  color: #4318ff;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.5px;
`;

const PeriodDate = styled.div`
  font-size: 12px;
  color: #a3aed0;
  line-height: 20px;
  letter-spacing: -0.5px;
  margin-top: -20px;
  padding-right: 15px;
`;

const Icon = styled.img`
  width: 23px;
  margin: 0 7px 0 10px;
`;

const Text = styled.div`
  font-size: 13px;
  color: #a3aed0;
  text-align: center;
`;

const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 2.5fr 1fr 0.7fr;
  align-items: center;
  cursor: pointer;
  height: 70px;
  &:hover {
    border: 0.5px solid #d9d9d9;
    border-radius: 20px;
    transform: translateY(-1px);
    box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.15);
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 250px 0;
`;

const EmptyImg = styled.img`
  width: 35px;
  margin: 0 auto;
`;

const Contents = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #a3aed0;
  line-height: 50px;
  margin: 15px auto 0;
`;

const ItemImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 16px;
`;

const Number = styled.span`
  color: #ffa800;
  font-weight: 600;
  fon-size: 13px;
  margin-right: 5px;
`;

const ItemTitle = styled.span`
  color: #2b3674;
  font-size: 13px;
  font-weight: 600;
  line-height: 25px;
`;

const ItemCode = styled.div`
  font-size: 12px;
  color: #a3aed0;
  margin-left: 15px;
  line-height: 20px;
`;

const Cnt = styled.div`
  font-size: 13px;
  color: #2b3674;
  font-weight: 600;
  text-align: center;
  line-height: 25px;
`;

const Compare = styled.div`
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  color: ${({ compare }) =>
    compare === 'NEW'
      ? '#FF9900'
      : compare < 0
      ? '#106EFC'
      : compare > 0
      ? '#F23985'
      : '#2B3674'};
`;

export default Rank;
