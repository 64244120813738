import React, { useRef } from 'react';
import styled from 'styled-components';
import { ItemTabs } from 'components/blocks';
import { useUser } from 'app/user';
import EmptyLink from 'components/templates/layout/EmptyLink';
import Item from './Item';
import Rank from './Rank';

const ItemMain = () => {
  const { user } = useUser();
  const view = useRef();

  return (
    <Container ref={view}>
      <Category01>데이터 분석 현황</Category01>
      <Category02>상품 분석 현황</Category02>
      {!user?.results?.isAnalyzed ? (
        <EmptyLink />
      ) : (
        <ItemTabs
          tabs={[
            { title: '검색', content: <Item /> },
            { title: '상품 순위 정보', content: <Rank /> },
          ]}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  height: calc(100% - 120px);
  padding-bottom: 20px;
  gap: 30px;
  padding-top: 15px;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Category01 = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #707eae;
  line-height: 20px;
  padding-left: 10px;
`;

const Category02 = styled.div`
  font-size: 30px;
  color: #2b3674;
  font-weight: 800;
  padding-left: 10px;
  margin-bottom: 40px;
  margin-top: -20px;
`;

export default ItemMain;
