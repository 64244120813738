import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'components/atoms';
import { ReactComponent as Before } from 'assets/svg/navigateBefore.svg';
import { ReactComponent as First } from 'assets/svg/navigateFirst.svg';
import { ReactComponent as Next } from 'assets/svg/navigateNext.svg';
import { ReactComponent as Last } from 'assets/svg/navigateLast.svg';

const Pagination = ({
  current = 1,
  total,
  onChange,
  limit = 10,
  range = 10,
}) => {
  const [currentPage, setCurrentPage] = useState();
  const firstPage = 1;
  const lastPage = Math.ceil((total || 0) / (limit > 0 ? limit : 1)) || 1;
  const pageGroup = Math.ceil(currentPage / range);

  const startNavi = pageGroup * range - range + 1;
  const endNavi = pageGroup * range + 1;
  const pages = [];

  const prevEndNavi = startNavi - 1 < firstPage ? firstPage : startNavi - 1;
  const nextStartNavi = endNavi > lastPage ? lastPage : endNavi;

  for (let i = startNavi; i < endNavi; i++) {
    if (i <= lastPage) {
      pages.push(i);
    }
  }

  const hadleClick = (page) => {
    setCurrentPage(page);
    if (onChange) {
      onChange(page);
    }
  };

  useEffect(() => {
    setCurrentPage(current);
  }, [current]);

  return (
    <Container>
      <Ul>
        <li>
          <Navigate
            onClick={() => hadleClick(firstPage)}
            disabled={firstPage === startNavi}
            style={{ cursor: 'pointer' }}
          >
            <First width="18px" />
          </Navigate>
        </li>
        <li>
          <Navigate
            onClick={() => hadleClick(prevEndNavi)}
            disabled={firstPage === startNavi}
            style={{ cursor: 'pointer' }}
          >
            <Before width="18px" />
          </Navigate>
        </li>
        {pages.map((number) => (
          <li key={number}>
            <Button
              outline={currentPage !== number}
              onClick={() => hadleClick(number)}
            >
              {number}
            </Button>
          </li>
        ))}
        <li>
          <Navigate
            onClick={() => hadleClick(nextStartNavi)}
            disabled={lastPage <= pageGroup * range}
            style={{ cursor: 'pointer' }}
          >
            <Next width="18px" />
          </Navigate>
        </li>
        <li>
          <Navigate
            onClick={() => hadleClick(lastPage)}
            disabled={lastPage <= pageGroup * range}
            style={{ cursor: 'pointer' }}
          >
            <Last width="18px" />
          </Navigate>
        </li>
      </Ul>
    </Container>
  );
};

const Container = styled.nav`
  display: flex;
  justify-content: center;
`;

const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  padding: 0;
  margin: 0;
  list-style: none;
`;

const Navigate = styled.button`
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.3rem 0.5rem;
  border-radius: 0.25rem;
  display: inline-block;
  cursor: pointer;
  color: #343a40;
  background-color: transparent;
  border-color: #343a40;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }
`;

Pagination.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  limit: PropTypes.number,
  onChange: PropTypes.func,
  range: PropTypes.number,
  outer: PropTypes.number,
};

export default Pagination;
