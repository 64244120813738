import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup/yup'; // 1.3.7
import * as yup from 'yup';
import { Input, Button, Title, Alert, Message } from 'components/atoms';
import { postAccountCheckPassword } from 'utils/api';
import viewEye from 'assets/images/view.png';
import hiddenEye from 'assets/images/hidden.png';

const schema = yup.object({
  password: yup.string().required(''),
});

const CurrentPassword = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [hidden, setHidden] = useState(true);

  const onSubmit = async (data) => {
    const res = await postAccountCheckPassword({
      password: data.password,
    });
    if (res?.statusCode === 200) {
      navigate('/newpassword');
    } else {
      setErrorMessage(res.message);
    }
  };
  const goToAccount = () => {
    navigate('/account');
  };

  const handleHidden = () => {
    setHidden(!hidden);
  };

  return (
    <Container>
      <Category01>프로필</Category01>
      <Category02>계정 설정</Category02>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title style={{ marginBottom: 50, letterSpacing: '-0.03rem' }}>
          비밀번호 변경
        </Title>
        <PasswordWrapper>
          <SubTitle>현재 비밀번호 *</SubTitle>
          <div style={{ position: 'relative' }}>
            <Input
              width="300px"
              name="password"
              type={hidden ? 'password' : 'text'}
              ref={register}
              placeholder="현재 비밀번호를 입력해주세요."
              variant={errors.password && 'danger'}
              style={{ cursor: 'pointer' }}
            />
            {hidden ? (
              <Icon onClick={handleHidden} src={hiddenEye} alt="" />
            ) : (
              <Icon onClick={handleHidden} src={viewEye} alt="" />
            )}
          </div>
        </PasswordWrapper>
        {errors.password && (
          <Message variant="danger">{errors.password.message}</Message>
        )}
        {errorMessage && (
          <Alert style={{ width: 300 }} variant="danger">
            {errorMessage}
          </Alert>
        )}
        <ButtonWrapper>
          <div />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LoginButton onClick={goToAccount}>취소</LoginButton>
            <Button className="button">다음</Button>
          </div>
        </ButtonWrapper>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  font-size: 0.8rem;
  gap: 30px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;

  input {
    height: 50px;
    padding-left: 15px;
    font-size: 13px;
  }

  .button {
    width: 120px;
    height: 42px;
    margin-left: 20px;
    font-weight: 600;
    font-size: 13px;
    border: none;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    background-color: #4859d1;
    box-shadow: 5px 5px 5px #eee;

    &:hover {
      transform: translateY(-1px);
    }
  }
`;

const LoginButton = styled.div`
  width: 120px;
  height: 42px;
  border: 1px solid #4859d1;
  background-color: #fff;
  color: #4859d1;
  text-align: center;
  padding: 0.375rem 0.75rem;
  line-height: 28px;
  border-radius: 20px;
  display: inline-block;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    transform: translateY(-1px);
  }
`;

const Category01 = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #707eae;
  line-height: 20px;
  padding-left: 10px;
`;

const Category02 = styled.div`
  font-size: 30px;
  color: #2b3674;
  font-weight: 800;
  padding-left: 10px;
  margin-bottom: 40px;
  margin-top: -20px;
`;

const Form = styled.form`
  width: 920px;
  padding: 30px 30px 50px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.08);
`;

const SubTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: #a3aed0;
  margin-bottom: 15px;
`;

const PasswordWrapper = styled.div`
  padding: 0 20px;
`;

const Icon = styled.img`
  position: absolute;
  top: 16.5px;
  left: 260px;
  width: 22px;
  height: 15px;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 70px; ;
`;

export default CurrentPassword;
