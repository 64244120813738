import React from 'react';
import styled from 'styled-components';
import { Select } from 'components/atoms';
import { usePropContext } from 'context/PropContext';
import upperCategory from 'assets/images/upper_category.png';

const SelectSortOption = () => {
  const {
    rules,
    fields,
    handleOption,
    handleDownload,
    activeCompare,
    second,
    handleUpperCategory,
  } = usePropContext();

  const handleOptionRule = (change) => {
    handleOption({ sort_rule: change.target.value });
  };

  const handleOptionField = (change) => {
    handleOption({ sort_field: change.target.value });
  };

  return (
    <Container>
      <Content
        style={{
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        {second && (
          <div>
            <img
              onClick={handleUpperCategory}
              src={upperCategory}
              style={{ width: 106, height: 32, cursor: 'pointer' }}
            />
          </div>
        )}
        <div>
          <Select
            name="sort-rule"
            options={rules}
            onChange={handleOptionRule}
            style={{ width: '100px', cursor: 'pointer' }}
          />
        </div>
        <div>
          <Select
            name="sort-field"
            options={fields}
            onChange={handleOptionField}
            style={{ width: '100px', cursor: 'pointer' }}
          />
        </div>
      </Content>
    </Container>
  );
};

const Container = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 3rem;
  font-size: 0.8rem;
`;

const Content = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export default SelectSortOption;
