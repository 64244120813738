import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Title } from 'components/atoms';
import { PropContextProvider } from 'context/PropContext';
import DataList from 'components/templates/layout/DataList';
import useTable from 'hooks/useTable';
import { getInvoiceList, getInvoiceDownload } from 'utils/api';
import download from 'assets/images/download.png';

const columns = [
  { label: 'Invoice', col: 'invoice' },
  { label: '기간', col: 'period' },
  { label: 'PDF', col: 'pdf' },
];

const InvoiceItem = ({ value }) => {
  const [option, setOption] = useState({
    sort_field: 'CREATED_DATE',
    sort_rule: 'DESC',
    page: 1,
    size: 10,
  });
  const [data, setData] = useState({ totalCnt: 0, items: [] });
  const table = useTable({ columns, initData: data.items });

  useEffect(() => {
    getContents();
  }, [value]);

  const getContents = async () => {
    const res = await getInvoiceList({
      ...option,
      dt: value,
    });
    setData({
      totalCnt: res.results.total,
      items: res.results.items,
    });
  };

  const handleOption = (changeData) => {
    const page = changeData.page ? changeData : 1;
    setOption({ ...option, page, ...changeData });
  };

  const handleDownload = async (path) => {
    const res = await getInvoiceDownload({
      path: path,
    });
    const temp = path.split('/');
    const blob = new Blob([res]);
    const fileObjectUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileObjectUrl;
    link.style.display = 'none';
    link.download = temp[temp.length - 1];
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(fileObjectUrl);
  };

  table.convert.setCol = (item) => {
    const { colIdx } = item;
    if ([0].includes(colIdx)) {
      return <div>{item.rowItem.filename}</div>;
    }
    if ([1].includes(colIdx)) {
      return (
        <div>
          {item.rowItem.dt.slice(0, 4) + '-' + item.rowItem.dt.slice(4, 6)}
        </div>
      );
    }
    if ([2].includes(colIdx)) {
      return (
        <img
          onClick={() => {
            handleDownload(item.rowItem.download_path);
          }}
          src={download}
          alt=""
          style={{
            width: '12px',
            height: '14px',
            cursor: 'pointer',
            border: 'none',
          }}
        />
      );
    }
  };

  table.convert.setColStyle = (item) => {
    const { colIdx } = item;
    if ([0].includes(colIdx)) {
      return {
        width: '50%',
        padding: '20px 0.75rem',
        color: '#2B3674',
        fontWeight: 600,
        textAlign: 'left',
        borderRight: [0].includes(colIdx) ? '1px dashed #A3AED0' : '0px',
      };
    } else if ([1].includes(colIdx)) {
      return {
        width: '30%',
        padding: '20px 0.75rem',
        color: '#2B3674',
        fontWeight: 500,
        textAlign: 'center',
        borderRight: [1].includes(colIdx) ? '1px dashed #A3AED0' : '0px',
      };
    } else {
      return {
        textAlign: 'center',
        color: '#2B3674',
        fontWeight: 500,
      };
    }
  };

  table.convert.setHeadStyle = (item) => {
    const { colIdx } = item;
    if ([0].includes(colIdx)) {
      return {
        textAlign: 'left',
        fontSize: '10pt',
        fontWeight: 500,
        color: '#A3AED0',
        backgroundColor: '#fff',
        border: '0px solid #fff',
      };
    } else {
      return {
        fontSize: '10pt',
        fontWeight: 500,
        color: '#A3AED0',
        backgroundColor: '#fff',
        border: '0px solid #fff',
      };
    }
  };

  return (
    <Container>
      <Section>
        <Title>검색 결과</Title>
        <PropContextProvider
          value={{
            table,
            data,
            option,
            handleOption,
          }}
        >
          <Section style={{ padding: '0px' }}>
            <DataList />
          </Section>
        </PropContextProvider>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
`;

export default InvoiceItem;
