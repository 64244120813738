import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StatBox } from 'components/blocks';

const Stat = ({ items }) => {
  return (
    <Container>
      {items && items.map((el, idx) => <StatBox key={idx} item={el} />)}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
`;

Stat.propTypes = {
  items: PropTypes.instanceOf(Object).isRequired,
};

export default Stat;
