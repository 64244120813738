import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PointLeft, PointRight } from 'components/atoms/icon';

export default function Slider({ children: domList, currentIndex }) {
  const [index, setIndex] = useState(currentIndex);

  useEffect(() => {
    setIndex(currentIndex);
  }, [currentIndex]);

  if (!domList) {
    return null;
  }

  const lastIndex = domList.length - 1;
  const currentDom = domList[index];

  const changeDom = (nextIndex) => {
    setIndex((p) => p + nextIndex);
  };
  return (
    <Container>
      {currentDom}
      <MoveBtn
        className="prev"
        onClick={() => changeDom(-1)}
        disabled={index === 0}
      >
        <PointLeft />
      </MoveBtn>
      <MoveBtn
        className="next"
        onClick={() => changeDom(1)}
        disabled={index === lastIndex}
      >
        <PointRight />
      </MoveBtn>
    </Container>
  );
}

const Container = styled.div`
  /* position: relative; */
`;

const MoveBtn = styled.button`
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: #ffffffd9;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 50%;
  cursor: pointer;

  &.prev {
    left: 5%;
  }

  &.next {
    right: 5%;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &:disabled {
    color: #ffffff40;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: not-allowed;
  }
`;

Slider.propType = {
  children: PropTypes.node,
  currentIndex: PropTypes.number,
};

Slider.defaultProps = {
  children: null,
  currentIndex: 0,
};
