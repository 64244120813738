import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup/yup'; // 1.3.7
import * as yup from 'yup';
import useModal from 'hooks/useModal';
import { Modal } from 'hoc/modal';
import Confirm from 'hoc/modal/Confirm';
import { message } from 'hooks/useMessage';
import { Alert, Message, Input, Button, CheckBox } from 'components/atoms';
import { useUser } from 'app/user';
import viewEye from 'assets/images/view.png';
import hiddenEye from 'assets/images/hidden.png';

const schema = yup.object().shape({
  name: yup.string().required(''),
  company: yup.string().required(''),
  email: yup.string().email('이메일 형식이 올바르지 않습니다.').required(''),
  password: yup.string().min(4, '최소 4자 이상 입력해주세요.').required(''),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], '비밀번호가 서로 일치하지 않습니다.')
    .required(''),
});

const Signup = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const { user, signup, clearUser } = useUser();
  const [check, setCheck] = useState(false);
  const navigate = useNavigate();
  const [hidden01, setHidden01] = useState(true);
  const [hidden02, setHidden02] = useState(true);
  const {
    show: showConfirm,
    openModal: openConfirm,
    closeModal: closeConfirm,
  } = useModal({ autoClose: false });

  const onSubmit = (data) => {
    if (!check) {
      message.info('약관 및 정책에 동의해주세요.');
    } else {
      signup(data);
    }
  };

  const goToLogin = () => {
    clearUser();
    navigate(-1);
  };

  useEffect(() => {
    if (user.signupMessage === 'success') {
      openConfirm();
    }
  }, [user.signupMessage]);

  const handleHidden = () => {
    setHidden01(!hidden01);
  };

  const handleHiddenPassword = () => {
    setHidden02(!hidden02);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title>
          BEREVIEW<span style={{ fontWeight: 300 }}>&nbsp;STUDIO</span>
        </Title>
        <Sub>회원가입 요청</Sub>
        <div>
          <div style={{ display: 'flex' }}>
            <Text>이름</Text>
            <span style={{ color: '#4859D1', fontWeight: 500 }}>&nbsp;*</span>
          </div>
          <Input
            name="name"
            ref={register}
            placeholder="이름을 입력해주세요."
            variant={errors.name && 'danger'}
            style={{ cursor: 'pointer' }}
          />
          {errors.name && (
            <Message variant="danger">{errors.name.message}</Message>
          )}
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <Text>회사명</Text>
            <span style={{ color: '#4859D1', fontWeight: 500 }}>&nbsp;*</span>
          </div>
          <Input
            name="company"
            ref={register}
            placeholder="회사명을 입력해주세요."
            variant={errors.company && 'danger'}
            style={{ cursor: 'pointer' }}
          />
          {errors.name && (
            <Message variant="danger">{errors.company.message}</Message>
          )}
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <Text>회사 계정 이메일</Text>
            <span style={{ color: '#4859D1', fontWeight: 500 }}>&nbsp;*</span>
          </div>
          <Input
            name="email"
            ref={register}
            placeholder="회사 계정 이메일을 입력해주세요."
            variant={errors.email && 'danger'}
            style={{ cursor: 'pointer' }}
          />
          {errors.email && (
            <Message variant="danger">{errors.email.message}</Message>
          )}
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <Text>비밀번호</Text>
            <span style={{ color: '#4859D1', fontWeight: 500 }}>&nbsp;*</span>
          </div>
          <div style={{ position: 'relative' }}>
            <Input
              name="password"
              ref={register}
              placeholder="최소 4자 이상 입력해주세요."
              type={hidden01 ? 'password' : 'text'}
              variant={errors.password && 'danger'}
              style={{ cursor: 'pointer' }}
            />
            {hidden01 ? (
              <Icon onClick={handleHidden} src={hiddenEye} alt="" />
            ) : (
              <Icon onClick={handleHidden} src={viewEye} alt="" />
            )}
          </div>
          {errors.password && (
            <Message variant="danger">{errors.password.message}</Message>
          )}
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <Text>비밀번호 확인</Text>
            <span style={{ color: '#4859D1', fontWeight: 500 }}>&nbsp;*</span>
          </div>
          <div style={{ position: 'relative' }}>
            <Input
              name="confirmPassword"
              ref={register}
              placeholder="비밀번호를 다시 한번 입력해주세요."
              type={hidden02 ? 'password' : 'text'}
              variant={errors.confirmPassword && 'danger'}
              style={{ cursor: 'pointer' }}
            />
            {hidden02 ? (
              <Icon onClick={handleHiddenPassword} src={hiddenEye} alt="" />
            ) : (
              <Icon onClick={handleHiddenPassword} src={viewEye} alt="" />
            )}
          </div>
          {errors.confirmPassword && (
            <Message variant="danger">{errors.confirmPassword.message}</Message>
          )}
        </div>

        <Modal show={showConfirm}>
          <Confirm
            onClose={closeConfirm}
            success="확인"
            onOk={goToLogin}
            type="check"
            title="회원가입 요청 완료"
          >
            요청일로부터 1-2일(영업일 기준) 이내에 검수가 진행되며, 승인 완료 시
            서비스 가 활성화됩니다. <br />
            (검수 상황에 따라 승인 일정은 연장될 수 있습니다.)
          </Confirm>
        </Modal>
        {user.status && user.status !== '성공' && (
          <Alert variant="danger">{user.status}</Alert>
        )}
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
          <CheckBox
            style={{ marginLeft: 20, cursor: 'pointer' }}
            onClick={() => setCheck(!check)}
          />
          <div style={{ marginLeft: 15 }}>
            <Content>
              서비스 이용약관 및 개인정보 처리방침에 동의합니다.
            </Content>
            <div style={{ display: 'flex' }}>
              <Agree
                href="https://docs.google.com/document/u/1/d/e/2PACX-1vTEJ2QZ_EZW-xOmJgu2tS_VfglFzWYqoseV99-Qg7_Khh55UNE-rm0LC1s507LDZuYWLNSgSyJwUbm2/pub"
                target="_blank"
              >
                서비스 이용약관
              </Agree>
              <div style={{ color: '#5e646d', height: '10px' }}>
                &nbsp;|&nbsp;
              </div>
              <Agree
                href="https://docs.google.com/document/u/1/d/e/2PACX-1vRnuGlPVAzGxKpBRXFaP719As8h7ovSVf7cHf9Kx85wXgicP7D8khen5ygqZS6zBBjwunpKV5KzvQIE/pub"
                target="_blank"
              >
                개인정보 처리방침
              </Agree>
            </div>
          </div>
        </div>
        <ButtonWrapper>
          <LoginButton onClick={goToLogin}>이전</LoginButton>
          <Button>회원가입 요청</Button>
        </ButtonWrapper>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  width: 35rem;
  margin: auto;
  padding-top: 4rem;
  line-height: 1.5;
  font-size: 0.8rem;

  input {
    height: 50px;
    padding-left: 15px;
    font-size: 13px;
  }

  button {
    height: 50px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 12.8;
    margin-top: 20px;

    &:hover {
      transform: translateY(-1px);
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 40px;
  background-color: #fff;
  padding: 60px 40px;
  border-radius: 21px;
  box-shadow: 5px 5px 5px #eee;
`;

const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
`;

const Sub = styled.div`
  margin-top: -15px;
  margin-bottom: 36px;
  font-weight: 500;
  text-align: center;
  font-size: 14.5px;
  line-height: 22px;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  letter-spacing: -0.03rem;
`;

const Content = styled.div`
  margin: 0 auto;
  font-size: 13px;
  font-weight: 600;
`;

const Icon = styled.img`
  position: absolute;
  top: 18px;
  right: 15px;
  width: 19px;
  cursor: pointer;
`;

const Agree = styled.a`
  font-size: 11px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: -0.03rem;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  align-items: center;
`;

const LoginButton = styled.div`
  margin-top: 20px;
  height: 50px;
  border: 1px solid #4859d1;
  background-color: #fff;
  color: #4859d1;
  text-align: center;
  padding: 0.375rem 0.75rem;
  line-height: 33px;
  border-radius: 10px;
  display: inline-block;
  font-size: 12.8px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    transform: translateY(-1px);
  }
`;

export default Signup;
