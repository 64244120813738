import { useState, useEffect } from 'react';

const useTable = ({ columns, initData }) => {
  const [headProp, setHeadProp] = useState([]);
  const [accessor, setAccessor] = useState(
    columns?.map((el) => ({ col: el.col || '' })),
  );
  const [bodyProp, setBodyProp] = useState([]);

  const setTableHead = (data) => {
    setHeadProp(
      data?.map((el, idx) => ({
        [el.col]: el.label,
        id: el.id || idx,
        label: el.label || '',
        index: idx,
        colIdx: idx,
        key: el.col,
        len: el.length || 1,
      })),
    );
    setAccessor(
      data?.map((el) => ({
        col: el.col || '',
      })),
    );
  };

  const setTableBody = (data) => {
    setBodyProp(
      data?.map((item, ridx) => {
        const ceil = [];
        accessor.forEach((column, cidx) => {
          ceil.push({
            [column.col]: item,
            rowItem: item,
            index: cidx,
            rowIdx: ridx,
            colIdx: cidx,
            colName: column.col || '',
            value: item[column.col] || '',
          });
        });
        return ceil;
      }) || [],
    );
  };

  useEffect(() => {
    setTableHead(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  useEffect(() => {
    setTableBody(initData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData]);

  const convert = {
    setHeadTitle: (item) => item,
    getHeadTitle: (item) => convert.setHeadTitle(item) || null,
    setHead: (item) => item.label,
    getHead: (item) => convert.setHead(item) || item.label,
    setHeadStyle: (item) => item,
    getHeadStyle: (item) => convert.setHeadStyle(item) || {},
    setRowSpan: (item) => item,
    getRowSpan: (item) =>
      Number.isNaN(convert.setRowSpan(item)) ? 1 : convert.setRowSpan(item),
    setCol: (item) => item.value,
    getCol: (item) => convert.setCol(item) || item.value,
    setColStyle: (item) => item,
    getColStyle: (item) => convert.setColStyle(item) || {},
    setMaxHeight: '',
    getMaxHeight: () => convert.setMaxHeight,
  };

  return { headProp, bodyProp, convert, setTableHead, setTableBody };
};

export default useTable;
