import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Spin = React.forwardRef(({ props }, ref) => {
  return (
    <Container {...props} ref={ref}>
      <p className="loader"></p>
    </Container>
  );
});

const Container = styled.div`
  display: inline-block;
  z-index: 999;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loader {
    position: relative;
    width: 12px;
    height: 12px;

    top: 46%;
    top: -webkit-calc(50% - 6px);
    top: calc(50% - 6px);
    left: 46%;
    left: -webkit-calc(50% - 6px);
    left: calc(50% - 6px);

    border-radius: 12px;
    background-color: #fff;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-animation: loader 1.5s ease-in-out infinite;
    animation: loader 1.5s ease-in-out infinite;
  }

  .loader:before {
    content: '';
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    top: 0px;
    left: -25px;
    height: 12px;
    width: 12px;
    border-radius: 12px;
  }

  .loader:after {
    content: '';
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    top: 0px;
    left: 25px;
    height: 12px;
    width: 12px;
    border-radius: 12px;
  }

  @-webkit-keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(180deg);
    }
  }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
`;

Spin.propTypes = {
  color: PropTypes.string,
};

Spin.defaultProps = {
  color: null,
};

export default Spin;
