import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'modal',
  initialState: {
    count: 0,
  },
  reducers: {
    addModal: (state, { payload }) => {
      state.count += 1;
    },
    delModal: (state) => {
      state.count -= 1;
    },
  },
});

export const { addModal, delModal } = slice.actions;

export default slice.reducer;
