import React from 'react';
import styled from 'styled-components';

const StatInfo = ({ show }) => {
  const getDateWithFormat = (second) => {
    let startDate = new Date();
    let endDate = new Date();
    if (show === 'week') {
      startDate.setDate(startDate.getDate() - (!second ? 8 : 16));
      endDate.setDate(endDate.getDate() - (!second ? 1 : 9));
    } else if (show === 'month') {
      startDate.setDate(startDate.getDate() - (!second ? 31 : 62));
      endDate.setDate(endDate.getDate() - (!second ? 1 : 32));
    }
    const fYY = startDate.getFullYear().toString().substring(2, 4);
    const fMM = startDate.getMonth() + 1;
    const fDD = startDate.getDate();
    const eYY = endDate.getFullYear().toString().substring(2, 4);
    const eMM = endDate.getMonth() + 1;
    const eDD = endDate.getDate();
    return (
      fYY +
      '.' +
      (fMM < 10 ? '0' : '') +
      fMM +
      '.' +
      (fDD < 10 ? '0' : '') +
      fDD +
      ' ~ ' +
      eYY +
      '.' +
      (eMM < 10 ? '0' : '') +
      eMM +
      '.' +
      (eDD < 10 ? '0' : '') +
      eDD
    );
  };

  return (
    <Container>
      {show === 'week' && (
        <Contents>
          <Title>동기간 비교</Title>
          <br />
          최근 7일({getDateWithFormat()})과
          <br />
          이전 7일({getDateWithFormat('second')}) 간을 서로 비교한 값입니다.
        </Contents>
      )}

      {show === 'month' && (
        <Contents>
          <Title>동기간 비교</Title>
          <br />
          최근 30일({getDateWithFormat()})과
          <br />
          이전 30일({getDateWithFormat('second')}) 간을 서로 비교한 값입니다.
        </Contents>
      )}
    </Container>
  );
};

const Container = styled.div`
  text-align: left;
  font-size: 0.8rem;
  position: absolute;
  top: 80px;
  right: 100px;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 10px 10px 5px 2px rgba(43, 54, 116, 0.45);
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #2b3674;
  margin-bottom: 10px;
`;

const Contents = styled.div`
  font-size: 14px;
  line-height: 15px;
  letter-spacing: -0.3px;
  color: #2b3674;
`;

export default StatInfo;
