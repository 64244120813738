import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

function ItemTabs({ defaultTab, tabs, onChange }) {
  const [currentIdx, setCurrentIdx] = useState();

  const clickTab = (idx) => {
    setCurrentIdx(idx);
    if (onChange) {
      onChange(idx);
    }
  };

  useEffect(() => {
    setCurrentIdx(defaultTab);
  }, [defaultTab]);

  return (
    <Container>
      <Header>
        {tabs.map((tab, idx) => (
          <Tab
            key={idx}
            active={currentIdx === idx}
            onClick={() => clickTab(idx)}
            disabled={tab.disabled}
          >
            {tab.title}
          </Tab>
        ))}
      </Header>
      <Body>{tabs[currentIdx]?.content}</Body>
    </Container>
  );
}

const Container = styled.div``;

const Header = styled.div`
  background-color: #fff;
  display: flex;
  margin-bottom: 20px;
  width: 230px;
  border-radius: 5px;
  padding: 7px;
`;

const Tab = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  color: #a7c0f7;
  width: 110px;
  font-weight: 700;
  font-size: 13px;
  height: 35px;
  line-height: 30px;
  background-color: white;
  border-radius: 5px;

  ${({ active }) =>
    active &&
    css`
      color: #f9fafb;
      background-color: #2563eb;
      border-radius: 5px;
    `};

  &:disabled {
    cursor: not-allowed;
  }
`;

const Body = styled.div`
  /* background-color: blue; */
  border-radius: 16px;
`;

ItemTabs.propTypes = {
  defaultTab: PropTypes.number,
  tabs: PropTypes.instanceOf(Array),
  children: PropTypes.node,
  onChange: PropTypes.func,
};

ItemTabs.defaultProps = {
  defaultTab: 0,
  tabs: [],
  children: null,
  onChange: null,
};

export default ItemTabs;
