import React from 'react';
import { NavLink, useMatch, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Accordion } from 'components/atoms';
import { menuList } from 'utils/constants';
import foreignImage from 'assets/images/foreign.png';
import link from 'assets/images/link.png';

const Match = (path) => {
  const match = useMatch(path);
  return match;
};

const SideMenu = () => {
  const navigate = useNavigate();
  const Menu = (menu, parentName) => {
    let menuTag = <div>empty</div>;
    if (menu?.child?.length > 0) {
      const links = [];
      menu.child.forEach((obj) => {
        links.push(Menu(obj, menu.name));
      });

      let style;
      if (menu?.link) {
        menu.link.forEach((matchLink) => {
          const match = Match(matchLink);
          if (match) {
            style = { color: '#4859D1' };
          }
        });
      }

      menuTag = (
        <div className="menu-wrapper" key={menu.name} style={style}>
          <Accordion
            key={menu.name}
            title={parentName ? '' : menu.name}
            header={parentName ? `- ${menu.name}` : ''}
            defaultActive={true}
          >
            <div className="menu-link-wrapper">{links}</div>
          </Accordion>
        </div>
      );
    } else {
      menuTag = menu.external ? (
        <a href={menu.link} key={menu.name} target="_blank" rel="noreferrer">
          {menu.name}
          <img
            src={foreignImage}
            width={12}
            style={{ marginLeft: '10px' }}
            alt=""
          />
        </a>
      ) : (
        <NavLink to={menu.link} key={menu.name}>
          {menu.name}
        </NavLink>
      );
    }
    return menuTag;
  };

  const menuTagList = [];
  menuList.forEach((menu) => {
    menuTagList.push(Menu(menu, null));
  });

  const goToHome = () => {
    navigate('/');
  };

  return (
    <Wrapper>
      <Container>
        <Brand onClick={goToHome}>
          BEREVIEW<span style={{ fontWeight: 400 }}>&nbsp;STUDIO</span>
        </Brand>
        {menuTagList}
      </Container>
      <FAQ>
        <Help>
          <Title>도움말</Title>
          <SubTitle
            onClick={() =>
              window.open(`https://docs.bereview.co.kr/category/faq`)
            }
          >
            FAQ
            <Img src={link} alt="" />
          </SubTitle>
        </Help>
      </FAQ>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 220px;
  height: 100%;
  background-color: #fff;
  position: fixed;
`;

const Container = styled.div`
  width: 220px;
  height: calc(100% - 170px);
  background-color: #fff;
  line-height: normal;
  z-index: 50;
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  .menu-wrapper {
    border-bottom: 1px solid #f7f7f7;
    white-space: nowrap;
    font-weight: 700;
  }

  .menu-link-wrapper {
    display: flex;
    flex-direction: column;

    .active {
      font-weight: 800;
      background-color: #f4f7fe;
      color: #4859d1;
    }
  }

  a {
    padding: 15px 0px 15px 50px;
    font-size: 13px;
    color: black;
    font-weight: 400;
  }
`;

const FAQ = styled.div`
  z-index: 50;
  background-color: #fff;
  height: 170px;
  padding: 30px 15px;
  width: 100%;
`;

const Brand = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #2b3674;
  line-height: 50px;
  padding: 0 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const Help = styled.div`
  border-radius: 16px;
  background-color: #f4f7fe;
  box-shadow: 5px 5px 5px #eee;
  padding: 25px 30px;
  color: #2b3674;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
`;

const SubTitle = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: 500;
  text-decoration: underline;
  align-items: center;
  cursor: pointer;
`;

const Img = styled.img`
  width: 14px;
  height: 14px;
`;

SideMenu.propTypes = {
  onReady: PropTypes.bool,
};

SideMenu.defaultPropTypes = {
  onReady: false,
};

export default SideMenu;
