import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Title } from 'components/atoms';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'app/user';
import { getAccountUser } from 'utils/api';
import name from 'assets/images/name.png';
import briefcase from 'assets/images/briefcase.png';
import mail from 'assets/images/mail.png';
import lock from 'assets/images/lock.png';

const Account = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [info, setInfo] = useState({
    name: '',
    company: '',
    email: '',
  });

  useEffect(() => {
    getContents();
  }, [user, navigate]);

  const getContents = async () => {
    const res = await getAccountUser();
    setInfo({
      name: res?.results?.name,
      company: res?.results?.company,
      email: res?.results?.email,
    });
  };

  const changePassword = () => {
    navigate('/currentpassword');
  };

  return (
    <Container>
      <Category01>프로필</Category01>
      <Category02>계정 설정</Category02>
      <Section>
        <Title style={{ marginBottom: 20 }}>계정 정보</Title>
        <ContentWrapper>
          <div>
            <SubTitle>
              <Img src={name} alt="" />
              이름
            </SubTitle>
            <Input readOnly name="name" value={info.name} />
          </div>
          <div>
            <SubTitle>
              <Img src={briefcase} alt="" />
              회사
            </SubTitle>
            <Input readOnly name="company" value={info.company} width="300px" />
          </div>
          <div>
            <SubTitle>
              <Img src={mail} alt="" />
              이메일
            </SubTitle>
            <Input readOnly name="email" value={info.email} width="300px" />
          </div>
          <div>
            <SubTitle>
              <Img src={lock} alt="" />
              비밀번호
            </SubTitle>
            <PasswordAlign>
              <PasswordInput
                readOnly
                name="password"
                type="password"
                value="**********"
              />
              <Button className="button" onClick={changePassword}>
                변경
              </Button>
            </PasswordAlign>
          </div>
        </ContentWrapper>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 15px;

  .button {
    margin-left: 20px;
    font-weight: 600;
    font-size: 13px;
    border: none;
    border-radius: 20px;
    color: #2b3674;
    cursor: pointer;
    width: 78px;
    height: 32px;
    background-color: #f4f7fe;
    box-shadow: 5px 5px 5px #eee;

    &:hover {
      transform: translateY(-1px);
    }
  }
`;

const Category01 = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #707eae;
  line-height: 20px;
  padding-left: 10px;
`;

const Category02 = styled.div`
  font-size: 30px;
  color: #2b3674;
  font-weight: 800;
  padding-left: 10px;
  margin-bottom: 40px;
  margin-top: -20px;
`;

const Section = styled.section`
  width: 920px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 30px 50px 30px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.08);
`;

const Input = styled.input`
  width: 311px;
  height: 50px;
  padding: 8px 15px;
  border-radius: 6px;
  border: 1px solid #f0f2fb;
  background-color: #f0f2fb;
  color: #2b3674;
  font-size: 14px;
  font-weight: 500;
`;

const PasswordInput = styled.input`
  width: 311px;
  height: 50px;
  box-shadow: 5px 5px 5px #eee;
  padding: 8px 20px;
  letter-spacing: 1.5px;
  font-size: 15px;
  border-radius: 6px;
  border: 1px solid #e4e4e4;
  color: #2b3674;
`;

const SubTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: #a3aed0;
  margin-bottom: 15px;
`;

const ContentWrapper = styled.div`
  padding: 0 20px;
  gap: 30px;
  display: flex;
  flex-direction: column;
`;

const Img = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 8px;
`;

const PasswordAlign = styled.div`
  display: flex;
  align-items: center;
`;

export default Account;
