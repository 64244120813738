import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input, Select } from 'components/atoms';
import { usePropContext } from 'context/PropContext';
import search from 'assets/images/search.png';

const SearchItemForm = ({ options, setSelect }) => {
  const { handleOption, handleSubmit } = usePropContext();
  const [keyword, setKeyword] = useState('');
  const [selectValue, setSelectValue] = useState(options[0].id);

  useEffect(() => {
    setSelect(selectValue);
  }, [selectValue]);

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault();
        handleOption(
          selectValue === 'name'
            ? { title: keyword, item_code: '' }
            : { item_code: keyword, title: '' },
        );
      }}
    >
      <Section>
        <div>
          <SubTitle>선택</SubTitle>
          <Select
            options={options}
            selectSize={100}
            setSelectValue={setSelectValue}
            type="keyword"
            name="itemSearch"
            style={{
              cursor: 'pointer',
              width: '250px',
            }}
          />
        </div>
        <div style={{ marginLeft: '20px' }}>
          <SubTitle>키워드</SubTitle>
          <Content
            style={{
              alignItems: 'flex-start',
              flexDirection: 'row',
            }}
          >
            <Input
              name="keyword"
              value={keyword}
              width="350px"
              placeholder="상품명 또는 상품코드를 입력해주세요."
              onChange={(e) => setKeyword(e.target.value)}
              style={{
                fontSize: '0.8rem',
                cursor: 'pointer',
                height: '43px',
                paddingLeft: '16px',
                border: '1px solid #E4E4E4',
              }}
            />
          </Content>
        </div>
      </Section>
      <Content>
        <Button />
      </Content>
    </Container>
  );
};

const Container = styled.form`
  display: flex;
  flex-direction: row;
  gap: 1.8rem;
  font-size: 0.8rem;
  padding: 10px;
`;

const Button = styled.button`
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background-image: url(${search});
  background-size: 78px 32px;
  width: 78px;
  height: 32px;
  background-repeat: no-repeat;
  margin-top: 20px;
  box-shadow: 5px 5px 5px #eee;
  &:hover {
    transform: translateY(-1px);
  }
`;

const Section = styled.div`
  display: flex;
`;

const SubTitle = styled.div`
  font-size: 12px;
  color: #a3aed0;
  margin-bottom: 5px;
`;

const Content = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export default SearchItemForm;
