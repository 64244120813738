import React from 'react';
import styled from 'styled-components';
import emptyImage from 'assets/images/no_image.png';
import { Box, Label, Button } from 'components/atoms';
import upperList from 'assets/images/upper_list.png';

const ItemStat = ({ itemStat, type, handleShowDetail }) => {
  return (
    <Wrapper>
      <ImgWrapper>
        {itemStat?.image !== '' ? (
          <ItemImg src={itemStat?.image} alt="image" />
        ) : (
          <EmptyImg src={emptyImage} />
        )}
      </ImgWrapper>
      <Box className="box">
        <Container>
          <ContentsWrapper>
            <Section>
              <Label className="label">카테고리</Label>
              <Content
                style={{
                  marginLeft: '40px',
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                }}
              >
                <Label>{itemStat.category}</Label>
              </Content>
            </Section>
            {type === 'button' && (
              <img
                src={upperList}
                className="button"
                onClick={() => handleShowDetail(false)}
                style={{ width: 106, height: 32, cursor: 'pointer' }}
              />
            )}
            <Section>
              <Label className="label">상품명</Label>
              <Content
                style={{
                  marginLeft: '40px',
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                }}
              >
                <Label>{itemStat.title}</Label>
              </Content>
            </Section>
            <Section>
              <Label className="label">상품코드</Label>
              <Content
                style={{
                  marginLeft: '40px',
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                }}
              >
                <Label>{itemStat.itemCode}</Label>
              </Content>
            </Section>
            <Section>
              <Label className="label">상품등록일</Label>
              <Content
                style={{
                  marginLeft: '40px',
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                }}
              >
                <Label>
                  {itemStat.date.slice(0, 4) +
                    '-' +
                    itemStat.date.slice(4, 6) +
                    '-' +
                    itemStat.date.slice(6, 8)}
                </Label>
              </Content>
            </Section>

            <Section>
              <Label className="label">URL</Label>
              <Content
                style={{
                  marginLeft: '40px',
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                }}
              >
                {itemStat?.url ? (
                  <Label
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    <a href={itemStat?.url} target="_blank" rel="noreferrer">
                      {itemStat?.url}
                    </a>
                  </Label>
                ) : (
                  <div style={{ lineHeight: '33px' }}>-</div>
                )}
              </Content>
            </Section>
          </ContentsWrapper>
        </Container>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;

  .box {
    max-height: 200px;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    background-color: #fff;
  }
`;

const Container = styled.form`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  gap: 30px;

  .label {
    color: #4859d1;
    font-weight: 600;
    min-width: 80px;
  }
`;

const ImgWrapper = styled.div`
  min-width: 200px;
  height: 200px;
  background-color: #fff;
  margin-right: 20px;
  border-radius: 20px;
  display: flex;
  box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.05);
`;

const ItemImg = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 20px;
  object-fit: cover;
`;

const EmptyImg = styled.img`
  width: 80px;
  height: 80px;
  margin: auto;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
`;

const ContentsWrapper = styled.div`
  width: 100%;
  position: relative;
  .button {
    position: absolute;
    top: 0px;
    right: 10px;
  }
`;

const Content = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export default ItemStat;
