import React from 'react';
import styled from 'styled-components';
import Header from 'components/templates/layout/Header';
import SideMenu from 'components/templates/layout/SideMenu';
import { Outlet } from 'react-router-dom';

const PrivateLayout = () => {
  return (
    <Container>
      <Header />
      <Main>
        <Side>
          <SideMenu />
        </Side>
        <Content>
          <Outlet />
        </Content>
      </Main>
    </Container>
  );
};

const Container = styled.div`
  background-color: #f4f7fe;
  position: relative;
`;

const Main = styled.div`
  display: flex;
`;

const Side = styled.div`
  // padding: 10px 0;
  background-color: white;
  overflow: auto;
  min-height: calc(100vh);
  min-width: 216.83px;
`;

const Content = styled.div`
  position: relative;
  width: calc(100% - 200px);
  padding: 20px 35px;
`;
export default React.memo(PrivateLayout);
