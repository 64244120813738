import React from 'react';
import styled from 'styled-components';
import empty from 'assets/images/alert_rank.png';

const EmptyData = ({ type }) => {
  return (
    <Container type={type}>
      <img src={empty} style={{ width: '30px' }} alt="" />
      <Message>해당 데이터가 없습니다.</Message>
    </Container>
  );
};

const Container = styled.footer`
  width: 100%;
  padding: 30px 0;
  background-color: #fff;
  border-radius: 16px;
  min-height: 220px;
  text-align: center;
  line-height: 200%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ type }) => (type === 'none' ? 'none' : '5px 5px 5px #eee')};
`;

const Message = styled.div`
  font-size: 15px;
  font-weight: 700;
  margin-top: 20px;
  color: #a3aed0;
`;

export default EmptyData;
