import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from 'components/atoms';

const StatusBox = ({ status, count, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Box>
        <Title>{status}</Title>
        <Text>{count.toLocaleString()} 건</Text>
      </Box>
    </Container>
  );
};

const Container = styled.div`
  min-width: 8rem;
  font-size: 0.8rem;
`;

const Title = styled.div`
  font-weight: 400;
  margin-bottom: 0.75rem;
`;

const Text = styled.div`
  font-weight: 700;
  font-size: 1.1rem;
`;

StatusBox.propTypes = {
  status: PropTypes.string,
  count: PropTypes.number,
  onClick: PropTypes.func,
};

StatusBox.defaultProps = {
  status: '-',
  count: 0,
  onClick: () => {},
};

export default StatusBox;
