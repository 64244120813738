import { useD3 } from 'hooks/useD3';
import React from 'react';
import * as d3 from 'd3';

function ZoomableSunBurstChart({
  contents,
  width = 450,
  height = 115,
  radius = width / 6,
  format = d3.format(' ,d'),
  color = ['red', 'blue'],
  handleClickCategory,
}) {
  const data = {
    name: '',
    children: [
      {
        name: '긍정',
        value: contents.tot_pos_sent_cnt,
      },
      {
        name: '부정',
        value: contents.tot_neg_sent_cnt,
      },
    ],
  };
  const ref = useD3(
    (svg) => {
      const partition = (data) => {
        const root = d3
          .hierarchy(data)
          .sum((d) => d.value)
          .sort((a, b) => b.value - a.value);
        return d3.partition().size([2 * Math.PI, root.height + 1])(root);
      };
      const arc = d3
        .arc()
        .startAngle((d) => d.x0)
        .endAngle((d) => d.x1)
        .innerRadius(0)
        .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));
      const root = partition(data);
      root.each((d) => (d.current = d));
      svg
        .attr('viewBox', [0, 0, width, width * 0.75])
        .style('font', '10px sans-serif');
      const g = svg
        .append('g')
        .attr('transform', `translate(${width / 2},${(width * 0.75) / 2})`);
      const path = g
        .append('g')
        .selectAll('path')
        .data(root.descendants().slice(1))
        .join('path')
        .attr('fill', (d) => {
          while (d.depth > 1) d = d.parent;
          return d.data.name === '긍정'
            ? 'rgb(16, 110, 252)'
            : 'rgb(248, 105, 148)';
        })
        .attr('d', (d) => arc(d.current));
    },
    [data.children.length],
  );

  return (
    <svg
      ref={ref}
      style={{
        height: height,
        width: width,
        marginRight: '0px',
        marginLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
        paddingTop: '0px',
      }}
    />
  );
}

export default ZoomableSunBurstChart;
