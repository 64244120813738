import React, { useState } from 'react';
import styled from 'styled-components';
import { Tr } from 'components/atoms';
import { DataTable } from 'components/blocks';
import { usePropContext } from 'context/PropContext';
import { useNavigate } from 'react-router-dom';

const CategoryList = () => {
  const navigate = useNavigate();
  const { table, category, handleSelectCategory, select } = usePropContext();

  const [categoryOption, setCategoryOption] = useState(null);

  const handleMoveCategory = (sp_category1, sp_category2) => {
    navigate('/analysis/category', {
      state: {
        sp_category1: sp_category1,
        sp_category2: sp_category2,
      },
    });
  };

  return (
    <Container>
      <DataTable
        border="none"
        head={
          <Tr style={{ border: 'none' }}>
            {table?.headProp?.map((thead) => {
              return (
                <th key={thead.id} style={table?.convert.getHeadStyle(thead)}>
                  {table?.convert.getHead(thead)}
                </th>
              );
            })}
          </Tr>
        }
        body={
          table?.bodyProp && table.bodyProp?.length > 0 ? (
            table?.bodyProp?.map((row, ridx) => {
              return (
                <>
                  <tr>
                    {row.map((col, cidx) => (
                      <td
                        onClick={() => handleSelectCategory(col)}
                        key={cidx.toString()}
                        rowSpan={table?.convert.getRowSpan(col)}
                        style={table?.convert.getRowSpan(col)}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          {cidx === 0 && col.value !== select.category && (
                            <div>►</div>
                          )}
                          {cidx === 0 && col.value === select.category && (
                            <div>▽</div>
                          )}
                          <div style={table?.convert.getColStyle(col)}>
                            &nbsp;&nbsp;{table?.convert.getCol(col)}
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
                  {row[0].value === select.category && (
                    <td colSpan={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <CategoryWrapper>
                        {category?.items?.map((item, idx) => {
                          return (
                            <CategoryCard key={idx}>
                              <CategoryTitle
                                onClick={() =>
                                  handleMoveCategory(
                                    item.sp_category1,
                                    item.sp_category2,
                                  )
                                }
                              >
                                {item.sp_category2}
                              </CategoryTitle>
                              <CategoryContent>
                                상품 수 :{' '}
                                {item.item_cnt.toLocaleString() + '개'}
                              </CategoryContent>
                              <CategoryContent>
                                리뷰 수 :{' '}
                                {item.review_cnt.toLocaleString() + '건'}
                              </CategoryContent>
                            </CategoryCard>
                          );
                        })}
                      </CategoryWrapper>
                    </td>
                  )}
                </>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={table?.headProp?.length}
                style={{ textAlign: 'center' }}
              >
                해당하는 데이터가 없습니다.
              </td>
            </tr>
          )
        }
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.8rem;
`;

const CategoryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 10px 30px 20px;
  background-color: #f8f8fd;
  margin-top: 10px;
  border-radius: 0 0 10px 10px;
`;

const CategoryCard = styled.div`
  width: 18rem;
  height: 120px;
  border-radius: 5px;
  background-color: #fff;
  padding: 20px;
  border: 0.7px solid #c6c6c6;
`;

const CategoryTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 16px;
  cursor: pointer;
`;

const CategoryContent = styled.div`
  font-size: 13px;
  color: #646464;
  line-height: 18px;
  letter-spacing: -0.3;
`;

export default CategoryList;
