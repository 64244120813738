import axios from 'axios';
import { apis } from 'utils/constants';

const getHeaders = async () => {
  if (!localStorage.getItem('token')) {
    const startIndex = window.location.href.lastIndexOf('/') + 1;
    const token = window.location.href.slice(startIndex);
    return {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${token}`,
    };
  } else {
    const token = await localStorage.getItem('token');
    return {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${token}`,
    };
  }
};

const getQueryParams = (params) => {
  let ret = [];
  for (const k in params) {
    if (params[k]) {
      ret.push(`${k}=${encodeURIComponent(params[k])}`);
    }
  }
  return ret.join('&');
};

const doRequest = async (requestParams) => {
  /* eslint-disable-next-line no-console */
  console.log(JSON.stringify(requestParams, null, 2));

  let { method, url, params, contentType, responseType } = requestParams;

  let ret = null;
  try {
    if (method === 'get') {
      if (responseType === 'blob') {
        ret = await axios.get(`${url}?${getQueryParams(params)}`, {
          withCredential: true,
          headers: await getHeaders(),
          responseType: 'blob',
        });
      } else {
        ret = await axios.get(`${url}?${getQueryParams(params)}`, {
          withCredential: true,
          headers: await getHeaders(),
        });
      }
    } else if (method === 'post') {
      if (contentType === 'multipart/form-data') {
        const headers = await getHeaders();
        ret = await axios.post(url, params, {
          withCredential: true,
          headers: {
            ...headers,
            'Content-Type': 'multipart/form-data',
            accept: 'application/json',
          },
        });
      } else {
        ret = await axios.post(url, params, {
          withCredential: true,
          headers: await getHeaders(),
        });
      }
    } else if (method === 'put') {
      ret = await axios.put(url, params, {
        withCredential: true,
        headers: await getHeaders(),
      });
    } else if (method === 'del') {
      ret = await axios.delete(url, {
        withCredential: true,
        headers: await getHeaders(),
        params,
      });
    } else {
      throw new Error(`Not Implemented`);
    }
    /* eslint-disable-next-line no-console */
    console.log(ret.data);
    if (ret.data.statusCode === 401) {
      localStorage.clear();
      window.open(`${apis.url}/login`, '_self');
    }
    return ret.data;
  } catch (e) {
    /* eslint-disable-next-line no-console */
    console.log(JSON.stringify(requestParams, null, 2));
    /* eslint-disable-next-line no-console */
    console.log(JSON.stringify(e.response?.data || {}, null, 2));
    throw e;
  }
};

const postAccountSignup = async (params) => {
  return await doRequest({
    method: `post`,
    url: `${apis.account.signup}`,
    params: params,
  });
};

const postAccountSignin = async (params) => {
  return await doRequest({
    method: `post`,
    url: `${apis.account.signin}`,
    params: params,
  });
};

const getAccountUser = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.account.user}`,
    params: params,
  });
};

const postAccountCheckPassword = async (params) => {
  return await doRequest({
    method: `post`,
    url: `${apis.account.password.check}`,
    params: params,
  });
};

const postAccountUpdatePassword = async (params) => {
  return await doRequest({
    method: `post`,
    url: `${apis.account.password.update}`,
    params: params,
  });
};

const postAccountGeneratePassword = async (params) => {
  return await doRequest({
    method: `post`,
    url: `${apis.account.password.generate}`,
    params: params,
  });
};

const postAccountGeneratePasswordToken = async (params) => {
  return await doRequest({
    method: `post`,
    url: `${apis.account.password.generate}${params.token}`,
    params: params,
  });
};

const delAccountDelete = async (params) => {
  return await doRequest({
    method: `del`,
    url: `${apis.account.delete}`,
    params: params,
  });
};

const getSecret = async () => {
  return await doRequest({
    method: `get`,
    url: `${apis.secret}`,
  });
};

const postSecret = async (params) => {
  return await doRequest({
    method: `post`,
    url: `${apis.secret}`,
    params: params,
  });
};

const postCompanyRegister = async (params) => {
  return await doRequest({
    method: `post`,
    url: `${apis.company.register}`,
    params: params,
  });
};

const getCompanyList = async () => {
  return await doRequest({
    method: `get`,
    url: `${apis.company.list}`,
  });
};

const getDataStatOverall = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.overall.brief}`,
    params: params,
  });
};

const getDataStatOverallDaily = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.overall.daily}`,
    params: params,
  });
};

const getDataStatOverallSent = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.overall.sent}`,
    params: params,
  });
};

const getDataStatOverallCate = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.overall.category.brief}`,
    params: params,
  });
};

const getDataStatOverallCateSeries = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.overall.category.series}`,
    params: params,
  });
};

const getDataStatOverallCateCompare = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.overall.category.compare.brief}`,
    params: params,
  });
};

const getDataStatOverallCateCompareSeries = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.overall.category.compare.series}`,
    params: params,
  });
};

const getDataStatOverallCateTopic = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.overall.category.topic}`,
    params: params,
  });
};

const getDataStatOverallDownload = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.overall.download}`,
    params: params,
  });
};

const getDataStatCate = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.cate.brief}`,
    params: params,
  });
};

const getDataStatCateDaily = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.cate.daily}`,
    params: params,
  });
};

const getDataStatCateSent = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.cate.sent}`,
    params: params,
  });
};

const getDataStatCateList = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.cate.list}`,
    params: params,
  });
};

const getDataStatCateTopic = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.cate.topic.brief}`,
    params: params,
  });
};

const getDataStatCateShot = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.cate.shot.brief}`,
    params: params,
  });
};

const getDataStatCateTopicSeries = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.cate.topic.series}`,
    params: params,
  });
};

const getDataStatCateTopicCompare = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.cate.topic.compare.brief}`,
    params: params,
  });
};

const getDataStatCateTopicCompareSeries = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.cate.topic.compare.series}`,
    params: params,
  });
};

const getDataStatCateTopicDaily = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.cate.topic.daily}`,
    params: params,
  });
};

const getDataStatTopicDownload = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.cate.download}`,
    params: params,
  });
};

const getDataStatItemSearch = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.item.search}`,
    params: params,
  });
};

const getDataStatItem = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.item.brief}`,
    params: params,
  });
};

const getDataStatItemDaily = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.item.daily}`,
    params: params,
  });
};

const getDataStatItemSent = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.item.sent}`,
    params: params,
  });
};

const getDataStatItemTopic = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.item.topic.brief}`,
    params: params,
  });
};

const getDataStatItemTopicList = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.item.topic.list}`,
    params: params,
  });
};

const getDataStatItemRank = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.item.rank}`,
    params: params,
  });
};

const getDataStatItemTopicSeries = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.item.topic.series}`,
    params: params,
  });
};

const getDataStatItemTopicCompare = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.item.topic.compare.brief}`,
    params: params,
  });
};

const getDataStatItemTopicCompareSeries = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.item.topic.compare.series}`,
    params: params,
  });
};

const getDataStatItemDownload = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.item.download}`,
    params: params,
  });
};

const getDataNavigateReviewList = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.review.list}`,
    params: params,
  });
};

const getDataNavigateOverall = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.navigate.list}`,
    params: params,
  });
};

const getDataNavigateOverallCate = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.data.navigate.cate}`,
    params: params,
  });
};

const postIntegrationUpload = async (params) => {
  return await doRequest({
    method: `post`,
    url: `${apis.integration.upload}`,
    params: params,
    contentType: 'multipart/form-data',
  });
};

const postIntegrationDownloadFile = async (params) => {
  const url = `${apis.integration.download}?${getQueryParams(params)}`;
  return await doRequest({
    method: `post`,
    url: url,
  });
};

const getIntegrationTemplateDownload = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.integration.template}`,
    params: params,
    responseType: 'blob',
  });
};

const getIntegrationUploadList = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.integration.list}`,
    params: params,
  });
};

const getInvoiceList = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.invoice.list}`,
    params: params,
  });
};

const getInvoiceDownload = async (params) => {
  return await doRequest({
    method: `get`,
    url: `${apis.invoice.download}`,
    params: params,
    responseType: 'blob',
  });
};

export {
  postAccountSignup,
  postAccountSignin,
  getAccountUser,
  postAccountCheckPassword,
  postAccountUpdatePassword,
  postAccountGeneratePassword,
  postAccountGeneratePasswordToken,
  delAccountDelete,
  getSecret,
  postSecret,
  postCompanyRegister,
  getCompanyList,
  getDataStatOverall,
  getDataStatOverallDaily,
  getDataStatOverallSent,
  getDataStatOverallCate,
  getDataStatOverallCateSeries,
  getDataStatOverallCateCompare,
  getDataStatOverallCateCompareSeries,
  getDataStatOverallCateTopic,
  getDataStatOverallDownload,
  getDataStatCate,
  getDataStatCateDaily,
  getDataStatCateSent,
  getDataStatCateList,
  getDataStatCateTopic,
  getDataStatCateTopicSeries,
  getDataStatCateTopicCompare,
  getDataStatCateTopicCompareSeries,
  getDataStatCateTopicDaily,
  getDataStatCateShot,
  getDataStatTopicDownload,
  getDataStatItemSearch,
  getDataStatItem,
  getDataStatItemDaily,
  getDataStatItemSent,
  getDataStatItemTopic,
  getDataStatItemTopicList,
  getDataStatItemRank,
  getDataStatItemTopicSeries,
  getDataStatItemTopicCompare,
  getDataStatItemTopicCompareSeries,
  getDataStatItemDownload,
  getDataNavigateReviewList,
  getDataNavigateOverall,
  getDataNavigateOverallCate,
  postIntegrationUpload,
  postIntegrationDownloadFile,
  getIntegrationTemplateDownload,
  getIntegrationUploadList,
  getInvoiceList,
  getInvoiceDownload,
};
