import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Alert, Title, SubTitle } from 'components/atoms';
import copyImage from 'assets/images/copy.png';
import { getSecret } from 'utils/api';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import book from 'assets/images/book.png';
import arrow from 'assets/images/arrow_right_blue.png';

const IntegrationAuto = () => {
  const [showMessage01, setShowMessage01] = useState(false);
  const [showMessage02, setShowMessage02] = useState(false);
  const [secret, setSecret] = useState('');
  const [root, setRoot] = useState('');
  useEffect(() => {
    getContents();
  }, []);

  const getContents = async () => {
    const res = await getSecret();
    setSecret(res.results.secret);
    setRoot(res.results.root);
  };

  return (
    <Container>
      <Category01>연동 설정</Category01>
      <Category02>API 연동</Category02>
      {showMessage01 && (
        <Alert variant="info">API 키가 클립보드에 복사되었습니다.</Alert>
      )}
      {showMessage02 && (
        <Alert variant="info">파트너 키가 클립보드에 복사되었습니다.</Alert>
      )}
      <Section>
        <Title>안내사항</Title>
        <Contents>
          <Row>▪︎ API를 이용해서 데이터를 연동하실 수 있습니다.</Row>
          <Row>
            ▪︎ API 사용에 대한 자세한 내용은 API 연동 가이드를 참고해주세요.
          </Row>
          <Row>
            ▪︎ 연동 완료 후 분석까지 최대 1일이 소요되며, 분석 완료 후 '데이터
            분석 현황'을 이용하실 수 있습니다.
          </Row>
          <GuideButton
            onClick={() => {
              window.open('https://docs.bereview.co.kr/guides/data.api.spec');
            }}
          >
            <BookImg src={book} alt="" />
            API 연동 가이드
            <ArrowImg src={arrow} alt="" />
          </GuideButton>
        </Contents>
      </Section>
      <div
        style={{
          height: 2,
          border: '1px dashed #C6C6C6',
        }}
      />
      {secret !== '' && root !== '' && (
        <SectionWrapper>
          <Section
            style={{
              display: 'flex',
              marginBottom: '10px',
            }}
          >
            <SubTitle style={{ width: '60px' }}>파트너 키</SubTitle>
            <KeyContainer>
              <APIKeyContainer>
                {root?.length > 43 ? root?.slice(0, 43) + '...' : root}
              </APIKeyContainer>
              <CopyToClipboard
                text={root}
                onCopy={() => {
                  setShowMessage02(true);
                  setTimeout(() => {
                    setShowMessage02(false);
                  }, 2000);
                }}
              >
                <CopyImg src={copyImage} alt="" />
              </CopyToClipboard>
            </KeyContainer>
          </Section>
          <Section
            style={{
              display: 'flex',
              marginRight: '1.5px',
              marginBottom: '10px',
            }}
          >
            <SubTitle style={{ width: '45px' }}>API 키</SubTitle>
            <KeyContainer>
              <APIKeyContainer>
                {secret?.length > 43 ? secret?.slice(0, 43) + '...' : secret}
              </APIKeyContainer>
              <CopyToClipboard
                text={secret}
                onCopy={() => {
                  setShowMessage01(true);
                  setTimeout(() => {
                    setShowMessage01(false);
                  }, 2000);
                }}
              >
                <CopyImg src={copyImage} alt="" />
              </CopyToClipboard>
            </KeyContainer>
          </Section>
        </SectionWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 15px;
`;

const Category01 = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #707eae;
  line-height: 20px;
  padding-left: 10px;
`;

const Category02 = styled.div`
  font-size: 30px;
  color: #2b3674;
  font-weight: 800;
  padding-left: 10px;
  margin-bottom: 40px;
  margin-top: -20px;
`;

const GuideButton = styled.div`
  display: flex;
  align-items: center;
  width: 150px;
  justify-content: center;
  border-radius: 4px;
  background-color: #f4f7fe;
  color: #4318ff;
  cursor: pointer;
  padding: 10px;
  font-size: 13px;
  margin-top: 40px;
`;

const SectionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.8rem;
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Section = styled.div`
  gap: 2rem;
  background-color: white;
  align-items: center;
  padding: 30px;
  border-radius: 16px;
  box-shadow: 5px 5px 5px #eee;
`;

const Contents = styled.div`
  flex-direction: column;
  padding: 20px 0px 10px;
`;

const Row = styled.div`
  font-size: 13px;
  line-height: 30px;
  color: #616161;
`;

const KeyContainer = styled.div`
  width: 390px;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  padding: 7px 22px;
  color: #8a8a8a;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.06);
`;

const APIKeyContainer = styled.div`
  height: 20px;
  line-height: 20px;
  width: 320px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-width: 500px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const BookImg = styled.img`
  width: 16px;
  height: 13px;
  margin-right: 10px;
`;

const ArrowImg = styled.img`
  width: 14px;
  height: 14px;
  margin-left: 5px;
`;

const CopyImg = styled.img`
  cursor: pointer;
  width: 25px;
`;

export default IntegrationAuto;
