import React, { forwardRef } from 'react';
import styled from 'styled-components';

const Radio = ({ id, label, variant, ...rest }, ref) => {
  return (
    <Container variant={variant} htmlFor={id}>
      <InputWrap>
        <input type="radio" id={id} ref={ref} {...rest} />
      </InputWrap>
      <span className="radio-inner-label">{label}</span>
    </Container>
  );
};

const Container = styled.label`
  display: inline-flex;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 0;
  align-items: baseline;

  .radio-inner-label {
    display: inline-block;
    padding: 0 8px;
  }
`;

const InputWrap = styled.span`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  top: 0.2rem;

  input {
    position: relative;
    display: block;
    margin: 0;
    width: 16px;
    height: 16px;
  }
`;

export default React.memo(forwardRef(Radio));
