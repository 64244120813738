import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spin } from 'components/atoms';

const DataTable = ({
  head,
  body,
  height,
  maxHeight,
  className,
  action,
  border,
}) => {
  const tableRef = useRef();
  const [minheight, setMinHeight] = useState();
  const observer = useRef();

  const onIntersect = React.useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        if (action) {
          // action.func();
        }
      }
    },
    [action],
  );

  useEffect(() => {
    if (!observer.current) return;

    const io = new IntersectionObserver(onIntersect, { threshold: 1 });
    io.observe(observer.current);

    return () => io.disconnect();
  }, [onIntersect]);

  useEffect(() => {
    if (tableRef.current && !maxHeight) {
      setMinHeight(height || `${tableRef.current?.scrollHeight + 10}px`);
    }
  }, [tableRef, height, maxHeight]);

  return (
    <Container height={height} minHeight={minheight} maxHeight={maxHeight}>
      <Table ref={tableRef} className={className} border={border}>
        <thead>{head}</thead>
        <tbody>{body}</tbody>
      </Table>
      {action && (
        <Observer>
          {action.isFatch ? (
            <Spin ref={observer} />
          ) : (
            <Add onClick={() => action.func()}>More ...</Add>
          )}
        </Observer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: block;
  width: 100%;
  overflow: auto;
  position: relative;
  min-height: ${({ minHeight }) => minHeight || 'auto'};
  max-height: ${({ maxHeight }) => maxHeight || 'auto'};
  height: ${({ height }) => height || 'auto'};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    position: sticky;
    top: -1px;
    box-shadow: 0 0 0 1px #dddddd;
    z-index: 1;
  }

  th,
  td {
    padding: 0.7rem 0.75rem;
    border: ${({ border }) =>
      border === 'none' ? '0px solid #fffff' : '0px dashed #dddddd'};
    white-space: nowrap;
    vertical-align: middle;
  }
`;

const Observer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2.5rem 0.5rem;
`;

const Add = styled.button`
  padding: 10px;
  font-weight: 600;
  border: none;
  cursor: pointer;
`;

DataTable.propTypes = {
  head: PropTypes.node,
  body: PropTypes.node,
  height: PropTypes.string,
};

DataTable.defaultProps = {
  head: null,
  body: null,
  height: '',
};

export default DataTable;
