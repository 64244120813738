import React from 'react';
import styled from 'styled-components';
import { PropContextProvider } from 'context/PropContext';
import CategoryList from 'components/templates/layout/CategoryList';

const CategoryTable = ({
  data,
  table,
  handleSelectCategory,
  category,
  select,
}) => {
  return (
    <PropContextProvider
      value={{
        table,
        data,
        handleSelectCategory,
        category,
        select,
      }}
    >
      <Section style={{ height: '100%', padding: '0px' }}>
        <CategoryList />
      </Section>
    </PropContextProvider>
  );
};

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  background-color: white;
`;

export default CategoryTable;
