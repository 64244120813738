import React, { useEffect, useState } from 'react';
import { Title, SubTitle } from 'components/atoms';
import styled from 'styled-components';
import useTable from 'hooks/useTable';
import Stat from 'components/templates/layout/Stat';
import { getDataNavigateOverall, getDataNavigateOverallCate } from 'utils/api';
import CategoryTable from './CategoryTable';
import EmptyData from 'components/templates/layout/EmptyData';

const columns = [
  { label: '카테고리', col: 'sp_category1' },
  { label: '상품 수', col: 'item_cnt' },
  { label: '리뷰 수', col: 'review_cnt' },
];

const Link = () => {
  const [empty, setEmpty] = useState(false);
  const [overall, setOverall] = useState(null);
  const [data, setData] = useState({ totalCnt: 0, items: [] });
  const [select, setSelect] = useState({ category: '' });
  const [category, setCategory] = useState([]);

  const handleSelectCategory = (col) => {
    if (col?.rowItem?.sp_category1 !== select.category) {
      setSelect({
        category: col?.rowItem?.sp_category1,
        item_cnt: col?.rowItem?.item_cnt,
        review_cnt: col?.rowItem?.review_cnt,
      });
    } else {
      setSelect({ category: '' });
    }
  };

  const table = useTable({ columns, initData: data.items });

  useEffect(() => {
    getContents();
  }, []);

  useEffect(() => {
    getCategory();
  }, [select]);

  const getContents = async () => {
    const res01 = await getDataNavigateOverall();

    if (Object.keys(res01.results).length > 0) {
      const res02 = await getDataNavigateOverallCate();
      if (res01.statusCode === 200) {
        setOverall([
          {
            title: '카테고리',
            type: 'category',
            count: res01.results.category,
          },
          {
            title: '상품',
            type: 'item',
            count: res01.results.item,
          },
          {
            title: '리뷰',
            type: 'review',
            count: res01.results.review,
          },
        ]);
        setEmpty(false);
      } else {
        setEmpty(true);
      }
      if (res02.statusCode === 200) {
        setData({
          totalCnt: res02.results.total,
          items: res02.results.items.map((item) => {
            return {
              sp_category1: item.sp_category1,
              item_cnt: item.item_cnt.toLocaleString() + '개',
              review_cnt: item.review_cnt.toLocaleString() + '건',
            };
          }),
        });
      }
    }
  };

  const getCategory = async () => {
    if (select.category !== '') {
      const res03 = await getDataNavigateOverallCate({
        sp_category1: select.category,
      });
      setCategory(res03.results);
    }
  };

  table.convert.setHead = (item) => {
    const { label } = item;
    return <div style={{ float: 'left', fontWeight: 600 }}>{label}</div>;
  };

  table.convert.setHeadStyle = (item) => {
    const { colIdx } = item;
    if ([0, 1, 2].includes(colIdx)) {
      return {
        backgroundColor: '#fff',
        borderTop: '1px solid red',
      };
    }
  };

  table.convert.setCol = (item) => {
    return item.value.toLocaleString() || '-';
  };

  table.convert.setRowSpan = (item) => {
    const { rowItem } = item;
    if (select?.category && rowItem.sp_category1 === select.category) {
      return {
        backgroundColor: '#f8f8fd',
        borderRadius: '2px',
      };
    }
  };

  table.convert.setColStyle = (item) => {
    const { colIdx } = item;
    if ([1, 2].includes(colIdx)) {
      return { color: 'rgba(0, 0, 0, 0.6)' };
    } else if ([0].includes(colIdx)) {
      return { color: 'rgba(0, 0, 0, 0.9)', fontWeight: 600, fontSize: '14px' };
    }
  };
  return (
    <>
      {overall ? (
        <Container>
          <Category01>연동 설정</Category01>
          <Category02>연동 현황</Category02>
          <Section>
            <Title>연동 현황</Title>
          </Section>
          <Section>
            <SubTitle>전체 현황</SubTitle>
            {overall && <Stat items={overall} />}
          </Section>
          <Section>
            <SubTitle>카테고리별 현황</SubTitle>
            <CategoryTable
              columns={columns}
              data={data}
              table={table}
              handleSelectCategory={handleSelectCategory}
              category={category}
              select={select}
            />
          </Section>
        </Container>
      ) : (
        <div
          style={{
            display: 'flex',
            height: 'calc(100% - 120px)',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EmptyData />
        </div>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 15px;
`;

const Category01 = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #707eae;
  line-height: 20px;
  padding-left: 10px;
`;

const Category02 = styled.div`
  font-size: 30px;
  color: #2b3674;
  font-weight: 800;
  padding-left: 10px;
  margin-bottom: 40px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 5px 5px 5px #eee;
`;

export default Link;
