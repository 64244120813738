import React from 'react';
import styled from 'styled-components';

const ModalFooter = (prop) => {
  const { children } = prop;

  return <Footer>{children}</Footer>;
};

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
`;

export default ModalFooter;
