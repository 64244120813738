import React, { useState, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { getYear, getMonth } from 'date-fns';
import styled, { css } from 'styled-components';
import ko from 'date-fns/locale/ko';
import calendar from 'assets/svg/calendar.svg';
import { CloseFillCircle } from '../icon';
const _ = require('lodash');

registerLocale('ko', ko);

const Calendar = React.forwardRef(({ selected, onChange, ...rest }, ref) => {
  const [selectDate, setSelectDate] = useState();
  const years = _.range(1990, getYear(new Date()) + 1, 1); // 수정
  const months = [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ];

  const handleChange = (date) => {
    setSelectDate(date);
    if (onChange) {
      onChange(date);
    }
  };

  useEffect(() => {
    setSelectDate(selected);
  }, [selected]);

  const CustomInput = React.forwardRef((inputProp, ref) => {
    const { value, onClick } = inputProp;

    return (
      <ButtonWrapper {...rest}>
        <CustomButton
          locale="ko"
          type="button"
          onClick={onClick}
          innerRef={ref}
          {...rest}
        >
          <span>{value === '' ? '날짜를 선택해 주세요.' : value}</span>
        </CustomButton>
        <IconWrapper>
          {rest.resetButton && value ? (
            <>
              <CloseFillCircle
                className="calendar-enter"
                onClick={() => handleChange('')}
              />
              <CalendarIcon
                className="calendar-out"
                src={calendar}
                alt="icon"
                onClick={onClick}
              />
            </>
          ) : (
            <CalendarIcon src={calendar} alt="icon" onClick={onClick} />
          )}
        </IconWrapper>
      </ButtonWrapper>
    );
  });

  return (
    <PickerWrapper>
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                decreaseMonth();
              }}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
              style={{ width: 60, margin: '0px 5px 0px 20px' }}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
              style={{ width: 60, margin: '0px 20px 0px 5px' }}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <button
              onClick={(e) => {
                e.preventDefault();
                decreaseMonth();
              }}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </button>
          </div>
        )}
        locale="ko"
        changeMonth={true}
        changeYear={true}
        selected={selectDate}
        onChange={handleChange}
        customInput={<CustomInput />}
        dateFormat="yyyy년 MM월 dd일"
        {...rest}
        ref={ref}
      />
    </PickerWrapper>
  );
});

const PickerWrapper = styled.div`
  .react-datepicker-popper {
    z-index: 2;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3px;
  padding: 2px 3px;
  border-radius: 4px;
  border: 1px solid #dddddd;
  background-color: white;
  color: #888888;

  svg,
  img {
    cursor: pointer;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #e9ecef;
    `}
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  min-width: 15px;

  .calendar-enter {
    display: none;
  }
  .calendar-out {
    display: block;
  }

  &:hover {
    .calendar-enter {
      display: block;
    }
    .calendar-out {
      display: none;
    }
  }
`;

const CustomButton = styled.button`
  padding: 0 0 0 3px;
  background-color: white;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 2.15;
  color: #888888;
  cursor: pointer;
  border: none;

  span {
    white-space: nowrap;
  }

  &:disabled {
    cursor: default;
    background-color: #e9ecef;
  }
`;

const CalendarIcon = styled.img`
  width: 15px;
`;

export default Calendar;
