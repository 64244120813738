import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop({ event, children }) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location, event]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

ScrollToTop.propTypes = {
  event: PropTypes.func,
  children: PropTypes.node.isRequired,
};

ScrollToTop.defaultProps = {
  event: null,
};
