import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ButtonGroup = ({ className, children }) => {
  return <Container className={className}>{children}</Container>;
};

const Container = styled.div`
  button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
  button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

ButtonGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

ButtonGroup.defaultProps = {
  className: '',
  children: null,
};

export default ButtonGroup;
