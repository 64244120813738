import { useSelector, useDispatch } from 'react-redux';
import { pending, success, fail } from './loading.slice';

export default function useLoading() {
  const { isLoading, error } = useSelector((state) => state.loading);
  const dispatch = useDispatch();

  const setPending = () => {
    dispatch(pending());
  };

  const setDone = () => {
    dispatch(success());
  };

  const setSuccess = () => {
    dispatch(success());
  };

  const setFail = (data) => {
    dispatch(fail(data));
  };

  return { isLoading, error, setPending, setSuccess, setFail, setDone };
}
