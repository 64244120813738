import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  userSignInRequest,
  userSignUpRequest,
  userSignOutSuccess,
  clear,
} from './user.slice';

export default function useUser() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const isLogin = !!localStorage.getItem('token');

  const signin = useCallback(
    (data) => {
      dispatch(userSignInRequest(data));
    },
    [dispatch],
  );

  const signup = useCallback(
    (data) => {
      dispatch(userSignUpRequest(data));
    },
    [dispatch],
  );

  const clearUser = useCallback(() => {
    dispatch(clear());
  }, [dispatch]);

  const logout = useCallback(() => {
    dispatch(userSignOutSuccess());
  }, [dispatch]);

  useEffect(() => {
    const expired = localStorage.getItem('expired');
    const currentTime = new Date().getTime();
    if (expired && expired < currentTime) {
      // logout();
    }
  });

  return { user, signin, signup, logout, isLogin, clearUser };
}
