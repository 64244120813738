import styled, { css } from 'styled-components';
import { Button } from 'components/atoms';

export const ContentContainer = styled.div`
  background-color: white;
  font-size: 0.8rem;
  min-height: calc(100vh - 250px);
  height: calc(100% - 120px);
`;

export const Section = styled.div`
  position: relative;
  padding: 20px;
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  font-weight: 700;
  font-size: 1.2rem;
  background-color: white;
  border: none;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem 0;
`;

export const Aside = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  align-items: baseline;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const WrapTitle = styled.span`
  font-weight: 700;
`;

export const Comment = styled.div`
  display: flex;
  gap: 15px;
  align-items: baseline;

  ${({ variant }) =>
    variant === 'mute' &&
    css`
      color: #6c757d;
    `}
`;

export const Table = styled.table`
  width: 320px;
  border: 2px solid #d6d6d6;
  align-items: baseline;

  ${({ variant }) =>
    variant === 'danger' &&
    css`
      border: 2px solid red;
      &:focus-within {
        box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.3);
      }
    `}

  th {
    width: 90px;
    padding: 10px 15px;
    color: #666666;
    font-size: 0.9rem;
    font-weight: 700;
  }

  td {
    width: 180px;
    padding: 10px 15px;
  }

  th,
  td {
    border: 1px solid #d6d6d6;
  }
`;

export const Form = styled.form``;

export const LinkButton = styled(Button)`
  padding: 0.1rem;
  border: none;
  background-color: inherit;
  color: blue;
  font-weight: 700;
  font-size: 0.8rem;
  text-decoration: underline;

  &:disabled {
    background-color: inherit;
    color: #eeeeee;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  button {
    padding: 0.1rem 0.75rem;
  }
`;

export const buttonSectionStyle = {
  display: 'flex',
  gap: '1rem',
  justifyContent: 'center',
  padding: '3.5rem',
};

export const buttonStyle = {
  default: {
    padding: '0.375rem 1.5rem',
  },
  deletStyle: {
    padding: '0.375rem 2rem',
    backgroundColor: 'red',
    fontWeight: '700',
  },
  modifyStyle: {
    padding: '0.375rem 1.5rem',
    color: 'red',
    border: '1px solid red',
  },
};

export const Linked = styled.a`
  display: inline-block;
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
  color: blue;
`;
