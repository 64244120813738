import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import StatInfo from './StatInfo';

const StatBox = ({ item }) => {
  const [week, setWeek] = useState(false);
  const [month, setMonth] = useState(false);

  return (
    <Container>
      <TitleWrapper>
        <Title title={item.title}>{item.title}</Title>
        <CountWrapper>
          <Count>
            {item.count?.toLocaleString()}
            {item.type === 'category' || item.type === 'item' ? '개' : '건'}
          </Count>
          {item.ratio && <Ratio>({item.ratio})</Ratio>}
        </CountWrapper>
      </TitleWrapper>
      {week && <StatInfo item={item} show="week" />}
      {month && <StatInfo item={item} show="month" />}
      <IndicatorWrapper>
        <Period>최근 7일</Period>
        <NumWrapper>
          <div />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PeriodCount>{item?.cnt_7?.toLocaleString()}</PeriodCount>
            <Indicator
              onMouseOver={() => setWeek(true)}
              onMouseOut={() => setWeek(false)}
              rating={item?.ratio_7?.slice(0, item?.ratio_7.length - 1)}
            >
              {item.ratio_7?.slice(0, item.ratio_7.length - 1) < 0
                ? '▼ '
                : item.ratio_7 === '0%'
                ? ''
                : '▲ '}
              {item?.ratio_7?.toLocaleString()}
            </Indicator>
          </div>
        </NumWrapper>
        <Period>최근 30일</Period>
        <NumWrapper>
          <div />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PeriodCount>{item?.cnt_30?.toLocaleString()}</PeriodCount>
            <Indicator
              onMouseOver={() => setMonth(true)}
              onMouseOut={() => setMonth(false)}
              rating={item?.ratio_30?.slice(0, item?.ratio_30.length - 1)}
            >
              {item.ratio_30?.slice(0, item.ratio_30.length - 1) < 0
                ? '▼ '
                : item.ratio_30 === '0%'
                ? ''
                : '▲ '}
              {item?.ratio_30?.toLocaleString()}
            </Indicator>
          </div>
        </NumWrapper>
      </IndicatorWrapper>
    </Container>
  );
};

const Container = styled.div`
  font-size: 14px;
  background-color: #fff;
  padding: 25px;
  border-radius: 16px;
  position: relative;
  box-shadow: 5px 5px 5px #eee;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: ${({ title }) =>
    title === '리뷰' ? '#7e8cb8' : title === '긍정' ? '#106EFC' : '#F86994'};
  background-color: ${({ title }) =>
    title === '리뷰' ? '#f4f7fe' : title === '긍정' ? '#ecf4fe' : '#fef4f7'};
  padding: 33px 26px;
  font-weight: 700;
  border-radius: 100%;
`;

const Ratio = styled.div`
  font-size: 12px;
  text-align: right;
`;

const CountWrapper = styled.div`
  text-align: center;
  color: #2b3674;
`;

const Count = styled.div`
  font-weight: 700;
  font-size: 1.1rem;
  margin-bottom: 5px;
`;

const IndicatorWrapper = styled.div`
  color: #898989;
  text-align: right;
`;

const Period = styled.div`
  font-size: 11px;
  line-height: 20px;
  margin-top: 5px;
`;

const NumWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: right;
  align-items: center;
`;

const PeriodCount = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-right: 8px;
`;

const Indicator = styled.div`
  color: ${({ rating }) =>
    rating > 0
      ? '#F23985'
      : rating === 0
      ? 'grey'
      : rating < 0
      ? '#106EFC'
      : ''};
  font-weight: 500;
  font-size: 12px;
  width: 60px;
  &:hover {
    cursor: pointer;
    background-color: ${({ rating }) =>
      rating > 0
        ? '#FEE9F2'
        : rating === 0
        ? 'grey'
        : rating < 0
        ? '#E9F2FF'
        : '#e9e9e9'};
  }
`;

StatBox.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

export default StatBox;
