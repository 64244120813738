import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Table = forwardRef(({ head, body }, ref) => {
  return (
    <Container ref={ref}>
      <thead>{head}</thead>
      <tbody>{body}</tbody>
    </Container>
  );
});

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    position: sticky;
    top: 0px;
  }

  th,
  td {
    padding: 0.7rem 0.75rem;
    border: 0px solid #fff;
    white-space: nowrap;
    vertical-align: middle;
  }
`;

Table.propTypes = {
  head: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
};

export default Table;
