import { put, takeLatest, all, call } from 'redux-saga/effects';
import {
  userSignInRequest,
  userSignInSuccess,
  userSiginInFail,
  userSignUpRequest,
  userSignUpSuccess,
  userSignUpFail,
} from './user.slice';
import { postAccountSignin, postAccountSignup } from 'utils/api';

function* signIn({ payload }) {
  try {
    const { email, password } = payload;
    const results = yield call(postAccountSignin, {
      email: email,
      password: password,
    });

    if (results.statusCode === 200) {
      yield put(userSignInSuccess(results));
    } else {
      yield put(userSiginInFail(results.message));
    }
  } catch (error) {
    const { response } = error;
    let message = error.message;

    if (response) {
      if (response.data) {
        message = response.data.message;
      }
      if (response.status === 404) {
        message =
          '요청하신 계정정보를 찾을 수 없습니다. 계정정보를 다시 확인해주세요.';
      }
    }
    yield put(userSiginInFail(message));
  }
}

function* signUp({ payload }) {
  try {
    const { name, company, email, password } = payload;
    const results = yield call(postAccountSignup, {
      name: name,
      company: company,
      email: email,
      password: password,
    });

    if (results.statusCode === 200) {
      yield put(userSignUpSuccess(results));
    } else {
      yield put(userSignUpFail(results.message));
    }
  } catch (error) {
    const { response } = error;
    let message = error.message;

    if (response) {
      if (response.data) {
        message = response.data.message;
      }
    }
    yield put(userSignUpFail(message));
  }
}

export default function* user() {
  yield all([
    takeLatest(userSignInRequest, signIn),
    takeLatest(userSignUpRequest, signUp),
  ]);
}
