import React from 'react';
import { usePropContext } from 'context/PropContext';
import { SubTitle } from 'components/atoms';
import ZoomableSunBurstChart from 'app/chart/ZoomableSunBurstChart';

const SentStat = () => {
  const { data, changed } = usePropContext();

  return (
    <div
      style={{
        marginLeft: 12,
        height: 270,
        marginBottom: 5,
        marginRight: 5,
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '20px',
        borderRadius: '16px',
        backgroundColor: 'white',
        boxShadow: '5px 5px 5px #eee',
      }}
    >
      <SubTitle>긍부정 비율</SubTitle>
      {changed && <ZoomableSunBurstChart contents={data} />}
      {!changed && <ZoomableSunBurstChart contents={data} />}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          borderWidth: 1,
          borderColor: 'red',
          borderRadius: 15,
          padding: 20,
        }}
      >
        <div
          style={{
            height: 30,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#656565',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              color: '#656565',
              fontSize: '8pt',
            }}
          >
            <div
              style={{
                width: 8,
                height: 8,
                borderRadius: 4,
                backgroundColor: '#106EFC',
                marginRight: 5,
              }}
            />
            긍정
          </div>
          <div
            style={{
              marginTop: 5,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              color: '#2B3674',
              fontSize: '10pt',
              fontWeight: 700,
            }}
          >
            {data?.tot_pos_sent_ratio}
          </div>
        </div>
        <div
          style={{
            width: 1,
            height: 30,
            backgroundColor: '#D6D6D6',
          }}
        />
        <div
          style={{
            height: 30,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#656565',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              color: '#656565',
              fontSize: '8pt',
            }}
          >
            <div
              style={{
                width: 8,
                height: 8,
                borderRadius: 4,
                backgroundColor: '#F86994',
                marginRight: 5,
              }}
            />
            부정
          </div>
          <div
            style={{
              marginTop: 5,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              color: '#2B3674',
              fontSize: '10pt',
              fontWeight: 700,
            }}
          >
            {data?.tot_neg_sent_ratio}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SentStat;
