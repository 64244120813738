import React from 'react';
import { Message, Select } from 'components/atoms';
import { LabelForm } from 'components/blocks';
import { InputWrapper } from 'components/templates/styles';

function LabelFormSelect({ label, name, errors, options }, ref) {
  return (
    <LabelForm label={label}>
      <InputWrapper>
        <Select name={name} options={options} ref={ref} />
        {errors && <Message variant="danger">{errors}</Message>}
      </InputWrapper>
    </LabelForm>
  );
}

export default React.forwardRef(LabelFormSelect);
