import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup/yup'; // 1.3.7
import Confirm from 'hoc/modal/Confirm';
import * as yup from 'yup';
import { Alert, Message, Input, Button } from 'components/atoms';
import Footer from 'components/templates/layout/Footer';
import { postAccountGeneratePassword } from 'utils/api';
import useModal from 'hooks/useModal';
import { Modal } from 'hoc/modal';

const schema = yup.object({
  email: yup.string().email('이메일 형식이 올바르지 않습니다.').required(''),
});

const Password = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const navigate = useNavigate();
  const {
    show: showConfirm,
    openModal: openConfirm,
    closeModal: closeConfirm,
  } = useModal({ autoClose: false });
  const [errorMessage, setErrorMessage] = useState('');

  const goToLogin = () => {
    navigate('/login');
  };

  const onSubmit = async (data) => {
    const res = await postAccountGeneratePassword({
      email: data.email,
    });
    if (res.statusCode === 200) {
      openConfirm();
    } else {
      setErrorMessage(res.message);
    }
  };

  return (
    <Container>
      <Form style={{ gap: 0 }} onSubmit={handleSubmit(onSubmit)}>
        <Title>
          BEREVIEW <span style={{ fontWeight: 300 }}>&nbsp;STUDIO</span>
        </Title>
        <SubTitle>비밀번호 찾기</SubTitle>
        <Contents>
          가입하신 메일로 비밀번호 재설정 링크를 보내드립니다.
        </Contents>
        <Contents style={{ marginBottom: 20 }}>
          안내에 따라 비밀번호를 재설정 해주세요.
        </Contents>

        <div style={{ marginTop: 10 }}>
          <Input
            name="email"
            type="email"
            placeholder="회사 계정 이메일을 입력해주세요."
            ref={register}
            variant={errors.email && 'danger'}
            style={{
              fontSize: 12,
              fontWeight: 400,
              cursor: 'pointer',
            }}
          />
          {errors.email && (
            <Message variant="danger">{errors.email.message}</Message>
          )}
        </div>
        <div style={{ margin: '10px 0 50px' }}>
          {errorMessage !== '' && (
            <Alert variant="danger">{errorMessage}</Alert>
          )}
        </div>
        <Button>이메일 발송</Button>
        <div style={{ height: 10 }} />
        <Button
          onClick={goToLogin}
          style={{ backgroundColor: '#fff', color: '#4859D1' }}
        >
          로그인하기
        </Button>
        {/* <Login onClick={goToLogin}>이전</Login> */}
        <Modal show={showConfirm}>
          <Confirm
            onClose={closeConfirm}
            success="확인"
            onOk={goToLogin}
            type="check"
            title="이메일 발송 완료"
          >
            비밀번호 재설정을 위한 링크가 입력하신 이메일로 전송되었습니다.
            <br />
            안내에 따라 비밀번호를 재설정해주세요.
            <br />
            <br />
            만약, 메일을 받지 못하셨다면 '스팸 메일함'을 확인해주세요.
          </Confirm>
        </Modal>
      </Form>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 30rem;
  margin: auto;
  padding-top: 12rem;
  line-height: 1.5;
  font-size: 0.8rem;

  input {
    height: 50px;
    padding-left: 10px;
    letter-spacing: -0.03rem;
  }

  button {
    height: 50px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 12.8;

    &:hover {
      transform: translateY(-1px);
    }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 40px;
  background-color: #fff;
  padding: 60px 40px;
  border-radius: 21px;
  box-shadow: 5px 5px 5px #eee;
`;

const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;

const SubTitle = styled.div`
  margin-top: -15px;
  margin-bottom: 36px;
  font-weight: 500;
  text-align: center;
  font-size: 14.5px;
  line-height: 22px;
`;

const Contents = styled.div`
  margin: 0 auto;
  font-size: 12px;
  font-weight: 500;
`;

const Login = styled.div`
  color: #5e646d;
  margin: 30px auto 0;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.03rem;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export default Password;
