import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ReactComponent as InfoSvg } from 'assets/svg/info.svg';

const InfoIcon = ({ position, children }) => {
  return (
    <Wrapper>
      <Wrapper>
        <InfoSvg fill="gray" />
        {children && (
          <Info className="bubble_box" position={position}>
            {children}
          </Info>
        )}
      </Wrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  &:hover .bubble_box {
    visibility: visible;
  }
`;

const Info = styled.div`
  position: absolute;
  visibility: hidden;
  top: 20px;
  ${({ position }) =>
    position === 'left'
      ? css`
          left: -20px;
        `
      : css`
          right: -20px;
        `}
  z-index: 99;
  min-width: 155px;
  width: max-content;
  padding: 10px;
  background: white;
  border-radius: 5px;
  font-size: 0.4rem;
  box-shadow: 0 0 10px black;
  word-break: break-all;

  &::after {
    position: absolute;
    border-top: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid white;
    content: '';
    top: -10px;
    ${({ position }) =>
      position === 'left'
        ? css`
            left: 21px;
          `
        : css`
            right: 21px;
          `}
  }
`;

InfoIcon.propTypes = {
  position: PropTypes.string,
  children: PropTypes.node,
};

InfoIcon.defaultProps = {
  position: '',
  children: null,
};

export default InfoIcon;
